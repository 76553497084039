import { Input, Component, OnInit,ViewChild} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { CashFlow } from '../../../db/cashflow';
import { CashflowService } from '../../../services/cashflow.service';
import { UserService } from 'projects/core/src/lib/user.service';
import { AddressesService } from '../../../services/addresses.service';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { AddressComponent } from '../../address/address.component';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { ReferenceComponent } from '../../../modules/reference/reference.component';
import { ShopsService } from '../../../services/shops.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { User } from 'projects/core/src/include/structures';
import { FinancialAccountsService } from '../../../services/financialaccounts.service';


@Component({
  selector: 'app-cash-flow-details',
  templateUrl: './cash-flow-details.component.html',
  styleUrls: ['./cash-flow-details.component.css']
})

export class CashFlowDetailsComponent extends Model<CashFlow>  implements OnInit {

  @ViewChild("referenceRecord")
  referenceRecord:ReferenceComponent;
  
  @Input()
  show_paid=true;

  @Input()
  id_address:number=0;

  @Input()
  date:Date=Helper.convertDateControl();


  @Input()
  external_record:CashFlow;

  @Input()
  mode:string="";

  @Input()
  id_table:number=0;

  @Input()
  table:string="";

  @Input()
  description:string="";

  @Input()
  amount:number=0;

  @Input()
  paid:number=0;

  @Input()
  tax:number=-1;

  @Input()
  id_type=1;

  @Input()
  reference:any;

  @Input()
  id_user:number=0;

  @Input()
  source:string="";

  @Input()
  id_financialaccount:number=0;

  @Input()
  methodpayment:string="Contanti";
  
  field_required=['id_address','date'];
  
  detailForm;
  addressDetailsComponent:AddressDetailsComponent;
  shops=[];
  users:User[];
  methodpayments=[];
  sources=[];


  modulename="cash-flow";

  constructor(
    private route1: ActivatedRoute,
    private cashflowService: CashflowService,
    public addressesService:AddressesService,
    private shopsService:ShopsService,
    private userService:UserService,
    private methodpaymentsService:MethodpaymentsService,

   
    
    ) {
      super(route1,cashflowService); 
      
    
    
  }



  ngOnInit(): void {

    this.title = 'Movimento Dettaglio';
    super.ngOnInit.apply(this, arguments); 
   
    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{

      this.id = params.id;
      if(params.id_address>0)
        this.id_address=params.id_address;
      
      } );

      const default_address=Globals.parameters.get("general").getParam("default_address");

      this.sources=this.module.getParam("sources",[]);

      this.show_paid=this.module.getParam("show_paid",true);
      
      this.methodpaymentsService.getMethods().subscribe((items_m)=>{
        this.methodpayments=items_m;
      });

     


      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
        this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
          this.shops=items;
          this.getItem();
        });
       
      });

   
      this.afterGetItem=(()=>{
        if(this.record.id==0 || this.record.id==undefined){
          
          this.newRecord();
        }else{
          this.calculateGross(false);
        }
      });
   

  }

  ngOnChanges(){
    if(this.external_record!=undefined){
      this.record=this.external_record;
      if( this.record.id_address>0){
        this.addressesService.getItem(this.record.id_address).subscribe((item)=>{
          this.record.addressItem=item;
          this.record.id_address=item.id;
        });
      }
    }
      
    
  }


  newRecord(){
    if( this.id_address>0){
      this.addressesService.getItem(this.id_address).subscribe((item)=>{
      this.record.addressItem=item;
      this.record.id_address=item.id;
      
      
      });
    }
    this.record.id_type=this.id_type;
    this.record.id_table=this.id_table;
    this.record.table=this.table;
    this.record.tax=this.tax>-1?this.tax:this.module.getParam("default_tax",0);
    this.record.gross=0;
    this.record.description=this.description;
    this.record.amount=this.amount;
    this.record.paid=this.paid;
    this.record.customfields=[];
    this.record.date=this.date;
    this.record.id_user=this.id_user>0?this.id_user:Globals.user.id;
    this.record.method_payment=this.methodpayment;
    this.record.created_from=Globals.user['shop']?Globals.user['shop']['id']:(this.shops.length>0?this.shops[0].id:0);
    this.record.reference=this.reference;
    this.record.source=this.source;
    this.record.id_financialaccount=this.id_financialaccount;
    this.calculateGross();
  }

  saveAndNew(){
    //salva il record corrente temporaneamente
    let tmp_id_address=this.record.id_address;
    this.save(false,()=>{
      this.record={} as CashFlow;
      this.id_address=tmp_id_address;
      this.newRecord();
    });

  }

  selectAddress(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance['recordSelected']){
        this.record.addressItem=instance['recordSelected'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }


  OnViewAddress(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectAddress(instance['record']);
    });
  }

  addAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        this.record.addressItem=instance['record'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }

  OnSelectAddress(r){
    if(r){
      this.record.addressItem=r;
      this.record.id_address=r.id;
    }
  }

  calculateGross(auto_compile_paid=true){
    
    this.record.gross=parseFloat((this.record.amount*(1+this.record.tax/100)).toFixed(2));

    if(this.module.getParam("auto_compile_paid",false))
      if(auto_compile_paid)
        this.record.paid=this.record.gross;
  }

  calculateNet(){
    this.record.amount=this.record.gross/(1+this.record.tax/100);
  }


  setReference(r,table):void{
    this.record.reference=r;
    this.record.table=table;
    this.record.id_table=r['id'];

    this.record.id_address=ReferenceComponent.getIdAddress(table,this.record.reference);    
   
  }

  connectActivity(record){
    if(record!=null)
      this.setReference(record,record.table);    
    else{
      this.record.reference=null;
      this.record.table="";
      this.record.id_table=0;
    }
      
  }


}
