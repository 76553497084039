import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlarmsService } from 'projects/core/src/lib/alarms.service';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.css']
})
export class AlarmComponent implements OnInit {
  @ViewChild("modalOverview")
  modalOverview:TemplateRef<any>;
  modalOverviewRef;

  list=[];
  alarmSelected;
  constructor(
    private alarmService:AlarmsService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.alarmService.getAlarms().subscribe((items)=>{
      this.list=items;
    })
  }

  openModalOverview(alarm){
    this.alarmSelected=alarm;
    this.modalOverviewRef=this.modalService.open(this.modalOverview);
  }
  closeModalOverview(){
    this.modalOverviewRef.close("success");
  }

}
