import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { Documents } from '../../../db/documents';
import { InventoriesDetails } from '../../../db/inventories';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { LoginventoryComponent } from '../../inventories/loginventory/loginventory.component';
import { ProductsDetailsComponent } from '../../products/products-details/products-details.component';

@Component({
  selector: 'app-documentinventories',
  templateUrl: './documentinventories.component.html',
  styleUrls: ['./documentinventories.component.css']
})
export class DocumentinventoriesComponent extends ModelList<InventoriesDetails> implements OnInit {

  @Input()
  ids_documents:any=[];
  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();

  
  constructor( private fb1: FormBuilder,
    private invoicesService: InventoriesDetailsService)
    {
      super(invoicesService,fb1);
    }

  ngOnInit(): void {
    this.title = 'Documenti';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=999999999;

    //imposta i filtri
    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("i.table");
    this.filter_table.value="documents";
    this.filter.push(this.filter_table);

    this.filter_id_table.mode=FilterMode.contain;
    this.filter_id_table.fields=[];
    this.filter_id_table.fields.push("i.id_table");
    this.filter_id_table.value=this.ids_documents.join(",");
    this.filter.push(this.filter_id_table);

    if(this.ids_documents.length>0){
      this.getItems();
    }

  }
  
  ngOnChanges(){
    if(this.ids_documents.length>0){
      this.filter_id_table.value=this.ids_documents.join(",");
      this.getItems();
    }
  }



  openProduct(id_product){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_product},
      
    ],()=>{

    });
  }

  openHistory(id_product){
    Globals.modal.showModal(LoginventoryComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_product","value":id_product},
    ],()=>{
      
    })

  }

}
