import { Component, OnInit,Input, Output,ViewChild,TemplateRef } from '@angular/core';
import { ModelList, OpenMode } from 'projects/core/src/include/modellist'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Filter,FilterMode } from 'projects/core/src/include/structures'

import { ProductcategoriesService } from '../../services/productcategories.service';

import { ProductsService } from '../../services/products.service';
import { ProductBrandsService } from '../../services/productbrands.service';

import { Brand } from '../../db/brand';
import { Product } from '../../db/product';
import { ProductCategories } from '../../db/productcategories';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { CustomfieldsComponent } from '../../modules/customfields/customfields.component';
import { BrandsComponent } from './brands/brands.component';
import { Globals } from 'projects/core/src/globals';
import { CategoriesComponent } from './categories/categories.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { Type } from '../../db/type';
import { TypesService } from '../../services/types.service';



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.sass']
})



export class ProductsComponent extends ModelList<Product> implements OnInit {
  /*
  @ViewChild('customFieldsModal') customFieldsModal:TemplateRef<any>;
  @ViewChild(CustomfieldsComponent) customfields:CustomfieldsComponent;
  */

  @Input()
  id_supplier:number=0;

  @Input()
  autoSelect:boolean=true; //permette di selezionare un record in automatico se è il singolo della lista

  @Input()
  showPrice:boolean=true;

  

  filter_id_supplier:Filter=new Filter();
  filter_id_type:Filter=new Filter();
  filter_id_category:Filter=new Filter();
  filter_id_brand:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_search_name:Filter=new Filter();
  filter_stock:Filter=new Filter();

  selectedBrand:Brand;
  selectedModel:Product={} as Product;
  categories:ProductCategories[]=[];
  brands:Brand[]=[];

  types:Type[];

  constructor(
    private fb1: FormBuilder,
    private productBrandsService: ProductBrandsService,
    private productsService:ProductsService,
    private productcategoriesService:ProductcategoriesService,
    private modalService: NgbModal,
    private typesService: TypesService,
  ) {
    super(productsService,fb1);
    
   }


  ngOnInit(): void {
    this.title='Catalogo Prodotti';
    this.detailViewModal=ProductsDetailsComponent;
    super.ngOnInit.apply(this, arguments); 
    
    this.typesService.getTypes("products").subscribe((items)=>{
      this.types=items;
    });
    
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("pm.name");
    this.filter_search.fields.push("pb.name");
    this.filter_search.fields.push("CONCAT(pb.name,' ',pm.name)");
    
    this.filter_search.fields.push("pm.codeinternal");
    this.filter_search.fields.push("pm.codeexternal");
    this.filter_search.fields.push("pm.barcode");
    this.filter_search.fields.push("pm.barcode_pack");
    
    this.filter_search.value="";
    this.filter_search.field_fulltext="pm.name";
    this.filter.push(this.filter_search);

    /*
    this.filter_search_name.mode=FilterMode.fulltext;
    this.filter_search_name.fields=[];
    this.filter_search_name.fields.push("pm.name");
    this.filter_search_name.value=this.filter_search.value;
    this.filter.push(this.filter_search_name);
    */
    
    if(this.id_supplier){ //se è stato specificato un fornitore
      this.filter_id_supplier.mode=FilterMode.normal;
      this.filter_id_supplier.fields=[];
      this.filter_id_supplier.fields.push("pm.id_supplier");
      this.filter_id_supplier.value=this.id_supplier.toString();
      this.filter.push(this.filter_id_supplier);
    }

    this.filter_id_category.mode=FilterMode.contain;
    this.filter_id_category.fields=[];
    this.filter_id_category.fields.push("pm.id_category");
    this.filter_id_category.value="";
    this.filter.push(this.filter_id_category);

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("pm.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_brand.mode=FilterMode.normal;
    this.filter_id_brand.fields=[];
    this.filter_id_brand.fields.push("pm.id_brand");
    this.filter_id_brand.value="";
    this.filter.push(this.filter_id_brand);

    this.filter_stock.mode=FilterMode.disabled;
    this.filter_stock.fields=[];
    this.filter_stock.fields.push("ps.stock");
    this.filter_stock.value="0";
    this.filter.push(this.filter_stock);
    
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("pm.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);


    this.ordering.field="name";

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });

    this.productBrandsService.getBrands(0).subscribe((items)=>{
      this.brands=items;
    });

    if(this.mode=="modal")
      this.afterGetItems=()=>{
        if(this.autoSelect)
          if(this.list.length==1){
            this.selectRecord(this.list[0]);
          }
      }
       
    this.getItems();
  
 
    
  }


  getProductModels(record:Brand):void{
    if(record!=null)
      this.productsService.listModels(record.id).subscribe((items)=>{
        this.selectedBrand=record;
        this.selectedBrand.models=items;
      });
  }

 

  openBrandsManager(){
    Globals.modal.showModal(BrandsComponent,[],()=>{
      this.productBrandsService.getBrands(0).subscribe((items)=>{
        this.brands=items;
      });
    });
  }

  openCategoriesManager(){
    Globals.modal.showModal(CategoriesComponent,[],()=>{
      this.productcategoriesService.getCategories().subscribe((items)=>{
        this.categories=items;
      });
    },'md');
  }




  

  edit(){

  }



}
