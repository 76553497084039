import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { Activities } from '../../../db/activities';
import { Deadlines } from '../../../db/deadlines';
import { Type } from '../../../db/type';
import { ActivitiesService } from '../../../services/activities.service';
import { DeadlinesService } from '../../../services/deadlines.service';
import { TypesService } from '../../../services/types.service';
import { ActivitiesDetailsComponent } from '../../activities/activities-details/activities-details.component';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';
import { DeadlineDetailsComponent } from '../deadline-details/deadline-details.component';

@Component({
  selector: 'app-deadlines-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends ModelList<Deadlines> implements OnInit {


  @ViewChild('convertActivityModal')
  convertActivityModal:TemplateRef<any>;
  convertActivityModalRef;
  convertActivityMode=0; //0== singola, 1=multipla
  convertActivityDeadlines=[];
  convert:Activities={} as Activities;
  
  @Input()
  list:Deadlines[];

  @Input()
  date_from;

  @Input()
  date_to;
  
  @Input()
  form;

  @Input()
  viewMode="table";

  @Output()
  updateList:EventEmitter<any>=new EventEmitter();

  
  
  total=0;

  type_activities:Type[];
  users:User[];

  constructor(
    private deadlinesService: DeadlinesService,
    private fb1: FormBuilder,
    private modalService: NgbModal,
    private activitiesService:ActivitiesService,
    private typesService: TypesService,
    private userService:UserService,



  ) { 
    super(deadlinesService,fb1);
    this.detailViewModal=DeadlineDetailsComponent;
  }

  ngOnInit(): void {
    this.typesService.getTypes("activities").subscribe((items)=>{
      this.type_activities=items;
    });
  
    
    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
     });

    this.calculateTotal();
  }

  ngOnChanges(){
     
    
     
    this.calculateTotal();
 
  }

  calculateTotal(){
    this.total=0;
    for(let r of this.list){
      this.total=this.total+(r.amount*(1+r.tax/100)*(r.type_amount==2?-1:1));
    }
  }

  getItems(){
    this.updateList.emit();
  }

  addPayment(r){
    
    Globals.modal.showModal(CashFlowDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":r.type_amount},
      {"name":"id_table","value":r.reference.id},
      {"name":"reference","value":r.reference},
      {"name":"table","value":r.table},
      {"name":"id_address","value":r.id_address},
      {"name":"description","value":r.description},
      {"name":"amount","value":r.amount},
      {"name":"paid","value":r.amount},
      {"name":"source","value":r.source},
      {"name":"id_financialaccount","value":r.id_financialaccount},
      {"name":"methodpayment","value":r.methodpayment}
    ],(instance)=>{
      if(instance!=null){
        if(confirm("Vuoi eliminare la scadenza adesso?")){
          this.deadlinesService.delete([r.id.toString()]).subscribe(()=>{
            this.updateList.emit();
          });
          return false;
        };
      };
      
    });
  }

  convertDeadlineIntoActivity(r:Deadlines):void{

    this.convertActivityMode=0;

    this.convert={} as Activities;
    this.convert.date=r.date;
    this.convert.description=r.description;
    this.convert.note=r.note;
    
    this.convert.id_table=r.id_table;
    this.convert.table=r.table;
    this.convert.status=1;
    this.convert.id_user=Globals.user.id;
    this.convert.id_address=r.id_address;
    this.convert.amount=r.amount;
    this.convert.tax=r.tax;
    this.convert['deadline']=r;
    this.convertActivityModalRef=this.modalService.open(this.convertActivityModal);
  }

  confirmActivity():void{
    if(this.convertActivityMode==0){ //singola conversione
      
      this.activitiesService.save(this.convert,(id)=>{
        
        /*let deadline={} as Deadlines;
        deadline=this.convert['deadline'];
        deadline.id_activity=id;
        this.deadlinesService.save(deadline,(id)=>{*/
          this.closeConvertActivity();
          this.getItems();
        //},false);
        
          
      });
    }else{ //conversione multipla
      let activities=[];
      for(let r of this.convertActivityDeadlines){
        let a={} as Activities;
        a.date=r.date;
        a.description=r.description;
        a.note=this.convert.note;
        a.id_type=this.convert.id_type;
        a.id_table=r.id_table;
        a.table=r.table;
        a.status=1;
        a.id_user=this.convert.id_user;
        a.id_address=r.id_address;
        a.amount=r.amount;
        a.tax=r.tax;
        a['deadline']=r;
        activities.push(a);

      }

      this.activitiesService.saveMultiple(activities,()=>{
        this.closeConvertActivity();
        this.getItems();
      });
    }
  }

  openActivity(id_activity){
    Globals.modal.showModal(ActivitiesDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_activity},
      
    ],(instance)=>{

    });
    
  }

  convertDeadlinesIntoActivities(){
    this.convert={} as Activities;

    this.convertActivityDeadlines=[];

    for(let id_deadline of this.form.value.id){
      for(let r of this.list){
        if(r['id']==id_deadline){
          this.convertActivityDeadlines.push(r);
        }
      }
    }


    this.convertActivityMode=1;
  
    this.convert.status=1;
    this.convert.id_user=Globals.user.id;
    this.convertActivityModalRef=this.modalService.open(this.convertActivityModal);
  }

  closeConvertActivity(){
    this.convertActivityModalRef.close("success");
  }

  

  



}
