<app-windowlist [model]="this">
    <div class="row my-1">
        <div class="col-lg-2 pr-md-0">
            <small>
                <ul class="card small-box list-group treeviewlist p-1">
                    <li class="list-group-item" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList();">Tutte le attività</li>
                    <li class="list-group-item" *ngFor="let a of type_activities" [class.active]="filter_id_type.value==a.id" (click)="filter_id_type.value=a.id;updateList();">{{a.name}}</li>
                    <div  *ngIf="type_activities.length>2" class="icon"><i class="fas fa-tasks"></i></div>
                </ul>
            </small>
            <small>
                <app-filterdate [date_type]="100" [class]="'card treeviewlist p-1 mt-2'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                
            </small>
        </div>
        <div class="col-lg-10">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-7 pr-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="Cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="updateList();"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="Annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2  pr-0">
                            <div class="input-group input-group-sm m-1" >
                                <select class="custom-select" [(ngModel)]="filter_id_type_installation.value" (ngModelChange)="updateList()">
                                    <option value="">Tutte le tipologie</option>
                                    <option *ngFor="let t of type_product" [ngValue]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3" *ngIf="mode!='modal'">
                            <button data-toggle="tooltip" title="Archivia"  (click)="archive()" [disabled]="form.value.id.length == 0" class="btn btn-primary btn-xs float-right m-1 "><i class="fas fa-archive"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Aggiungi" (click)="openDetail(null,{'name':'id_shop','value':id_shop})" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> 
                        </div>
                    </div>

                    <div *ngIf="list==null" class="m-4">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>

                    <form [formGroup]="form"  *ngIf="list!=null">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Modello / Numero Seriale</th>
                                <th>Ubicazione attuale</th>
                                <th>Ultima Attività</th>
                                <th>Prossima scadenza</th>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <a [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)" style="cursor:pointer;"><span *ngIf="record.reference.product"><b>{{record.reference.product.brand}}</b> {{record.reference.product.name}}</span></a>
                                        <span class="badge badge-warning" *ngIf="record.reference.product && record.reference.product.id_category==id_category_log && !record.last_log.id" date-toggle="tooltip" title="Nessun monitoraggio chilometri rilevato!"><i class="fa fa-exclamation-triangle"></i></span>
                                        <br/>
                                        <small>
                                            <i>
                                                <b>{{ record.reference.sn }}</b>
                                                <br/>{{ record.reference.matricola }}
                                            </i>
                                        </small>
                                        &nbsp;<i *ngIf="record.loguseralert>0" data-toggle="tooltip" title="Ci sono alcune avvertenze da visionare" class="text-warning text-warning fas fa-exclamation-triangle"><sup><span class="badge badge-danger">{{record.reference.loguseralert}}</span></sup></i>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="record.reference.shop">
                                            <i class="fas fa-store-alt"></i>&nbsp;<span [class.trashed]="record.reference.shop.status==2" (dblclick)="moveInstallation(record.reference)" data-toggle="tooltip" title="Doppio Clik per spostamento rapido rapida" style="cursor:pointer;">{{record.reference.shop.name}}
                                                <div *ngIf="record.reference.shop.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                            </span>
                                            
                                            <span class="badge badge-danger" *ngIf="record.reference.shop.type_params!='default'">{{record.reference.shop.type}}</span>
                                            <br/>
                                            <ng-container *ngIf="record.reference.shop.addressItem">
                                                <small [class.trashed]="record.reference.shop.addressItem.status==2">
                                                    <i class="fa fa-user"></i>&nbsp;{{record.reference.shop.addressItem.name}}
                                                    <div *ngIf="record.reference.shop.addressItem.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                                </small>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                   <td>
                                    <strong>{{record.date | date:"dd/MM/Y"}}</strong><br/>
                                       {{record.type}}
                                       <small *ngIf="record.note">
                                        <div class="alert alert-warning">
                                            {{record.note}}
                                        </div>
                                     </small>
                                   </td>
                                   <td >
                                       <ng-container *ngIf="record.reference && record.reference.nextDeadline">
                                            <strong>{{record.reference.nextDeadline.date | date:"dd/MM/Y"}}</strong><br/>
                                        {{record.reference.nextDeadline.type}}
                                        </ng-container>
                                   </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record.reference)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>

