import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingValuesComponent } from './missing-values.component';
import { FormsModule } from '@angular/forms';
import { TaxCodeModule } from '../tax-code/tax-code.module';
import { VatNumberModule } from '../vat-number/vat-number.module';



@NgModule({
  declarations: [MissingValuesComponent],
  exports:[MissingValuesComponent],
  imports: [
    CommonModule,
    FormsModule,
    TaxCodeModule,
    VatNumberModule
  ]
})
export class MissingValuesModule { }
