import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-allinvoices',
  templateUrl: './allinvoices.component.html',
  styleUrls: ['./allinvoices.component.css']
})
export class AllinvoicesComponent implements OnInit {
  list=[];
  loading=true;

  constructor(
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {
    this.documentsService.verifyAllInvoice().subscribe((items)=>{
      this.list=items;
      this.loading=false;
    })
  }

}
