import { NgModule } from '@angular/core';
import { DocumentsComponent } from './documents.component';
import { DocumentsDetailsComponent } from './documents-details/documents-details.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { DocumentsRoutingModule} from './documents-routing.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { DocumentlistComponent } from './documentlist/documentlist.component';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { SearchproductModule } from '../../modules/searchproduct/searchproduct.module';
import { ComboboxModule } from 'projects/core/src/common/combobox/combobox.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { CashFlowModule } from '../cash-flow/cash-flow.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ProductitemModule } from '../../modules/productitem/productitem.module';
import { StatedocumentModule } from '../../modules/statedocument/statedocument.module';
import { GoodsModule } from '../../modules/goods/goods.module';
import { SelecttemplateModule } from 'projects/core/src/common/selecttemplate/selecttemplate.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { NationsModule } from 'projects/core/src/common/nations/nations.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { TransferComponent } from './transfer/transfer.component';
import { DeadlinesModule } from '../deadlines/deadlines.module';
import { DocumentssdiComponent } from './documentssdi/documentssdi.component';
import { FilesModule } from '../../modules/files/files.module';
import { OverviewComponent } from './overview/overview.component';
import { ReceiptModule } from '../../modules/receipt/receipt.module';
import { AllinvoicesComponent } from './allinvoices/allinvoices.component';
import { DocumentsTemplateComponent } from './documents-template/documents-template.component';
import { DocumentsTemplateDetailComponent } from './documents-template/documents-template-detail/documents-template-detail.component';
import { SelectTablesModule } from 'projects/core/src/common/tables/tables.module';
import { FinancialaccountModule } from '../../modules/financialaccount/financialaccount.module';
import { TaxCodeModule } from '../../modules/tax-code/tax-code.module';
import { VatNumberModule } from '../../modules/vat-number/vat-number.module';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { InventoryComponent } from './inventory/inventory.component';
import { DocumentinventoriesComponent } from './documentinventories/documentinventories.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        DocumentsRoutingModule,
        PaginationModule,
        CitiesModule,
        ToolbarmodelModule,
        SearchproductModule,
        SelectfieldcustomModule,
        FilterdateModule,
        ComboboxModule,
        CashFlowModule,
        StatedocumentModule,
        ProductitemModule,
        GoodsModule,
        SelecttemplateModule,
        CashFlowModule,
        ReferenceModule,
        NationsModule,
        DeadlinesModule,
        FilesModule,
        ReceiptModule,
        SelectTablesModule,
        ReferenceModule,
        FinancialaccountModule,
        TaxCodeModule,
        VatNumberModule,
        NgxTagsInputModule

    ],
   exports: [DocumentsComponent,DocumentlistComponent,DocumentsDetailsComponent,DocumentinventoriesComponent],
   declarations:  [DocumentsComponent,DocumentsDetailsComponent,DocumentlistComponent, TransferComponent, DocumentssdiComponent, OverviewComponent, AllinvoicesComponent, DocumentsTemplateComponent, DocumentsTemplateDetailComponent, InventoryComponent, DocumentinventoriesComponent],
   providers: [],
})
export class DocumentsModule{ 

    



}