


<app-windowlist [model]="this">
    
    <div class="row">
        <div class="col-lg-2 pr-lg-0">
            <small>
                <app-filterdate [date_type]="default_filterdate" [future]="true" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="updateList($event)"></app-filterdate>
            
                <ul class="card small-box list-group treeviewlist p-1 mt-2">
                    <li class="list-group-item" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList()" style="cursor:pointer;">Tutte le tipologie</li>
                    <li class="list-group-item" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_activities" (click)="filter_id_type.value=t.id;updateList()" style="cursor:pointer;">{{t.name}}</li>
                 <!-- <div class="icon"><i class="fas fa-tasks"></i></div> -->
                </ul>
            
            
            </small>
        </div>
        <div class="col-lg-10">
            <div class="card card-outline mb-0">
                <div class="p-0">
                    <div class="row">
                        <div class="col-6">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cliente o descrizione" [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append">
                                    <input type="text"  class="form-control form-control-sm float-right" placeholder="comune" [(ngModel)]="filter_search_city.value" (keyup.enter)="updateList()">
                              
                                </div>
                                <div class="input-group-append ">
                                    
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()">
                                <option value="">Tutti gli operatori</option>
                                <ng-container *ngFor="let u of users">
                                    <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_phase.value" (ngModelChange)="updateList()">
                                <option value="">Tutte le fasi</option>
                                <option value="1">In corso</option>
                                <option value="2">Completate</option>
                                <option value="3">Confermate</option>
                            </select>
                        </div>
                        
                        <div class="col-2" *ngIf="mode!='modal'">
                            <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>
                    
                    <div class=" m-4" *ngIf="list.length==0">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessuna voce di attività trovata!</i>
                    </div>

                    <form [formGroup]="form" *ngIf="list.length>0">
                    
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" *ngIf="list.length>0">
                            <thead class="bg-light">
                                <th><div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Data pagamento</th>
                                <th>Attività</th>
                                <th>Note</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let activities of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{activities.id}}"
                                            (change)="onChange(activities.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <a (click)="openDetail(activities)"  style="cursor:pointer;">
                                            {{activities.reschedule_payment | date:'dd/MM/yyyy'}}
                                            
                                        </a>
                                    </td>
                                    
                                    <td>
                                        {{activities.date | date:'dd/MM/yyyy'}} {{activities.time}}<br/>
                                        {{activities.type}}&nbsp;<span *ngIf="activities.amount>0 || activities.paid>0"  [class.badge-danger]="activities.id_cashflow==0" [class.badge-success]="activities.id_cashflow>0" class="badge ml-1"><i class="fas fa-coins"></i></span>
                                        <br/><small>{{activities.description}}</small>
                                        <i *ngIf="activities.priority==2" class="text-warning fas fa-exclamation"></i> 
                                        <i *ngIf="activities.priority==3" class="text-danger fas fa-exclamation"></i>
                                        <ng-container *ngIf="activities.note">
                                            <hr/>
                                            <small>Note: {{activities.note}}</small>
                                        </ng-container>

                                        <app-reference *ngIf="mode!='embedded'" [reference]="activities.reference" [table]="activities.table"></app-reference>
                                    </td>
                                    
                                    <td>{{activities.note_reschedule_payment}}</td>
                                    
                                    <td>
                                        <a (click)="openDetail(activities)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right">
                                            <i class="fas fa-pencil-alt"></i></a>

                                        <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" (click)="notify(activities);" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                            <i class="far fa-paper-plane"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
    
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                        
                    </form>
                </div>

                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>

       