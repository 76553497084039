<app-modal title="Gestione magazzini" [class]="'modal-md'">
    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary btn-xs float-right m-1" (click)="addInventory()"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;Aggiungi</span></button>
       
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-sm">
                <tr *ngFor="let r of list">
                    <td>{{r.id}}</td>
                    <td><input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="r.name" (change)="update(r)"></td>
                    <td><input type="text" class="form-control form-control-sm" name="code" [(ngModel)]="r.code" placeholder="codice magazzino ..." (change)="update(r)"></td>
                    <td><input type="text" class="form-control form-control-sm" name="location" [(ngModel)]="r.location" placeholder="indirizzo magazzino ..." (change)="update(r)"></td>
                    <td>
                        <input type="color" [(ngModel)]="r.color" (change)="update(r)" />
                        <input type="color" [(ngModel)]="r.textcolor" (change)="update(r)" />
                    </td>
                    <td class="text-right">
                        <button class="btn btn-xs btn-danger " (click)="deleteInventory(r)"><i class="fa fa-times"></i></button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</app-modal>