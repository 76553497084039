
<div class="mb-2">
    <button class="btn btn-success btn-sm m-1" (click)="addSection()"><i class="fas fa-plus-square mr-2"></i>Aggiungi sezione</button>
    <button [disabled]="!sections" class="btn btn-success btn-sm m-1" (click)="addField()"><i class="fas fa-plus mr-1"></i>Aggiungi campo</button>
    
</div>
<ul class="nav nav-tabs">
    <li class="nav-item" *ngFor="let s of sections;let i=index">
        <a class="nav-link" [class.active]="tabsection==i" (click)="tabsection=i">{{s.name}}
            <i data-toggle="tooltip" title="Rinomina sezione" (click)="renameSection(s)" *ngIf="tabsection==i && i>0" class="text-secondary fas fa-pencil-alt ml-1"></i>
            <i data-toggle="tooltip" title="Elimina sezione" (click)="deleteSection(s)" *ngIf="tabsection==i && i>0" class="text-danger fas fa-trash ml-1"></i>
        </a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    
    <div *ngFor="let s of sections;let j=index;" class="tab-pane fade show" [class.active]="tabsection==j" style="min-height:200px;">
        <ul class="list-group">
            <ng-container *ngFor="let f of s.fields;let i=index" >
                <ng-container *ngIf="f.type!='section'">
                    <li class="list-group-item border" >
                        <div class="row">
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="">
                                            <small>Nome</small>
                                            <input class="form-control form-control-sm" type="text" name="{{j}}_{{i}}_name" [(ngModel)]="f.name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="">
                                            <small>Tipologia</small>
                                            <select class="form-control form-control-sm" name="{{j}}_{{i}}_type" [(ngModel)]="f.type" (change)="f.params='';suggestParams(f)">
                                                <option value="text">Testo</option>
                                                <option value="textarea">Area di testo</option>
                                                <option value="select">Casella di riepilogo</option>
                                                <option value="boolean">Si/No</option>
                                                <option value="number">Numero</option>
                                                <option value="separator">Separatore</option>
                                                <option value="date">Data</option>
                                                <option value="time">Orario</option>
                                                <option value="checkbox">Casella di controllo</option>
                                                <option value="file">File</option>
                                                <!--<option value="section">Sezione</option>-->
                                                <option value="photo">Foto</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="">
                                            <small>Obbligatorio</small>
                                            <select class="form-control form-control-sm" name="{{j}}_{{i}}_required" [(ngModel)]="f.required">
                                                <option selected value="0">No</option>
                                                <option value="1">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" [class.collapse]="!f['expand']">
                                    <div class="col-lg-6">
                                        <div class="">
                                            <small>Etichetta</small>
                                            <input class="form-control form-control-sm"  name="{{j}}_{{i}}_label"  type="text" [(ngModel)]="f.label" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="">
                                            <small>Valore di default</small>
                                            <input type="text" class="form-control form-control-sm"  name="{{j}}_{{i}}_defaultvalue" [(ngModel)]="f.defaultvalue" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="">
                                            <small>Descrizione estesa</small>
                                            <textarea class="form-control form-control-sm"  name="{{j}}_{{i}}_description" [(ngModel)]="f.description"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" [class.collapse]="!f['expand']">
                                    <div class="col-lg-12" *ngIf="f.type=='select'">
                                        <small>Valori della lista</small>
                                        <select class="form-control form-control-sm"  multiple="multiple" [(ngModel)]="param_selected">
                                            <option *ngFor="let s of f.params.split(';')" value="{{s}}">{{s}}</option>
                                        </select>
                                        <button class="btn btn-xs btn-success" (click)="addValueToParams(f)"><i class="fa fa-plus"></i></button>
                                        <button class="btn btn-xs btn-danger" [disabled]="param_selected==''" (click)="removeValueFromParams(f)"><i class="fa fa-trash"></i></button>
                                    </div>
                                    <div class="col-lg-12" *ngIf="f.params && f.params.length>0 && f.type!='select'">
                                            <small>Parametri</small>
                                            <div class="border p-2">
                                            <ng-container *ngFor="let s of f.params">
                                                <small>
                                                    <app-field [field]="s"></app-field>
                                                </small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" [class.collapse]="!f['expand']">
                                    <div class="col-12">
                                        <div class="">
                                            <small>Note interne</small>
                                            <textarea type="text" class="form-control form-control-sm"  name="{{j}}_{{i}}_note" [(ngModel)]="f.note" ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" [class.collapse]="!f['expand']">
                                    <div class="col-lg-3">
                                        <div class="">
                                            <small>Mostra colonna in lista</small>
                                            <select class="form-control form-control-sm"  name="{{j}}_{{i}}_show_list" [(ngModel)]="f.show_list">
                                                <option value="0">No</option>
                                                <option value="1">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="">
                                            <small>Sezione</small>
                                            <select class="form-control form-control-sm"  name="{{j}}_{{i}}_id_parent" [(ngModel)]="f.id_parent" (change)="moveToSection(f)">
                                                <option *ngFor="let s of sections" [ngValue]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="">
                                            <small>Accesso</small>
                                            <select class="form-control form-control-sm"  name="{{j}}_{{i}}_id_parent" [(ngModel)]="f.access" >
                                                <option value="1">Pubblico</option>
                                                <option value="3">Pubblico (solo visione)</option>
                                                <option value="2">Privato</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="">
                                            <small>Abilita se è compilato il campo...</small>
                                            <select class="form-control form-control-sm"  name="{{j}}_{{i}}_condition_enable_fieldname" [(ngModel)]="f.condition_enable_fieldname" >
                                                <option value="">Nessuno</option>
                                                <option *ngFor="let f2 of s.fields " value="{{f2.name}}">{{f2.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 pr-0 text-right">
                                <button class="btn btn-default text-danger btn-xs" (click)="removeField(f)"><i class="fa fa-trash" data-toggle="tooltip" title="Elimina campo"></i></button>
                                <button class="btn btn-default text-info btn-xs" (click)="duplicateField(f)"><i class="fa fa-clone" data-toggle="tooltip" title="Dupplica campo"></i></button>
                                
                                <button class="btn btn-default btn-xs" (click)="moveField(f,'up');"><i class="fas fa-arrow-up" data-toggle="tooltip" title="Sposta sopra"></i></button>
                                <button class="btn btn-default btn-xs" (click)="moveField(f,'down');"><i class="fas fa-arrow-down"  data-toggle="tooltip" title="Sposta sotto"></i></button>
                                <button class="btn btn-default btn-xs" *ngIf="!f['expand']" (click)="f['expand']=true"><i class="fas fa-chevron-down" data-toggle="tooltip" title="Espandi"></i></button>
                                <button class="btn btn-default btn-xs" *ngIf="f['expand']" (click)="f['expand']=false"><i class="fas fa-chevron-up" data-toggle="tooltip" title="Comprimi"></i></button>
                            
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>




    

</div>

