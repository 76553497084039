import { Component, OnInit,Input } from '@angular/core';
import {CustomfieldsService} from '../../lib/customfields.service';
import { FormBuilder } from '@angular/forms';
import { CustomField,Filter,FilterMode } from '../../include/structures';
import {ModelList} from '../../include/modellist'
import {VerifyFiscalCodeService} from '../../lib/verify-fiscal-code.service'
import { Globals } from '../../globals';
import { UploadFileComponent } from 'projects/core/src/common/upload-file/upload-file.component';
import { UploadService } from 'projects/core/src/lib/upload.service';



@Component({
  selector: 'app-customfields',
  templateUrl: './customfields.component.html',
  styleUrls: ['./customfields.component.sass'],
  
})
export class CustomfieldsComponent extends ModelList<CustomField> implements OnInit {
  

  filter_status:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_id_addresses:Filter=new Filter();
  filter_condition_id:Filter<string[]>=new Filter<string[]>();
  filter_condition_field:Filter<string[]>=new Filter<string[]>();
  filter_tag:Filter=new Filter();

  multi_value_input:string;

  @Input()
  id_table:number;

  @Input()
  table:string;

  @Input()
  list:CustomField[];

  @Input()
  condition_id:number=0;

  @Input()
  condition_field:string;

  @Input()
  tag:string;

  @Input()
  access:string="superuser,admin,manager";

  @Input()
  layout="horizontal"

  mode="embedded";
  listvalue:CustomField[]=[];


  tax_code_error:string="";
  vat_number_error:string="";


  isFilterSet=false;
  constructor(
    private customfieldsService: CustomfieldsService,
    private fb1: FormBuilder
  ){
    super(customfieldsService,fb1);
   }

  ngOnInit(): void {
    
    if(typeof(this.list)=="string")
      this.list={} as CustomField[];
    
      

    
  }

  setFilters():void{
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("c.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("c.table");
    this.filter_table.value=this.table;
    this.filter.push(this.filter_table);


    this.filter_condition_field.mode=FilterMode.contain;
    this.filter_condition_field.fields=[];
    this.filter_condition_field.fields.push("c.condition_field");
    this.filter.push(this.filter_condition_field);

    this.filter_condition_id.mode=FilterMode.contain;
    this.filter_condition_id.fields=[];
    this.filter_condition_id.fields.push("c.condition_id");
    this.filter.push(this.filter_condition_id);

    if(this.tag!=""){
      this.filter_tag.mode=FilterMode.normal;
      this.filter_tag.fields=[];
      this.filter_tag.fields.push("c.tag");
      this.filter_tag.value=this.tag;
      this.filter.push(this.filter_tag);
    }


    this.isFilterSet=true;
    
  }

  ngOnChanges():void{
    
    //effettua una copia dei valori 
    if(this.list!=undefined)
      //if(this.listvalue.length==0)
        this.listvalue  = this.list.map(x => Object.assign({}, x));
        
    this.updateList();
  }

  updateList():void{

    if(this.id_table==null)
      return;
    this.paging_count=100;
    
    if(!this.isFilterSet)
      this.setFilters();
      
    if(this.condition_id!=undefined){
      this.filter_condition_id.value=[];
      this.filter_condition_id.value.push(this.condition_id.toString());
      this.filter_condition_id.value.push(""); //così prende anche tutti i customfield che non sono stati esplicitati in questi campi
      
      


      this.filter_condition_field.value=[];
      this.filter_condition_field.value.push(this.condition_field);
      this.filter_condition_field.value.push(""); //così prende anche tutti i customfield che non sono stati esplicitati in questi campi

      
    }

    this.ordering.field="c.ordering";
      this.getItems((items:CustomField[])=>{
        
        if(this.list==undefined)
          this.list=[];
        else  
          //azzera la lista
          this.list.splice(0,this.list.length);
        
        //aggiungi ogni customfield del template e verifica se avevi dei valori da inserirci
        for (let i of items){
        
          if(i.access.indexOf(Globals.user.role)>-1){
              //parserizza le opzioni
              if(i.option==null){
                i.option=[];
              }
              else{
                try{
                  i.option=JSON.parse(i.option);
                }catch{
                  i.option=[];
                }
              }
            
            
            
            //cerca i valori dentro listvalue
            let c:CustomField=this.listvalue.filter(r=>r.id==i.id)?this.listvalue.filter(r=>r.id==i.id)[0]:null;
            if(c!=null){
              
              if(c.type=="select"){
                //parserizza le opzioni
                if(c.option==null)
                  i.option=[];
                else{
                  try{
                    if(typeof(c.option)=="string")
                      i.option=JSON.parse(c.option);
                    else
                      i.option=c.option;

                  }catch{
                    i.option=[];
                  }
                }
              }

              i.value=c.value;
              
            }
            
            /*
            if(i.value==null)
              if(i.tag=="features" && i.type=="select")
                i.value=[];
            */
            this.list.push(i);
          }
   
      }
      
      
    },false);
  }

  addTextOnMulti(record:CustomField){
    let result=prompt("Inserisci il valore");
    if(result!=""){
      let array:string[]=[];
      if(record.value==undefined)
        record.value="";
      else
        array=record.value.split("|");
      array.push(result);

      record.value=array.join("|");
    
    }
  }

  removeTextOnMulti(record:CustomField,value:string){
    let array:string[]=[];
    array=record.value.split("|");
    for(let i=0;i<array.length;i++)
      if(array[i]==value){
        array.splice(i,1);
        
      }

    if(array.length>0)
      record.value=array.join("|");
    else
      record.value="";
  }



  verifyTaxCode(record:CustomField):boolean{

    let verifyFiscalCode=new VerifyFiscalCodeService();
    let v:boolean=false;
    this.tax_code_error="";
    if(record.value!=undefined){
      v=verifyFiscalCode.validate(record.value);
      if(!v)
        this.tax_code_error=verifyFiscalCode.error_message;
    }
    return v;
  }

  counterClass=0;

  get divisor():boolean{
    if(this.counterClass>=8){
      this.counterClass=0;
      return true;
    }else{
      return false;
    }
  };

  calculateClassSize(type){
    switch(type){
      case "checkbox":
      case "number":
      case "currency":
        this.counterClass=this.counterClass+4;
        return 2;

      case "date":
      case "select":
        this.counterClass=this.counterClass+5;
          return 3;

      case "text":
      case "tax_code":
      case "url":
        this.counterClass=this.counterClass+6;
          return 4;
      case "separator":
        this.counterClass=0;
        return 12;


    }
  }


  isListValid(){
    if(Array.isArray(this.list))
      return true;
    return false;
  }

  getBasePath(path){
    if(path)
      return Globals.config.serverUrl+"/"+path;
    return "";
  }

  downloadFile(relative_path){
    window.open( this.getBasePath(relative_path),"_blank");
  }

  uploadFile(field){
    Globals.modal.showModal(UploadFileComponent,[
      {"name":"record","value":field},
      {"name":"params","value":field.params},

    ],(instance)=>{

      if(instance){
        field.value=instance['filename'];
      }
    },"md");
  }
  

}
