<app-windowlist [model]="this">
    
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box">
            <small>
                <app-filterdate [date_type]="default_filterdate" [future]="true" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="updateList($event)"></app-filterdate>
                <ul class="card small-box list-group treeviewlist p-1 mt-2">
                    <li class="list-group-item" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList()" style="cursor:pointer;">Tutte le tipologie</li>
                    <li class="list-group-item" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_activities" (click)="filter_id_type.value=t.id;updateList()" style="cursor:pointer;">{{t.name}}</li>
                    <div *ngIf="type_activities.length>2" class="icon"><i class="fas fa-stream"></i></div>
                </ul>
            </small>
        </div>
        <div [class.col-lg-10]="filter_box" [class.col-lg-12]="!filter_box" >
            <div class="card card-outline mb-0">
                <div class="p-0">
                    <div class="row" *ngIf="filter_box">
                        <div class="col-6">
                            <div class="input-group input-group-sm m-1" >
                                <div class="input-group-prepend">
                                    <input type="text"  class="form-control form-control-sm float-right"  size="4" placeholder="ID" [(ngModel)]="filter_id.value" (keyup.enter)="updateList()">
                              
                                </div>
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cliente o descrizione" [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append">
                                    <input type="text"  class="form-control form-control-sm float-right" placeholder="comune" [(ngModel)]="filter_search_city.value" (keyup.enter)="updateList()">
                              
                                </div>
                                <div class="input-group-append ">
                                    
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!='' || filter_search_city.value!=''" (click)="filter_search.value='';filter_search_city.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()">
                                <option value="">Tutti gli operatori</option>
                                <ng-container *ngFor="let u of users">
                                    <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_phase.value" (ngModelChange)="updateList()">
                                <option value="">Tutte le fasi</option>
                                <option value="1">In corso</option>
                                <option value="2">Completate</option>
                                <option value="3">Confermate</option>
                            </select>
                        </div>
                        
                        <div class="col-2" *ngIf="mode!='modal'">
                            <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>
                    <ng-container *ngIf="modeView==1">
                        <div class=" m-4" *ngIf="list.length==0">
                            <i class="fa-2x fas fa-info text-info mr-3"></i>
                            <i>Nessuna voce di attività trovata!</i>
                        </div>

                        <form [formGroup]="form" *ngIf="list.length>0">
                            
                                <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" *ngIf="list.length>0">
                                    <thead class="bg-light">
                                        <th><div #checkboxselectall class="icheck-primary d-inline mr-3">
                                                <input type="checkbox"
                                                (change)="onChangeAll($event.target.checked)" />
                                            </div>
                                        </th>
                                        <th>Data</th>
                                        <th *ngIf="mode!='embedded'">Riferimento</th>
                                        <th *ngIf="mode!='embedded'">Ubicazione</th>
                                        <th>Attività</th>
                                        
                                        <th>Operatore</th>

                                        <th>Stato</th>
                                        <th>Versato</th>
                                        <th></th>
                                    </thead>
                                    <thead>
                                        <tr class="bg-info" >
                                            <td colspan="5" class="text-left"><b>TOTALE</b></td>
                                            <td colspan="2" *ngIf="mode!='embedded'"></td>
                                            <td class="text-right" [class.text-red]="total_paid < 0">
                                                <b>{{total_paid | currency:'EUR':true }}</b>
                                            </td>
                                            
                                            <td ></td>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let activities of list">
                                            <td>
                                                <div class="icheck-primary d-inline mr-3">
                                                    <input type="checkbox" #checkrecord id_record="{{activities.id}}"
                                                    (change)="onChange(activities.id, $event.target.checked)" [checked]="checkedAll"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <a (click)="openDetail(activities)"  style="cursor:pointer;">
                                                    {{activities.date | date:'dd/MM/yyyy'}} {{activities.time}}<br/>
                                                    <small>
                                                        <i>id: {{activities.id}}</i>
                                                    </small>
                                                    <small>
                                                        <div class="alert alert-warning" *ngIf="activities.phase==4">
                                                            da rischedulare a {{activities.reschedule | date:'dd/MM/yyyy'}}<br/>
                                                            <i>{{activities.note_reschedule}}</i>
                                                        </div>

                                                        
                                                    </small>
                                                </a>
                                            </td>
                                            <td *ngIf="mode!='embedded'">
                                                <app-reference [reference]="activities.reference" [table]="activities.table"></app-reference>
                                            </td>
                                            <td *ngIf="mode!='embedded'">
                                                {{activities.address}}<br/>
                                                <strong>{{activities.city}}</strong> {{activities.country}}
                                                <span *ngIf="activities.latitude==0"><div class="badge badge-danger">NO GPS</div></span>
                                                
                                            </td>
                                            <td>
                                                {{activities.type}}&nbsp;
                                                <span *ngIf="(activities.amount>0 || activities.paid>0) && activities.enable_accounting"  
                                                    [class.badge-danger]="activities.id_cashflow==0" 
                                                    [class.badge-warning]="activities.id_cashflow>0 && !hasCashflowPaid(activities)" 
                                                    [class.badge-success]="activities.id_cashflow>0 && hasCashflowPaid(activities)" 
                                                    class="badge ml-1">
                                                    <i class="fas fa-coins" (click)="openCashflow(activities.id_cashflow)"></i>
                                                </span>
                                               
                                                <ng-container *ngIf="activities.documents && activities.documents.length>0">
                                                    <span  
                                                        [class.badge-danger]="activities.documents[0].cashflow.length==0" 
                                                        [class.badge-success]="activities.documents[0].cashflow.length>0" 
                                                        class="badge ml-1">
                                                        <i class="fas fa-coins" (click)="openCashflow(activities.documents[0].cashflow[0].id)"></i>
                                                    </span>
                                                </ng-container>


                                                <span *ngIf="activities.id_form>0" class="badge badge-info">
                                                    <i class="fab fa-wpforms"></i>
                                                </span>
                                                <br/><small>{{activities.description}}</small>
                                                <i *ngIf="activities.priority==2" class="text-warning fas fa-exclamation"></i> 
                                                <i *ngIf="activities.priority==3" class="text-danger fas fa-exclamation"></i>
                                                <ng-container *ngIf="activities.note">
                                                    <hr/>
                                                    <small>Note: {{activities.note}}</small>
                                                </ng-container>
                                                
                                                <ng-container *ngIf="activities.documents && activities.documents.length>0">
                                                    <br/>
                                                    <small>
                                                        <div class="badge badge-info" *ngFor="let d of activities.documents" (click)="openDocument(d);" style="cursor:pointer">
                                                            <i class="fa fa-file"></i> &nbsp;Documento n° {{d.reference}} {{d.referenceprefix}} del {{d.date}}
                                                        </div>
                                                    </small>
                                                </ng-container>
                                            </td>
                                            
                                            <td>{{activities.username}}</td>
                                            <td>
                                                <i *ngIf="activities.phase==4" (dblclick)="reschedule(activities)" class="text-warning fa fa-lg fa-2x fa-exclamation"></i>
                                                <i *ngIf="activities.phase==3" class="text-success fa fa-lg fa-2x fa-check-double"></i>
                                                <i *ngIf="activities.phase==2" (dblclick)="confirmActivities(activities)" class="text-success fa fa-2x fa-check"></i>
                                                <i *ngIf="activities.phase==1" class="text-warning far fa-lg fa-2x fa-clock"></i>                                           
                                            </td>
                                            <td class="text-right">
                                                {{activities.paid | currency:'EUR':true }}
                                                <small>
                                                    <div class="alert alert-warning" *ngIf="activities.paid_description!=''">
                                                        <i>{{activities.paid_description}}</i>
                                                    </div>
                                                </small>

                                                <small>
                                                    <div class="alert alert-warning" *ngIf="activities.paid_type==3">
                                                        <i>Pagamento rischedulato al {{activities.reschedule_payment | date:"d/M/Y"}}</i>
                                                    </div>
                                                </small>

                                            </td>
                                            <td>
                                                <a (click)="openDetail(activities)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right">
                                                    <i class="fas fa-pencil-alt"></i></a>

                                                <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" (click)="notify(activities);" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                                    <i class="far fa-paper-plane"></i><span class="d-none d-sm-inline-block"></span>
                                                </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="bg-info">
                                            <td colspan="5" class="text-left"><b>TOTALE</b></td>
                                            <td colspan="2" *ngIf="mode!='embedded'"></td>
                                            <td class="text-right" [class.text-red]="total_paid < 0">
                                                <b>{{total_paid | currency:'EUR':true }}</b>
                                            </td>
                                            
                                            <td ></td>
                                        </tr>

                                    </tfoot>
                                </table>
                            

                            
                        </form>
                    </ng-container>
                    <ng-container *ngIf="modeView==2">
                        <div id="map" style="height: 100%;width: 100%;" [style.min-height]="map_height"></div>
                        <div *ngIf="loadingMap" id="loading"  [style.min-height]="map_height">
                            Caricamento in corso...
                        </div>
                        
                    </ng-container>
                </div>
                <ng-container *ngIf="modeView==1">
                    <div class="card-footer clearfix py-1">
                        <app-pagination [model]="this" ></app-pagination>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</app-windowlist>

       
<ng-template #rescheduleModal>
    <div class="modal-header">
        <h5>Rischedula attività</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeReschedule()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Azione</label>
            <select class="form-control form-control-sm" name="rescheduleAction" [(ngModel)]="rescheduleAction">
                <option value="1">Crea nuova voce di agenda</option>
                <option value="2">Modifica data di questa attività</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmReschedule()">Conferma</button>
        <button class="btn btn-secondary" (click)="closeReschedule()">Annulla</button>
        
    </div>

</ng-template>