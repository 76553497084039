<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-4 mb-1">
            <small>
                <app-treecategories [class]="'card p-1'" [multiple]="true" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [editButton]="false" #tree [list]="categories"></app-treecategories>
            
                <ul class="card small-box list-group p-1 mt-2" *ngIf="isModuleEnabled('inventories')">
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;getItems();" style="cursor:pointer;">Tutti</li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;getItems();" style="cursor:pointer;">Giacenza positiva</li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==7" (click)="filter_stock.mode=7;getItems();" style="cursor:pointer;">Giacenza negativa</li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;getItems();" style="cursor:pointer;">Giacenza nulla</li>
                    <div class="icon"><i class="fas fa-cubes"></i></div> 
                </ul>
            </small>
        </div>
        <div class="col-lg-8 pl-lg-0 mb-1">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="input-group input-group-info input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per modello, codice articolo, codice fornitore o EAN" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 px-lg-0">
                            <div class="input-group input-group-sm m-1" >
                                
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_brand.value" (ngModelChange)="getItems()">
                                    <option value="">Tutte le marche</option>
                                    <option *ngFor=" let r of brands" [ngValue]="r.id">{{r.name}}</option>
                                </select>
                                <div class="input-group-append ">
                                    <button class="btn btn-sm btn-default" (click)="openBrandsManager()"><i class="fa fa-cog"></i></button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm m-1" >
                                
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()">
                                    <option value="">Tutte le tipologie</option>
                                    <option *ngFor=" let r of types" [ngValue]="r.id">{{r.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 pl-lg-0 py-1">
                            <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-2">
                                <input  type="checkbox"
                                (change)="onChangeAll($event.target.checked)" />
                            </div>
                            <ng-container *ngIf="canEdit">
                                <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mr-1 mt-1"><i class="far fa-trash-alt"></i></button>
                                <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mr-1mt-1 "><i class="fas fa-trash"></i></button>
                                <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right mr-1 mt-1"><i class="fas fa-undo"></i></button>
                                <button data-toggle="tooltip" title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right mr-1 mt-1"><i class="fas fa-file-export"></i></button>
                                <button data-toggle="tooltip" title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right mr-1 mt-1"><i class="fas fa-plus"></i></button>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="list==null" class="m-4">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>
                    <form class="border-top" *ngIf="list!=undefined">

                        <ul class="list-group" *ngIf="list!=null">
                            <li class="list-group-item" *ngFor="let record of list">
                                <div class="row border-bottom pb-1">
                                    <div *ngIf="isModuleEnabled('inventories')" class="col-1 pt-3 text-center" [class.text-success]="record.stock>0" [class.text-gray]="record.stock==0" [class.text-danger]="record.stock <0">
                                        <i *ngIf="!record.icon" class="fas fa-2x fa-box-open"></i>
                                        <i class="fa-2x {{record.icon}}"></i>
                                        <h6 *ngIf="record.stockable">{{record.stock}} {{record.unit}}</h6>
                                    </div>
                                    
                                    <div class="p-0" [class.col-7]="isModuleEnabled('inventories')" [class.col-8]="!isModuleEnabled('inventories')" >
                                       <app-productitem (OnSelected)="openDetail(record)" [product]="record"></app-productitem>
                                        
                                    </div>
                                    <div class="col-2 text-right" *ngIf="showPrice">
                                        <h5 *ngIf="record.pricelist!=undefined && record.pricelist.length>0" data-toggle="tooltip"  title="prezzo di vendita (IVA inclusa)" class="text-success mb-0">{{record.pricelist[0].price*(1+record.tax/100) | currency:"&euro; "}}</h5>
                                        <h5 *ngIf="record.pricelist==undefined || record.pricelist.length==0" data-toggle="tooltip"  title="nessun prezzo di vendita">nd</h5>
                                        <h6  class="text-secondary" data-toggle="tooltip"  title="prezzo di acquisto (IVA inclusa)">{{record.netcost*(1+record.tax/100) | currency:" &euro; "}}</h6>
                                    </div>
                                    <div class="col-2 pr-0" [class.col-4]="!showPrice">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" data-toggle="tooltip" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                        />&nbsp;
                                        <button *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                                        <button  (click)="duplicate(record)" data-toggle="tooltip" title="Duplica" class="btn btn-info btn-xs float-right ml-1">
                                            <i class="far fa-clone"></i><span class="d-none d-sm-inline-block"></span></button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)"  data-toggle="tooltip" title="Stampa" class="btn btn-warning btn-xs float-right ml-1">
                                                <i class="fas fa-print"></i></button>
                                        <button *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right ml-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                    </div>
                                    
                                </div>
                            </li>
                            
                        </ul>

                        <!--<table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" >
                            <thead class="bg-light">
                                <th>
                                    <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" />
                                    &nbsp;Cod
                                </th>
                                <th>Marca e Modello</th>
                                <th>Categoria</th>
                                <th>Prezzo</th>
                                <th>Costo</th>
                                <th>Giacenza</th>
                                <th>
                                </th>
                            </thead>
                            <tbody *ngIf="list!=null">

                           
                            <tr *ngFor="let record of list" class="border" >
                                <td><input type="checkbox" #checkrecord id_record="{{record.id}}" (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll" />
                                &nbsp;<small>{{record.codeinternal}}</small>
                                </td>
                                <td>
                                    <a  class="text-info" [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)">
                                    <i class="{{record.icon}}"></i>
                                    {{record.brand}}<br/><strong>{{record.name}}</strong> <span *ngIf="record.has_serials">&nbsp;<i class="fas fa-barcode"></i></span>
                                    </a>
                                </td>
                                <td>{{record.category}}</td>
                                <td class="bg-success" *ngIf="record.pricelist!=undefined && record.pricelist.length>0" >{{record.pricelist[0].price | currency:"&euro; "}}</td>
                                <td *ngIf="record.pricelist==undefined || record.pricelist.length==0">nd</td>
                                <td class="bg-light">{{record.netcost | currency:" &euro; "}}</td>
                                <td ><span [class.text-success]="record.stock>0" [class.text-danger]="record.stock <=0">{{record.stock}} {{record.unit}}</span></td>
                                <td>
                                    <button *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                        <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                                    <button *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record)">
                                        <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                    
                                </td>
                            </tr>
                            
                            </tbody>
                        </table>-->
                        <!-- /.table-responsive -->

                        
                    </form>
                
                        
                
                </div>
            
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
        
    
        
        
    </div>  
    
</app-windowlist>


