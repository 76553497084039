<app-windowlist [model]="this">
    <div class="row">
        <div class="col-12">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-9">
                            <div class="input-group input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome form ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                               
                       
                                
                            </div>
                        </div>

                        <div class="col-3">
                            
                            <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button data-toggle="tooltip" title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            
                        </div>

                    </div>

                    <div *ngIf="list==null" class="m-4">
                        <h5 class="text-info"><i class="icon fas fa-info-circle mr-2"></i>Nota:</h5>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>

                    <form [formGroup]="form" *ngIf="list!=null">
                        <table class="table">
                            <thead>
                                <th width="40px">
                                    <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-3">
                                        <input  type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Nome modulo</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of list">
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                        />&nbsp;
                                    </td>
                                    <td><strong>{{record.name}}</strong></td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                       



                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
    
   
</app-windowlist>