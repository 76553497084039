

  <div class="wrapper bg-light" *ngIf="currentIsLogin==true">

    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-light border-bottom-0 fixed-top elevation-1">
      <!-- Left navbar links -->
      <ul class="navbar-nav" *ngIf="menu.length>1">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" ><i class="fas fa-bars pt-2" title="Scopri/Nascondi menu"></i></a>
        </li>
      </ul>
      <div class="navbar-nav" >
        <h5 class="text-secondary m-0" *ngIf="history.length > 0"><b>{{history[history.length-1].label}}</b></h5>
      </div>
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">

        <!-- User login -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <div *ngIf="!currentUser['shop']" style="padding-top: 6px"></div>
            <i class="fas fa-user-tie mr-2"></i>
            <span data-toggle="tooltip" title="{{currentUser.name}}" class="d-none d-sm-inline-block"><span style="max-width:150px;" class="mb-0 text-truncate">{{currentUser.name}}</span></span><br/>
            <small data-toggle="tooltip" title="{{currentUser['shop']['name']}}" style="max-width:120px; font-size:7pt" class="d-none d-sm-inline-block float-left text-uppercase text-truncate" 
              *ngIf="currentUser['shop']">{{currentUser['shop']['name']}}
            </small>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
            <div class="card mb-0">
                <div class="card-header text-center p-1 bg-primary">
                  <b style="max-width:150px;" class="mb-0 text-truncate">{{currentUser.username}}</b><br>
                  <small class="text-uppercase"><small>{{currentUser.role}}</small></small>
                </div>
                <div class="card-body p-0">
                  <a *ngIf="enableSelectPoint" (click)="openSelectPoint()" class="dropdown-item" style="cursor:pointer;"><i class="fas fa-map-marker-alt mr-2"></i>Cambia punto vendita
                    <small style="max-width:150px; font-size:7pt" class="d-block d-sm-none text-uppercase text-truncate" *ngIf="currentUser['shop']">{{currentUser['shop']['name']}}</small>
                  </a>
                  <a (click)="logout()" class="dropdown-item"  style="cursor:pointer;"><i class="fas fa-sign-out-alt mr-2"></i>Esci</a>
                </div>
            </div>
          </div>
        </li>

         <!-- Connector -->
        <li class="nav-item" style="color: rgba(0, 0, 0, 0.5)" title="C1 Connector" *ngIf="enableC1Connector">
          <span class="nav-link">
            <i [class.text-success]="c1connector_status" class="fas fa-network-wired mt-2 px-2"></i><br>
              <span class="float-left" style="font-size:7pt">{{c1connector_c1connectorid}}</span>
          </span>
        </li>

        <!-- Notifiche -->
        <li class="nav-item dropdown">
          <app-alarm></app-alarm>
        </li>

         <!-- Chat -->
         <li class="nav-item dropdown">
          <app-chat></app-chat>
        </li>

        <!-- Impostazioni -->
        <li class="nav-item dropdown" *ngIf="menu_settings.length>0" >
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false" title="Impostazioni">
            <i class="fas fa-cog mt-2"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
            <div class="card mb-0">
              <div class="card-header text-center p-1 bg-primary">Impostazioni</div>
              <div class="card-body p-0">
                <a *ngFor="let m of menu_settings"  (click)="goToView(m)" class="btn btn-light  dropdown-item">
                  <i class="{{m.icon}} mr-2"></i>{{m.label}}
                </a>
              </div>
             </div>
          </div>
        </li>

      </ul>
    </nav>
    <!-- /.navbar -->
  
   

    <!-- Main Sidebar Container -->
    <aside  class="main-sidebar sidebar-dark-primary position-fixed" >
      <!-- Brand Logo -->
      <p class="brand-link elevation-1 mb-0">
        <img *ngIf="config.softwareicon" class="brand-image" src="{{config.softwareicon}}">
        <span class="ml-2 brand-text text-white">{{config.softwarename}}</span>
      </p>  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column nav-compact" data-widget="treeview" role="menu" data-accordion="false"> <!-- per comprimere: nav-compact -->
              <li class="nav-item" *ngFor="let m of menu" >
                  <a class="d-none d-lg-block nav-link" (click)="goToView(m)" [class.active]="m==current_module" >
                    <i class="nav-icon {{m.icon}} mr-2"></i><p>{{m.label}}</p>
                  </a>
                  <a class="d-block d-lg-none nav-link" (click)="goToView(m)" [class.active]="m==current_module" data-widget="pushmenu" > <!-- per auto-nascondersi data-widget="pushmenu"  -->
                    <i class="nav-icon {{m.icon}} mr-2"></i><p>{{m.label}}</p>
                  </a>
              </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
    <div id="sidebar-overlay">
      <a class="close_sidebar d-block d-lg-none" data-widget="pushmenu" href="#" ><i class="fas fa-times"></i></a>
    </div>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper p-md-1" style="margin-top:56px;">
        <router-outlet></router-outlet>
        <div>
          <!-- Use ng-template to ensure that the generated components end up in the right place -->
          <ng-template #mainContent></ng-template>
        </div>
    </div>
    <!-- /.content-wrapper -->
    
    <!-- Right Sidebar -->
    <aside *ngIf="info" class="control-sidebar bg-secondary position-fixed d-block" style="height: calc(100% - 56px - 16px)">
      <button type="button" class="btn float-right" (click)="info==false?info=true:info=false">
        <i class="fas fa-times text-light"></i>
      </button>
      <div class="p-3 pt-4 control-sidebar-content">
        <small>
        <b>PER INIZIARE</b><br>
          Lorem ipsum....

        </small>
      </div>
    </aside>
    <!-- /.Right-sidebar -->
  
    <!-- Main Footer width: calc(100% - 250px); -->
    <footer class="main-footer d-none d-sm-inline-block fixed-bottom py-0 text-xs">
      <div class="float-left mx-2"><span class="text-primary"><b>C1</b></span></div>
      <div class="float-left mr-5">version <span class="text-primary">{{config.softwareversion}} ({{build}})</span></div>
      <div *ngIf="copyright" class="float-left mr-5 d-none d-lg-block">is a <a href="http://codingict.it" target="_blank">Coding</a> software</div>
      
      
      <div class="float-right d-none d-sm-inline-block ml-5">Licenza:&ensp;<span data-toggle="tooltip" title="Server: {{config.serverUrl}}" class="text-primary">{{company.name}}<span *ngIf="company.vat_number"> (P.IVA: {{company.vat_number}})</span></span></div>
      <!-- <div (click)="openModalRegistrationInfo()" style="cursor: pointer;" class="float-right ml-5 d-none d-lg-block">Scadenza: <span [class.text-primary]="true" [class.text-danger]="false">31/12/2022</span></div> -->
      <!-- <div class="float-right ml-5 d-none d-lg-block"><i class="fa-hdd far mr-1"></i><span [class.text-primary]="true" [class.text-danger]="false">0.2 Gb</span></div> -->
    </footer>

</div>




<ng-template #modalRegistrationInfo let-modal>
  <div class="modal-header py-1 bg-light">
    <b>Dati di registrazione</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <span>Concesso in licenza a: </span><b>{{company.name}}</b><br>
    <span>Scadenza: </span><b>31/12/2022</b><br>
    <span>Stato: </span><b [class.text-success]="true">ATTIVA</b><br>
    <div class="text-center mt-4">
      <button (click)="modal.close('cancel')" class="btn btn-secondary ">Chiudi</button>
    </div>
  </div>
</ng-template>
