import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Notification } from '../db/notification'
import { WSService } from 'projects/core/src/include/service'
import { ToastMode } from 'projects/core/src/include/structures';
import { PreviewnotificationComponent } from '../modules/previewnotification/previewnotification.component';


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends WSService<Notification> {

  constructor() { super("notifications") };

  getNotifications(table:string,id_record:number):Observable<Notification[]>{
    return  Globals.ws.requestUrl<Notification[]>("notifications","getNotifications",[table,id_record.toString()]);
    
  }

  getAllNotifications():Observable<Notification[]>{
    return  Globals.ws.requestUrl<Notification[]>("notifications","getItems",[]);
    
  }

  sendNotify(item:Notification,id_address:number,onComplete:Function){
    item['id_address']=id_address;
    let timerConnection=null;
    let sended=false;
    let sended_whatsapp=false;
    let enable_sms_c1connector=Globals.parameters.get("notifications").getParam("sms_c1connector",false);

    if(enable_sms_c1connector){
      var subscription_sms=Globals.c1connector.onMessageReceived.subscribe((result)=>{
        if(result['cmd']=="sms_sended"){
          Globals.loading=false;
          if(result['status']){
            Globals.message.showToaster("SMS inviato correttamente al numero "+result['number'],ToastMode.SUCCESS);
          }else{
            Globals.message.showToaster("Errore nell'invio SMS\n"+result['error'],ToastMode.DANGER);
  
          }
          subscription_sms.unsubscribe();
          if(timerConnection!=null)
            clearTimeout(timerConnection);
          sended=true;
        
        }
      });
      var subscription_whatsapp=Globals.c1connector.onMessageReceived.subscribe((result)=>{
    

        if(result['cmd']=="whatsapp_sended"){
          Globals.loading=false;
          if(result['status']){
            Globals.message.showToaster("Messaggio WhatsApp inviato correttamente al numero "+result['number'],ToastMode.SUCCESS);
          }else{
            Globals.message.showToaster("Errore nell'invio del messaggio WhatsApp\n"+result['error'],ToastMode.DANGER);
  
          }
          subscription_whatsapp.unsubscribe();
          if(timerConnection!=null)
            clearTimeout(timerConnection);
            sended_whatsapp=true;
        
        }


      });
    }

    //trova il gateway di invio sms
    item.gateways.forEach(element => {
      if(element.gateway=="sms" && enable_sms_c1connector && element.enable==1){
        var m={};
        m['cmd']="sendSMS";
        m['type']="smsgateway";
        var s={};
        s['id_user']=Globals.user.id;
        s['id_address']=item['id_address'];
        s['number']=element['contact'];
        try{
          let message=JSON.parse(element['message']);
          s['message']=message['body'];
        }catch{
          s['message']="";
        }

        
        m['value']=s;

        //verifica che il c1connector sia connesso


        if(Globals.c1connector.connected){
          Globals.c1connector.sendMessage(m);
          Globals.loading=true;

          timerConnection=setTimeout(()=>{
            if(!sended){
              Globals.loading=false;
              Globals.message.showToaster("Il server SMS ci mette troppo tempo. Controllare che sia attivo",ToastMode.DANGER);
            }
            if(timerConnection!=null)
            clearTimeout(timerConnection);
          
          },10000)


          //exit;
        }
      }

      if(element.gateway=="whatsapp" && enable_sms_c1connector && element.enable==1){
        var m={};
        m['cmd']="sendWhatsappMessage";
        m['type']="whatsapp";
        var s={};
        s['id_user']=Globals.user.id;
        s['id_address']=item['id_address'];
        s['number']=element['contact'];
        try{
          let message=JSON.parse(element['message']);
          s['message']=message['body'];
        }catch{
          s['message']="";
        }

        
        m['value']=s;

        //verifica che il c1connector sia connesso


        if(Globals.c1connector.connected){
          Globals.c1connector.sendMessage(m);
          Globals.loading=true;

          timerConnection=setTimeout(()=>{
            if(!sended_whatsapp){
              Globals.loading=false;
              Globals.message.showToaster("Il server WhatsApp ci mette troppo tempo. Controllare che sia attivo",ToastMode.DANGER);
            }
            if(timerConnection!=null)
            clearTimeout(timerConnection);
          
          },20000)


          //exit;
        }
      }
    });
    if(id_address)
      item['id_address']=id_address;

    return Globals.ws.send(this.table,"send",item,[],(result:string)=>{
      let obj=JSON.parse(result);
     
      if(obj['email']){
        Globals.message.showToaster("Email inviata correttamente",ToastMode.SUCCESS);
      }

      if(obj['sms']){
        Globals.message.showToaster("SMS inviato correttamente",ToastMode.SUCCESS);
      }
      
      /*obj['email']==true?messages.push("Email: Ok"):messages.push("Email: Error");
      
      //TODO da verificare perchè non restituisce correttamente true
      //obj['sms']==true?messages.push("SMS: Ok"):messages.push("SMS: Error");
      //obj['sms']==messages.push("SMS: Ok");
      
      obj['whatsapp']==true?messages.push("whatsapp: Ok"):messages.push("whatsapp: Error");
      
      */


      
    });
  }

  
  showPreviewNotification(contacts:any,record:any,table:string,oncomplete:Function,id_address:number=0,id_notification=0){
    Globals.modal.showModal(PreviewnotificationComponent,[{"name":"table","value":table},{"name":"contacts","value":contacts},{"name":"record","value":record},{"name":"id_notification","value":id_notification}],(instance)=>{
      
      if(instance!=null){
        let notify=instance['selectedNotify'];
      

      
        this.sendNotify(notify,id_address>0?id_address:record.id,(id)=>{

          
          //verifica se i contatti sono da aggiungere
        


          oncomplete(id);
          
        });
      }
    });
  }

}
