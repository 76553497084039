import { Component, OnInit, Input,ViewChild,TemplateRef, Output,EventEmitter } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { FormBuilder } from '@angular/forms';
import { Type } from '../../../db/type';
import { Filter,FilterMode, OrderingMode, ToastMode } from 'projects/core/src/include/structures'
import { DocumentsService } from '../../../services/documents.service';
import { DocumentFile, Documents } from '../../../db/documents';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { DocumentsDetailsComponent } from '../documents-details/documents-details.component';
import { ShopsService } from '../../../services/shops.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { DocumentssdiComponent } from '../documentssdi/documentssdi.component';
import { FinancialAccountsService } from '../../../services/financialaccounts.service';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';
import { formatDate } from '@angular/common';
import { AllinvoicesComponent } from '../allinvoices/allinvoices.component';
import { VerifyFiscalCodeService } from '../../../services/verify-fiscal-code.service';
import { VerifyVATNumberService } from '../../../services/verify-vatnumber.service';
import { NotificationService } from '../../../services/notification.service';
import { FilterdateComponent } from '../../../modules/filterdate/filterdate.component';

@Component({
  selector: 'app-documentlist',
  templateUrl: './documentlist.component.html',
  styleUrls: ['./documentlist.component.sass']
})

export class DocumentlistComponent extends ModelList<Documents> implements OnInit {

  @ViewChild(FilterdateComponent)
  FilterdateComponent:FilterdateComponent;

  modulename="documents";
  
  @Input()
  id_address:number=0;

  @Input()
  type:number=1; //1 vendita //2 acquisto //0= tutti

  @Input()
  instance:string;

  @Input()
  state:number=0;
  
  @Input()
  id_table:number=0;
  
  @Input()
  table:string="";

  @Input()
  filterbox:boolean=true;

  @Input()
  showCard=true;


  @Output()
  ids:EventEmitter<[]>=new EventEmitter();

  date_from: Date=new Date();
  date_to: Date=new Date();
  status_invoices:Type[];
  shops=[];
  methodpayments=[];
  financialaccounts=[];


  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter<number[]>=new Filter<number[]>();
  filter_typedocument:Filter=new Filter();
  filter_state:Filter<number>=new Filter<number>();
  filter_id_table:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_referenceprefix:Filter=new Filter();
  filter_reference:Filter=new Filter();
  filter_created_from:Filter=new Filter();
  filter_sdi_status:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_method_payment:Filter=new Filter();  
  filter_nation:Filter=new Filter();  
  filter_state_payment:Filter=new Filter();  
  filter_financialaccount:Filter=new Filter();  
  filter_referencedocument:Filter=new Filter();

  total_net:number=0;
  total_tax:number=0;
  total_discount:number=0;
  total:number=0;
  paid:number=0;
  total_paid:number=0;
  total_difference:number=0;


  convertTo:number=1;
  referenceprefixTo:string="";
  typeTo:number=1;
  isFilterSet:boolean=false;

  referenceprefix=[];

  @ViewChild('convertDocumentModal')
  convertDocumentModal:TemplateRef<any>;
  reorderNumber_referenceprefix="";
  reorderNumber_year="";
  reorderList=[];
  reorderTab=0;

  @ViewChild('reorderReferenceModel')
  reorderReferenceModel:TemplateRef<any>;
  reorderReferenceModelRef;


  @ViewChild('uploadXMLModal')
  uploadXMLModal:TemplateRef<any>;
  uploadXMLModalRef;
  fileXMLToUpload: File = null;

  @ViewChild('listFileModal')
  listFileModal:TemplateRef<any>;
  listFileModalRef;
  

  


  documentSelected:Document;


  constructor( private fb1: FormBuilder,
    private invoicesService: DocumentsService,
    private shopsService:ShopsService,
    private methodpaymentsService:MethodpaymentsService,
    private modalService: NgbModal,
    private uploadService:UploadService,
    private financialAccountsService:FinancialAccountsService,
    private verifyFiscalCodeService:VerifyFiscalCodeService,
    private verifyVATNumberService:VerifyVATNumberService,
    private notificationService:NotificationService
    
  ) {
    super(invoicesService,fb1);
    this.detailViewModal=DocumentsDetailsComponent;
   }

  ngOnInit(): void {
    this.title = 'Documenti';
    super.ngOnInit.apply(this, arguments); 

    this.referenceprefix=this.module.getParam("sectionals");
    

    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });

    this.methodpaymentsService.getMethods().subscribe((items_m)=>{
      this.methodpayments=items_m;
    });

    this.financialAccountsService.getAccounts().subscribe((items_c)=>{
      this.financialaccounts=items_c;
    });

    if(this.mode!="embedded"){
      this.filter_date.mode=FilterMode.date;
      this.filter_date.fields=[];
      this.filter_date.value=Helper.convertDateControl(this.date_from);
      this.filter_date.value2=Helper.convertDateControl(this.date_to);
      this.filter_date.fields.push("i.date");
      this.filter.push(this.filter_date);
    }



    this.afterGetItems=(()=>{
      this.invoicesService.getTotals(this.filter,this.orderingList,this.advancedFilter,this.type==0?1:0).subscribe((item)=>{
        if(item){
          this.total=item.total;
          this.paid=item.paid;
      
        }
      });
      let current_ids:any=[];
      for(let r of this.list){
        current_ids.push(r.id);
      }
      this.ids.emit(current_ids);
      

    });
    

    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_address);

    this.typeTo=this.type;

  }

  setFilters():void{
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("i.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_financialaccount.mode=FilterMode.normal;
    this.filter_financialaccount.fields=[];
    this.filter_financialaccount.fields.push("i.id_financialaccount");
    this.filter_financialaccount.value="";
    this.filter.push(this.filter_financialaccount);

    this.filter_sdi_status.mode=FilterMode.normal;
    this.filter_sdi_status.fields=[];
    this.filter_sdi_status.fields.push("i.sdi_status");
    this.filter_sdi_status.value="";
    this.filter.push(this.filter_sdi_status);

    this.filter_nation.mode=FilterMode.disabled;
    this.filter_nation.fields=[];
    this.filter_nation.fields.push("i.nation");
    this.filter_nation.value="IT";
    this.filter.push(this.filter_nation);

    this.filter_created_from.mode=FilterMode.normal;
    this.filter_created_from.fields=[];
    this.filter_created_from.fields.push("i.created_from");
    
    
    if(this.user.isAdmin()){
      this.filter_created_from.value="";
    }else{
      this.filter_created_from.value=this.user['shop']?this.user['shop']['id']:"";
    }
    
    this.filter.push(this.filter_created_from);

    if(this.id_table>0){
      
      this.filter_referencedocument.mode=FilterMode.custom;
      this.filter_referencedocument.value=" (i.id_table="+this.id_table.toString()+" AND i.table='"+this.table+"') OR (xref.id_table_dest="+this.id_table.toString()+")";
      this.filter.push(this.filter_referencedocument);
      
    }

    this.filter_method_payment.mode=FilterMode.normal;
    this.filter_method_payment.fields=[];
    this.filter_method_payment.fields.push("i.methodpayment");
    this.filter_method_payment.value="";
    this.filter.push(this.filter_method_payment);

    this.filter_state.mode=FilterMode.normal;
    this.filter_state.fields=[];
    
    this.filter_state.fields.push("i.state");
    this.filter_state.nullvalue=0;
    this.filter_state.value=this.state;
    this.filter.push(this.filter_state);

    
    this.filter_state_payment.mode=FilterMode.custom;
    this.filter_state_payment.fields=[];
    this.filter_state_payment.enabled=false;
    this.filter_state_payment.value="i.total>i.paid AND i.typedocument IN (1,2,3,12,6,9)";

    this.filter.push(this.filter_state_payment);

    
    
    if(this.type>0){
      this.filter_id_type.mode=FilterMode.normal;
      this.filter_id_type.fields=[];
      this.filter_id_type.fields.push("i.id_type");
      this.filter_id_type.value=[this.type];
      this.filter.push(this.filter_id_type);
    }
    this.filter_typedocument.mode=FilterMode.contain;
    this.filter_typedocument.fields=[];
    this.filter_typedocument.fields.push("i.typedocument");
    this.filter_typedocument.value="";
    this.filter.push(this.filter_typedocument);


    this.filter_reference.mode=FilterMode.normal;
    this.filter_reference.fields=[];
    this.filter_reference.fields.push("i.reference");
    this.filter_reference.value="";
    this.filter.push(this.filter_reference);


    this.filter_referenceprefix.mode=FilterMode.normal;
    this.filter_referenceprefix.fields=[];
    this.filter_referenceprefix.fields.push("i.referenceprefix");
    this.filter_referenceprefix.value="";
    this.filter.push(this.filter_referenceprefix);


    

    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("i.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("i.name");
    this.filter_search.fields.push("i.description");
    this.filter_search.fields.push("i.reference");
    this.filter_search.fields.push("i.code");
    this.filter_search.fields.push("a.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    /*
    if(this.mode!="modal" && this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name+this.instance,this.filter);
    */
    this.ordering.field="i.date DESC,CAST(i.reference AS UNSIGNED)";
    this.ordering.mode=OrderingMode.discendent;    
    

    

    
    this.isFilterSet=true;
    
  }

ngOnChanges():void{
  if(this.id_address>0 || this.id_table>0) //avvia la ricerca quando il filtro è attivo
    this.updateList(this.id_address);
}

updateList(id_address:number=0):void{
  if(!this.isFilterSet)
      this.setFilters();

  
  if(id_address>0){
    this.filter_id_address.value=id_address.toString();
    this.paging_count=99999999;
  }

  this.getItems();
}

openConvert(r:Documents){
  this.recordSelected=r;
  this.modalService.open(this.convertDocumentModal);

}

convertDocument(){

  this.invoicesService.convertDocument(this.recordSelected['id'],this.convertTo,this.referenceprefixTo,this.typeTo).subscribe((result)=>{
    if(result){
      this.getItems();
      this.modalService.dismissAll();
    }
  })
  /*
  //crea un nuovo record
  let new_r:Documents = Object.assign({}, this.recordSelected);

  new_r.id=0;
  new_r.date=Helper.convertDateControl();
  new_r.typedocument=this.convertTo;

  this.invoicesService.getNextNumeration(new_r.date,new_r.id_type,new_r.typedocument,new_r.referenceprefix).subscribe((item)=>{
    new_r.reference=item.toString();
    this.invoicesService.save(new_r,()=>{
      this.getItems();
      this.modalService.dismissAll();
    });
  });
*/

  


}

sendEInvoice(id,typedocument){
  console.log(typedocument);
  if(confirm("Sicuro di voler inviare il documento allo SDI?")){
    Globals.setLoading(true);
  
  /* deprecata?
  if(typedocument==3){ //se è una nota di credito
    this.invoicesService.sendCreditNote(id).subscribe((result)=>{
      Globals.setLoading(false);
       this.updateList();
      
      });
      
  }
  */

  if(typedocument==1 || typedocument==2 || typedocument==3){
    this.invoicesService.sendEInvoice(id).subscribe((result)=>{
      Globals.setLoading(false);
       this.updateList();
      
      });
    }
  }
  
    
}

sendMultipleEInvoice(){
  if(this.form.value.id.length==0){
    Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
    return;
  }
  let ids=this.form.value.id;
  if(confirm("Sicuro di voler inviare i documenti selezionati allo SDI?")){
    Globals.setLoading(true);
    this.invoicesService.sendMultipleEInvoice(ids).subscribe((result)=>{
      Globals.setLoading(false);
      this.updateList();
    })
  }
} 


/*
setFilterTypeValue(value){
  let values=[];
  if(this.filter_typedocument.value!='')
      values=this.filter_typedocument.value.split(",");

  let find=false;
  for(let i=0;i<values.length;i++){
    if(values[i]==value){
      values.splice(i,1);
      find=true;
      break;
    }
  }

  if(!find){
    values.push(value);
  }

  this.filter_typedocument.value=values.join(",");
  this.getItems();
}*/

openReorderNumeration(){
  this.reorderTab=0;
  this.reorderReferenceModelRef=this.modalService.open(this.reorderReferenceModel);
}

  btn_next_reorder(){
      switch(this.reorderTab){
        case 0:
          if(this.reorderNumber_referenceprefix=="" || this.reorderNumber_referenceprefix==undefined){
            alert("Impostare un sezionale per il riordino");
            return;
          }
          if(this.reorderNumber_year=="" || this.reorderNumber_year==undefined){
            alert("Impostare un anno per il riordino");
            return;
          }
          this.getReorderNumeration();
          this.reorderTab=1;
          break;
        case 1:
          let listToUpdate=this.reorderList.filter((x)=>x['toupdate']==true);
            
          if(listToUpdate.length==0){
            alert("Nessun elemento verrà modificato");
            this.reorderReferenceModelRef.close("success");
            return;
          }
          if(confirm("Sicuro di voler modificare la numerazione dei record selezionati?")){
            this.invoicesService.saveReorderNumber(listToUpdate,()=>{
              this.reorderReferenceModelRef.close("success");
            });
          }
          break;
      }
  }

  btn_prev_reorder(){
    switch(this.reorderTab){
      case 0:
        this.reorderReferenceModelRef.close("success");
        
        break;
      case 1:
        this.reorderTab=0;
        break;
    }
  }


  getReorderNumeration(){
    this.invoicesService.reorderNumeration(this.reorderNumber_referenceprefix,this.reorderNumber_year,1).subscribe((list)=>{
      this.reorderList=list;
    });
  }

  verifyChangeReorder(r){
    if(r['reference']!=r['new_reference']){
      r['toupdate']=true;
    }else{
      r['toupdate']=false;
      
    }
  }
  
  checkStatusEInvoice(){
    if(confirm("Questa operazione potrebbe richiedere diversi minuti. Procedere?")){
      Globals.message.showToaster("Avviata la procedura di verifica dello stato dei documenti allo SDI. Attendere...",ToastMode.WARNING);
      this.invoicesService.checkStatusEInvoice().subscribe(()=>{
        Globals.message.showToaster("Verifica dello stato dei documenti allo SDI completata.",ToastMode.SUCCESS);
      });
    }
  }


  checkDocumentSdi(){
    /*if(confirm("Questa operazione potrebbe richiedere diversi minuti. Procedere?")){
      Globals.message.showToaster("Avviata la procedura di verifica dei documenti in ingresso dallo SDI. Attendere...",ToastMode.WARNING);
      this.invoicesService.checkDocumentSdi().subscribe(()=>{
        Globals.message.showToaster("Verifica dei documenti in ingresso dallo SDI completato.",ToastMode.SUCCESS);
      });
    }*/
    Globals.modal.showModal(DocumentssdiComponent,[{"name":"mode","value":"modal"}],()=>{

    });
  }


  uploadXML(){
    this.uploadXMLModalRef=this.modalService.open(this.uploadXMLModal);
  }

  closeUploadXMLModal(){
    this.uploadXMLModalRef.close();
  }

  confirmUploadXMLModal(){
    this.uploadService.uploadFile(this.fileXMLToUpload,"documents",(result)=>{

      //elabora il file xml
      this.invoicesService.parsingXMLSdi(result,"").subscribe((parsed)=>{
        if(parsed){
          Globals.message.showToaster("File caricato correttamente",ToastMode.SUCCESS);
          this.getItems();
        }
        else
          Globals.message.showToaster("Errore nella decodifica del file",ToastMode.DANGER);
        
      });

      
     
    },true);
    this.closeUploadXMLModal();
  }

  handleFileXMLInput(files: FileList) {
    
    this.fileXMLToUpload = files.item(0);
   
}


openlistFileModal(d:Document){
  this.documentSelected=d;
  this.listFileModalRef=this.modalService.open(this.listFileModal);
}

closelistFileModal(){
  this.listFileModalRef.close();
}

downloadFile(f:DocumentFile){
  window.open(Globals.config.serverUrl+"/"+f.file);
  this.closelistFileModal();
}

getExtension(f:DocumentFile){
  return f.file.split('.').pop();
}

mergeDocuments(){
  if(this.form.value.id.length!=2){
    Globals.message.showToaster("Selezionare due documenti",ToastMode.WARNING);
    return;
  }

  let ids=this.form.value.id;
  let document1:Documents;
  let document2:Documents;
  //preleva i due documenti
  for(let d of this.list){
    if(d.id==ids[0])
        document1=d;
    if(d.id==ids[1])
        document2=d;
  }

  //verifica che i documenti appartengono alla stessa anagrafica
  if(document1.id_address!=document2.id_address){
    alert("I due documenti non hanno la stessa anagrafica");
    return;
  }

  if(confirm("Unire i due documenti selezionati?  Saranno considerati validi i dati relativi al documento con origine SDI")){
    this.invoicesService.mergeDocuments(document1.id,document2.id).subscribe((result)=>{
      this.getItems();
    });
  }
  



}

resaveDownloadedFileAllDocuments(){
  if(confirm("Questa operazione potrebbe durare diversi minuti. Procedere?")){
    Globals.message.showToaster("Inizio scaricamento file dallo SDI per ogni documento",ToastMode.WARNING);
    
    if(this.type==2){
      this.invoicesService.resaveInBoxDownloadedFileAllDocuments().subscribe(()=>{
        Globals.message.showToaster("Scaricamento file dallo SDI completato",ToastMode.SUCCESS);
        this.getItems();
      });
    }

    if(this.type==1){
      this.invoicesService.resaveOutBoxDownloadedFileAllDocuments().subscribe(()=>{
        Globals.message.showToaster("Scaricamento file dallo SDI completato",ToastMode.SUCCESS);
        this.getItems();
      });
    }
    
  }
}


addPayment(record:Documents){
  Globals.modal.showModal(CashFlowDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"description","value":record.id_type==1?'Incasso doc. n.'+record.reference+" del "+formatDate(record.date,'dd-MM-yyyy',"it-IT"):'Pagamento doc. n.'+record.reference+" del "+formatDate(record.date,'dd-MM-yyyy',"it-IT")},
    {"name":"id_table","value":record.id},
    {"name":"table","value":"documents"},
    {"name":"id_address","value":record.id_address},
    {"name":"amount","value":parseFloat(record.total.toString()).toFixed(2)},
    {"name":"paid","value":parseFloat(record.total.toString()).toFixed(2)},
    {"name":"id_type","value":record.id_type},
    {"name":"reference","value":record},
    {"name":"methodpayment","value":record.methodpayment}
  ],(instance)=>{
    this.getItems();
  });
}

typedocument_to_accounting(r){
  if(
    r.typedocument ==1 ||
    r.typedocument ==2 ||
    r.typedocument ==12 ||
    r.typedocument ==3 ||
    r.typedocument ==6  ||
    r.typedocument ==7  ||
    r.typedocument ==8  ||
    r.typedocument ==9  ||
    r.typedocument ==11
    
   ){
     if(r.state==2)
      return true;
  }  

  return false;
}

checkAllInvoices(){
  Globals.modal.showModal(AllinvoicesComponent,[
    {"name":"mode","value":"modal"}
  ],()=>{
    this.getItems();
  });
}

verifyBillingAddress(r){
  let message:string="";

  if(r.nation && r.nation == 'IT'){
    if(!r.tax_code && !r.vat_number){
      message+="codice fiscale e partita IVA assenti\n"
    }
    if(r.tax_code && !this.verifyFiscalCodeService.validate(r.tax_code)){
      message+="codice fiscale errato\n"
    }
    if(r.vat_number && !this.verifyVATNumberService.validate(r.vat_number)){
      message+="partita IVA errata\n"
    }
    if(!r.address || !r.city || !r.country || r.zip.toString().length < 5){
      message+="indirizzo di fatturazione incompleto\n"
    }
    if(!r.pec && r.sdi.toString().length < 6){
      message+="codice SDI o PEC errati\n"
    }
  }

  if(r.nation && r.nation != 'IT'){
    if(r.tax_code!='XXXXXXXXXXX' || r.vat_number!='XXXXXXXXXXX'){
      message+="codice fiscale o partita IVA non corretti\n"
    }  
    if(!r.address || !r.city ||  r.zip!='00000'){
      message+="indirizzo di fatturazione non corretto\n"
    }
    if(r.pec || r.sdi!='XXXXXXX'){
      message+="codice SDI o PEC errati\n"
    }
  }

  if(!r.methodpayment || !r.paymentschedule){
    message+="metodo di pagamento o data di scadenza del pagamento assenti\n"
  }
  
  return message;
}


updateFinancialAccount(record,financialaccount){
  record.id_financialaccount=financialaccount['id'];
  record.financialaccount=financialaccount['name'];
  
  this.invoicesService.save(record,()=>{
    record['editFinancial']=false;
  });
}

notify(record:Documents){
   
  this.notificationService.showPreviewNotification(record.addressItem.contacts,record,"documents",()=>{

  });
  
  
  
}

onSearchEnter(){
  if(this.filter_search.value!="" || this.filter_reference.value!=""){
    this.FilterdateComponent.setDate(100);
   
  }else{
    this.updateList();
  }

}


}


