import { Injectable } from '@angular/core';
import {Company} from "./include/structures";
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Globals } from './globals';

@Injectable()
export class Config{

     //variabili generali
     id="";
     softwarename="";
     softwareversion="";
     softwareicon="";
     license="";
     company:Company=new Company();
     favIcon: HTMLLinkElement = document.querySelector('#appIcon');
     serverUrl="";
     copyright=true;
 
     config:any; //file di configurazione

     constructor(
        private http:HttpClient,
        private titleService: Title
  
      ){}
     
    loadConfig(onComplete:Function,setTitle=true){
        this.http.get(Globals.rootpath+"config.json").subscribe((value)=>{
          
          
          
          this.config=value;
          
          this.serverUrl=this.config['serverUrl'];
          Globals.ws.base=this.config['serverUrl']+"/api.php";
          this.softwareversion=this.config['version'];
          this.softwarename=this.config['name'];
          this.license=this.config['license'];
          this.softwareicon=this.config['icon'];
          this.company=this.config['company'];
          let allowRole=this.config['allowRole']==undefined?['admin']:this.config['allowRole'];
          this.copyright=this.config['copyright']==undefined?this.config['copyright']:true;
          this.id=this.config['id']==undefined?this.config['name']:this.config['id'];
          Globals.access.allowRole=allowRole;
          
          //if(this.config['favicon'])
            //this.favIcon.href=this.config['favicon'];
          if(setTitle)
            this.titleService.setTitle(this.softwarename);
          
          /*configurazioni*/
          Globals.access.prefix=this.softwarename;
          
          //this.http.get(Globals.rootpath+"build.txt").subscribe((build)=>{
          //  this.softwareversion=this.softwareversion+"."+build;
          //});

          if(onComplete)
            onComplete();
          
        });
      }
}