import { NgModule } from '@angular/core';
import { CashFlowContainerComponent } from './cash-flow.component';
import { CashFlowDetailsComponent } from './cash-flow-details/cash-flow-details.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { CashFlowRoutingModule } from './cash-flow-routing.module';
import { ContactModule } from '../../modules/contact/contact.module';
import { AddressModule } from '../address/address.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { NoteboxModule } from '../../modules/notebox/notebox.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ConfirmcashflowComponent } from './confirmcashflow/confirmcashflow.component';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { CashFlowOverviewComponent } from './cash-flow-overview/cash-flow-overview.component';
import { CashFlowComponent } from './cash-flow-list/cash-flow-list.component';
import { CashregisterComponent } from './cashregister/cashregister.component';
import { CashregisterDetailComponent } from './cashregister/cashregister-detail/cashregister-detail.component';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { FinancialaccountModule } from '../../modules/financialaccount/financialaccount.module';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        CitiesModule,
        PaginationModule,
        AdvancedsearchModule,
        CashFlowRoutingModule,
        ContactModule,
        ToolbarmodelModule,
        SelectfieldcustomModule,
        ContactModule,
        NoteboxModule,
        FilterdateModule,
        ModalModule,
        ReferenceModule,
        FinancialaccountModule

    ],
   exports: [CashFlowComponent,CashFlowDetailsComponent,ConfirmcashflowComponent,CashFlowOverviewComponent],
   declarations: [CashFlowComponent,CashFlowDetailsComponent,ConfirmcashflowComponent, CashFlowOverviewComponent, CashFlowContainerComponent, CashregisterComponent, CashregisterDetailComponent],
   providers: [],
})
export class CashFlowModule{ }