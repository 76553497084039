<!-- Login -->
<div class="login-box-body login-card-body bg-secondary">
    <div class="login-logo">
        <img src="{{softwareicon}}" alt="" class="brand-image" style="height: 80px;">
        <p>{{softwarename}}</p>
        
    </div>
    <div class="input-group mb-3">
        <input type="text" class="form-control"  placeholder="La tua Username" name="uname" [(ngModel)]="uname" (keyup.enter)="onLogin()">
        <div class="input-group-append">
            <div class="input-group-text">
            <span class="fas fa-user text-light"></span>
            </div>
        </div>
    </div>
    <div class="input-group mb-3">
        <input type="password" class="form-control"  placeholder="La tua Password" name="passwd" [(ngModel)]="passwd" (keyup.enter)="onLogin()">
        <div class="input-group-append">
            <div class="input-group-text">
            <span class="fas fa-key text-light"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
        <button class="btn btn-primary btn-block btn-flat" (click)="login()">Accedi</button>
        </div>
        <div class="col-3"></div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <input type="checkbox" name="remember_connected" [(ngModel)]="remember_connected"><small>&ensp;Ricordami connesso</small>
        </div>
    </div>
</div>

<div *ngIf="copyright" class="animation__shake fixed-bottom text-light text-xs text-center m-3"><b>C1</b> is a <a class="text-light" href="http://codingict.it" target="_blank"><b>Coding</b></a> software</div>
