import { Component, OnInit,Input } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, NotificationLog, OrderingMode } from 'projects/core/src/include/structures';
import { NotificationLogsService } from '../../services/notificationlogs.service';


@Component({
  selector: 'app-notificationlogs',
  templateUrl: './notificationlogs.component.html',
  styleUrls: ['./notificationlogs.component.sass']
})
export class NotificationlogsComponent extends ModelList<NotificationLog> implements OnInit {

  @Input()
  id_address:number;

  

  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;

  filter_search:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_date:Filter=new Filter();  


  isFilterSet:boolean=false;
  selectedNotify:NotificationLog={} as NotificationLog;

  body:string;
  email_from:string;
  email_subject:string;
  contact:string;

  





  constructor( private fb1: FormBuilder,
    private notificationLogsService: NotificationLogsService) {
      super(notificationLogsService,fb1);
     }

  ngOnInit(): void {
    this.title= 'Notifiche inviate';
    super.ngOnInit.apply(this, arguments); 

    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_address);


    this.afterGetItems=()=>{
      for(let i=0;i<this.list.length;i++){
        try{
          let message=JSON.parse(this.list[i].message);
          switch(this.list[i].gateway){
            case "email":
              this.list[i].preview="<strong>"+message['email_subject']+"</strong><br/><small>"+(message['email_body']!=null?message['email_body'].replace(/<[^>]*>/g, '').substring(0,50):"")+" ...</small>";
              break;
            case "whatsapp":
              this.list[i].preview="<small>"+message['body'].substring(0,50)+" ...</small>";
              break;
            case "sms":
              this.list[i].preview="<small>"+message['body'].substring(0,50)+" ...</small>";
              break;
                
          }
        }catch(e){
          
        }
      }
    }

  }

  ngOnChanges():void{
    if(this.id_address>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }


  setFilters():void{

    this.date_from.setDate(1);
    this.date_from.setMonth(1);
    this.date_from.setFullYear(1900);


    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("n.message");
    this.filter_search.value="";
    this.filter.push(this.filter_search);
    

    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("n.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);

    
    this.date_to.setDate(this.today.getDate()+1);
    this.date_to.setMonth(this.today.getMonth());
    this.date_to.setFullYear(this.today.getFullYear());

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("n.date");
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to);   
    this.filter.push(this.filter_date);

    this.ordering.mode=OrderingMode.discendent;
    this.ordering.field="n.date";
    
  }

  updateList(id_address:number):void{
    if(!this.isFilterSet)
      this.setFilters();
      
    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }

    this.getItems();
  }


  setNotify(record:NotificationLog){
    
    this.selectedNotify=Object.assign({}, record);
    this.contact=  this.selectedNotify.contact;
    let message=JSON.parse(this.selectedNotify.message);
    switch(this.selectedNotify.gateway){
      case "email":
        this.email_from=  message['email_from'];
        this.email_subject=  message['email_subject'];
        this.body=  message['email_body'];
        
      break;
      case "whatsapp":
        this.body= message['body'];;
        break;
      case "sms":
        this.body= message['body'];;
        break;
          
    }
    
    
    
      
      
   

  }

}
