<div class="card-header bg-info">{{steptitle}}</div>
<div>
    <div class="card" *ngIf="documentSelected && documentSelected.id>0">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4"><label>Documento</label></div>
                <div class="col-lg-8"><label>
                    <div *ngIf="documentSelected.typedocument==1" data-toggle="tooltip" title="Fattura Semplice" class="badge badge-info bg-purple">Fattura Semplice</div>
                    <div *ngIf="documentSelected.typedocument==2" data-toggle="tooltip" title="Fattura Accompagnatoria" class="badge badge-info bg-teal" >Fattura Accompagnatoria</div>
                    <div *ngIf="documentSelected.typedocument==3" data-toggle="tooltip" title="Nota di Credito" class="badge badge-info bg-pink">Nota di Credito</div>
                    <div *ngIf="documentSelected.typedocument==4" data-toggle="tooltip" title="Preventivo" class="badge badge-warning">Preventivo</div>
                    <div *ngIf="documentSelected.typedocument==5" data-toggle="tooltip" title="Documento di trasporto" class="badge badge-secondary">DDT</div>
                    <div *ngIf="documentSelected.typedocument==6" data-toggle="tooltip" title="Scontrino" class="badge badge-secondary">Scontrino</div>
                    <div *ngIf="documentSelected.typedocument==7" data-toggle="tooltip" title="Ordine cliente" class="badge badge-secondary">Ordine Cliente</div>
                    <div *ngIf="documentSelected.typedocument==8" data-toggle="tooltip" title="Ordine fornitore" class="badge badge-secondary">Ordine Fornitore</div>
                    {{documentSelected.reference}} del {{documentSelected.date | date:'dd-MM-yyyy'}}</label></div>
            </div>
            <div class="row">
                <div class="col-lg-4"><label>Fornitore</label></div>
                <div class="col-lg-4"><label>{{documentSelected.addressItem.name}}</label></div>
                <div class="col-lg-4 text-lg-right"><button class="btn btn-secondary" (click)="importFromDocument()">Importa lista prodotti da documento</button></div>
            </div>
        </div>
    </div>
    <div class="form-group row " *ngIf="step==0">
        <div class="col-12">
            <small>
                <app-goods [descriptionReadonly]="true" [viewmode]="2" [type]="type" [showNotes]="false" [list]="list"></app-goods>
            </small>
        </div>
    </div>
    <div class="form-group row " *ngIf="step==1">
        <div class="col-12" >
            <h5>Genera il documento di carico</h5>
            
            <div class="form-group row">
                <div class="col-lg-2"><label>Tipo documento</label></div>
                <div class="col-lg-10">
                    <select class="form-control form-control-sm" name="typedocument" [(ngModel)]="documentSelected.typedocument">
                        <option value="1">Fattura semplice</option>
                        <option value="2">Fattura accompagnatoria</option>
                        <option value="5">DDT</option>
                        <option value="8">Ordine fornitore</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-lg-2"><label>Fornitore</label></div>
                <div class="col-lg-10">
                    <div class="small-box alert-info">
                        <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)"
                            [showSearchButton]="true"
                            [recordSelected]="documentSelected.addressItem"
                            [service]="addressesService" (OnAdd)="addAddress()"
                            [fieldRender]="'name'"
                            [class]="'form-control form-control-sm'" ></app-selectfieldcustom>
                        <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                            <small>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <small>
                                            <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                <b>INDIRIZZO:</b><br>
                                                {{record.addressItem.address}}<br>
                                                {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                            </ng-container>
                                            <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                            <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                        </small>
                                    </div>
                                    <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                        <small>
                                            <b>CONTATTI:</b><br>
                                            <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                        </small>
                                    </div>
                                </div>
                            </small>
                            <div class="icon"><i class="fa fa-user"></i></div>
                        </div>
                    </div>
                    <!-- <div class="alert alert-light" *ngIf="documentSelected.addressItem">
                        <small><i class="fa fa-user"></i>&nbsp;{{documentSelected.addressItem.address}}, {{documentSelected.addressItem.city}}, 
                        <span *ngIf="documentSelected.addressItem.vat_number">P.IVA {{documentSelected.addressItem.vat_number}}</span></small>
                    </div> -->
                </div>
            </div>
            <div class="form-group row">
                <div class="col-lg-2"><label>Data</label></div>
                <div class="col-lg-10">
                    <input type="date" class="form-control form-control-sm" name="date" [(ngModel)]="documentSelected.date">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-lg-2"><label>Numero</label></div>
                <div class="col-lg-10">
                    <input type="text" class="form-control form-control-sm" name="reference" [(ngModel)]="documentSelected.reference">
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="documentSelected && documentSelected.id>0">
            <div class="alert alert-danger" *ngIf="(isMinusQuantity || isPlusQuantity) && saveDocumentLinked">
                Il documento verrà impostato su "Parziale" in quanto:
                <ul>
                    <li *ngIf="isMinusQuantity">mancano alcuni prodotti</li>
                    <li *ngIf="isPlusQuantity">alcuni prodotti non sono presenti nel documento</li>
                </ul>
            </div>
            <div class="alert alert-success" *ngIf="!isMinusQuantity && !isPlusQuantity">
                L'ordine fornitore verrà impostato su "Concluso".
            </div>
        </div>
        <div class="col-12 text-center mt-5">
            <button class="btn btn-success" (click)="confirm()">Conferma operazioni</button>
        </div>
    </div>
</div>

<div class="card-footer">
    <div class="row">
        <div class="col-6">
            <button *ngIf="showPrevious" class="btn btn-primary" (click)="goPrevious()"><i class="fa fa-chevron-left"></i>&nbsp;Indietro <span class="d-none d-sm-inline-block">(F2)</span></button>
        </div>
        <div class="col-6 text-right">
            <button  *ngIf="showNext" class="btn btn-primary" (click)="goNext()">{{NextTitleBtn}} <span class="d-none d-sm-inline-block">(F4)</span>&nbsp;<i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 text-right">
            <button class="btn btn-sm btn-secondary" (click)="closeWindow();">Chiudi</button>
        </div>
    </div>
</div>
