<app-windowlist [model]="this">
    <div class="row my-1">
        <div class="col-lg-12" >
            <div class="card card-outline">
                <div class="p-0">
                    <div *ngIf="list==null" class="m-4">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>
                    <form [formGroup]="form" *ngIf="list!=null">
                        <table class="table table-nowrap table-sm table-striped table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th></th>
                                <th>
                                    <!-- <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" /> -->
                                    Data
                                </th>
                                <th>Descrizione</th>
                                <th class="text-center">Qt</th>
                                <th>Magazzino</th>
                                <th>Documento</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of list">
                                    <td>
                                        <span *ngIf="r.type==1" title="Carico" class="badge badge-success"><i class="fa fa-upload"></i></span>
                                        <span *ngIf="r.type==2" title="Scarico" class="badge badge-danger"><i class="fa fa-download"></i></span>
                                    </td>
                                    <td>
                                        {{r.date | date:'dd/M/yyyy'}}
                                    </td>
                                    <td>
                                        {{r.description }}<span *ngIf="r.sn">&nbsp;<div class="badge badge-info">{{r.sn}}</div></span>
                                        &nbsp;<i class="fa fa-pencil-alt" title="Visualizza" (click)="openProduct(r.id_product)" style="cursor:pointer;"></i>
                                        <ng-container *ngIf="id_product==0">
                                            &nbsp;<i class="fa fa-boxes" title="Visualizza" (click)="openHistory(r.id_product)" style="cursor:pointer;"></i>
                                        </ng-container>
                                        <br/><small>{{r.note}}</small>
                                    </td>
                                    <td class="text-center">{{r.quantity}} {{r.unit}}</td>
                                    <td>
                                        {{r.inventoryname}}<br/>
                                        <small>{{r.username}}</small>
                                    </td>
                                    <td><small>
                                        <app-reference [reference]="r.reference" [table]="r.table"></app-reference></small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>