import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { Access } from 'projects/core/src/access';
import { AuthGuard } from 'projects/core/src/authGuard';
import { Config } from 'projects/core/src/config';
import { Globals } from 'projects/core/src/globals';
import { Message } from 'projects/core/src/message';
import { ModalWindow } from 'projects/core/src/modal';
import { NavigationHelper } from 'projects/core/src/navigation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxLoadingModule } from 'ngx-loading';
import { InitModule } from './modules/init/init.module';
import { PointModule } from '../../../c1-backend/src/app/modules/point/point.module';
import { C1Connector } from 'projects/core/src/c1connector';
import { AlarmModule } from './modules/alarm/alarm.module';
import { AddressModule } from './views/address/address.module';

import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StripeModule } from 'projects/core/src/common/stripe/stripe.module';
import { ChatModule } from './modules/chat/chat.module';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
    
    
   ],
  imports: [
    PointModule,
    InitModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxLoadingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AlarmModule,
    ToastrModule.forRoot(),
    AddressModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StripeModule,
    ChatModule
  ],
  providers: [AuthGuard,Globals,Config,Access,NavigationHelper,Message,ModalWindow,C1Connector,{ provide: LOCALE_ID, useValue: "it-IT" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
