import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { Helper } from '../helper';
import { WSService } from '../include/service'
import { Action } from '../include/structures';


@Injectable({
  providedIn: 'root'
})
export class ActionsService extends WSService<Action> {

  constructor() { super("actions")};

  getRule(table:string,record:any,oncomplete:Function){

    

    //verifica se ci sono delle regole per le scadenze da imporre
    Globals.ws.requestUrl<Action[]>("actions","getItems",["a.table='"+table+"'"]).subscribe((items)=>{
      //verifica  le condizioni
      for(let i=0;i<items.length;i++){
        items[i].conditionsArray=JSON.parse(items[i].conditions);
        let verify:boolean=true;
        for(let j=0;j<items[i].conditionsArray.length;j++){
          let s=Helper.getFieldbyString(items[i].conditionsArray[j].field,record);

          switch(items[i].conditionsArray[j].condition){
            case "ugual":
              if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)!=items[i].conditionsArray[j].value && verify)
                verify=false;
                
              break;

            case "major":
              if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)<=items[i].conditionsArray[j].value && verify)
                verify=false;
                
              break;

              case "minor":
                if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)>=items[i].conditionsArray[j].value && verify)
                  verify=false;
                  
                break;
          }

          
          //if(Globals.app.replaceKeyword(record[items[i].conditionsArray[j].field],record)!=items[i].conditionsArray[j].value && verify==true)
            //verify=false;
        }

        if(verify){
          
         //fai l'azione
         let action=JSON.parse(items[i].action);

         let v:Object={} as Object;
         let where:string[]=[];
         for(let f of action.fields){
          v[f.name]=Helper.replaceKeyword(f.value,record);
          where.push(f.name+"='"+v[f.name]+"'");
         }

         v['id_address']=Helper.searchKey("id_address",record);
       
         switch (action.action){
           case "UPDATE":
           case "INSERT":
             //verifica se la voce è già stata inserita
             Globals.ws.requestUrl(action.table,"getItem",[where.join(" AND ")]).subscribe(((item)=>{
               if(item==null)
                if(confirm("Salvare i valori anche nella tabella "+action.table+" ?"))
                  Globals.ws.send(action.table,"save",v,[],(return_id)=>{
                    console.log(return_id);
                  },"Valori aggiunti nella tabella "+action.table);
             }))
             
             break;
          
         }
         
         


          
        }
        if(oncomplete) oncomplete(true);
        continue;
      }

      if(oncomplete) oncomplete(false);

    });
  }
}
