import {Component,Input} from '@angular/core';
import { WSService } from './service'
import { ActivatedRoute } from '@angular/router';
import { Filter,Template, ToastMode } from './structures'
import { Extension } from './parameters';
import { ModelList } from './modellist';


import {Globals} from '../globals'
import { Helper } from '../helper';
import * as clone from 'clone';
import { DeadlinerulesService } from 'projects/c1-backend/src/app/services/deadlinerules.service';
import { ActionsService } from '../lib/actions.service';
import {SelecttemplateComponent} from '../common/selecttemplate/selecttemplate.component'
import {TemplatesService} from '../lib/templates.service';

import * as math from 'mathjs';

@Component({
  template:''
})


export class Model<T>  {
    
    /* VARIABLES */
    id:number=0; // current record id
    record:T={} as T; // current record
    
    get title():string{
      return Globals.title;
    }
    set title(value){
      Globals.title = value;
     // Globals.navigation.addHistory(this.title,this.id==0?true:false);
     
    };
    
    //title:string=""; //title of window
    today = new Date(); //current date
    modulename:string=""; //name of current module
    module:Extension; //parameters of window
    mode:string=""; //current modality of window (normal, embedded, etc)

    modalWindow:any; // finestra corrente in modalità modal


    field_required:string[]=[]; 
    record_tmp:T;
    isOffline=false;
    templates:Template[]=[]

    @Input()
    parent:ModelList<T>;

    /* EVENTS */
    public beforeSave:Function;
    public afterSave:Function;
    public afterGetItem:Function;

    
    public afterSelectionRecord:Function;

    constructor(
        private route: ActivatedRoute,
        private recordService: WSService<T>,
        
        
       
    ){
      Globals.currentModel=this;
    }

    ngOnInit(): void {

      if(this.modulename!="")
        this.module=Globals.parameters.get(this.modulename);

      this.getTemplates();
      
    }

    public loadRecord(id){
      this.id = id;
      this.getItem();
    }

    ngAfterViewInit(){
      /*setTimeout(()=>{
        AppComponent.app.title = this.title;
        this.route.params.subscribe((params) => {
          AppComponent.app.addHistory(this.title+(this.id>0?" ("+this.id+")":""),params.id==0?true:false);
      });
      },100);*/
      
    }
    getItem():void{
      Globals.setLoading(true);
        if(this.id>0){
          this.recordService.getItem(this.id).subscribe((item)=>{
            Globals.setLoading(false);
            
           
            

            Object.assign(this.record,item);

            this.updateTmpRecord();
            Globals.lastDetailRecord=this.record;
            Globals.lastDetailRecord_id=this.record['id'];
            Globals.lastDetailRecord_table=this.recordService.table;
            
            if(this.afterGetItem)
              this.afterGetItem();
          });
        }else{
          Globals.setLoading(false);
          if(this.afterGetItem)
            this.afterGetItem();
        }
      }

     

      getOfflineItem():void{
        Globals.setLoading(true);
        if(this.id>0){
          this.record=this.recordService.getOfflineItem(this.id)
          Globals.setLoading(false);
          this.updateTmpRecord();
          Globals.lastDetailRecord=this.record;
          Globals.lastDetailRecord_id=this.record['id'];
          Globals.lastDetailRecord_table=this.recordService.table;
          if(this.afterGetItem)
            this.afterGetItem();
        
        }else{
          Globals.setLoading(false);
          if(this.afterGetItem)
            this.afterGetItem();
        }
      }


    updateTmpRecord(){
      //copia il record in modo da poi verificare se c'è stato una modifica o meno
      this.record_tmp=clone<T>(this.record);
    }

    
    save(reloadItem:boolean=true,oncomplete:Function=null):void{

      if(this.beforeSave){
         if(!this.beforeSave())
          return;
      }


      if(this.isOffline){
        this.updateTmpRecord();
        this.saveOffline(reloadItem,oncomplete);
        
        if(this.afterSave)
            this.afterSave();
        return;
      }



      let isInsert:boolean=true;
      if(this.record['id']>0)
        isInsert=false;

      this.recordService.save(this.record,(id)=>{
        this.id=id;
        this.record['id']=this.id;
        this.updateTmpRecord();
        if(reloadItem){
          if(isInsert){
            //cancella l'ultimo history
            Globals.navigation.deleteLastHistory();
            //ricarica la pagina
            let urlSeg=this.route.snapshot.url;
            let countParams=Object.keys(this.route.snapshot.params).length;
              
            urlSeg=urlSeg.splice(0,urlSeg.length-countParams);
            Globals.navigation.onNavigate(["/"+urlSeg.join("/"),this.id]);

            
          }
        }
        
        let deadlinerulesService:DeadlinerulesService=new DeadlinerulesService();
        deadlinerulesService.getRule(this.recordService.table,this.record,()=>{
          let actionsService:ActionsService=new ActionsService();
          actionsService.getRule(this.recordService.table,this.record,()=>{
            let resultAf=true;
            if(this.afterSave){
              
              let r=this.afterSave(this.id);
              resultAf=r==null?true:r;
            }
                

            if(oncomplete && resultAf)
              oncomplete();
          });
        
          
        });
        
        /*
       if(this.afterSave)
        this.afterSave();
        
        if(oncomplete)
          oncomplete();

        if(this.parent!=null)
          this.parent.getItems();
        */ 
        
      });
  
    }

    saveOffline(reloadItem:boolean=true,oncomplete:Function=null):void{
      //aggiorna i record
      let items=JSON.parse(localStorage.getItem(this.recordService.table+"Items"));
      for(let i=0;i<items.length;i++){
        if(items[i]['id']==this.record['id']){
          items[i]=this.record;
          items[i]['lastupdate']=Helper.convertDateControl(new Date(),true);
        }
      }
      this.updateTmpRecord();

      localStorage.setItem(this.recordService.table+"Items",JSON.stringify(items));
      if(oncomplete)
        oncomplete();
    }

    saveAndNew():void{
      this.save(false,()=>{
        this.record={} as T;
      });

    }

    saveAndClose():void{
      this.save(false,()=>{
        this.onBack("success");
      });
    }

    verifyUnsavedRecord(){
      /*if(!this.disable()){
        const jsondiffpatch=require("jsondiffpatch");
        //verifica se il record è stato modificato
        try{
          let result=jsondiffpatch.diff(this.record,this.record_tmp);
          if(result!=undefined){
            if(confirm("Salvare le ultime modifiche?")){
              this.saveAndClose();
              return true;
            }
          }
        }catch{
          return false;
        }
      }*/

      return false;

    } 
    

    onBack(returnModal="cancel"):void{

      if(!this.verifyUnsavedRecord()){
        if(this.parent!=null){
          this.parent.disableEditing();
          this.loadRecord(this.parent.selectedRecord['id']);
          }
        else{
          if(this.mode=="modal"){
            this.modalWindow.close(returnModal);
          }else{
            Globals.navigation.onBackLink(this.route);
          }
            
        }
          
      }
        
      
    }

    disable(){
      for(let i=0;i<this.field_required.length;i++){
        if(this.record[this.field_required[i]]==undefined || this.record[this.field_required[i]]=='' || this.record[this.field_required[i]]==0){
          
          return true;
        }
          
          
      }
      return false;
    }



    /// Trova la posizione del valore id nell'array degli IDS ricercati
    _getIdxFromIds(id:number):number{
        let pos=Globals.lastSearchIDS.indexOf(id);
        return pos;
        
    }
  
    /// Ricarica il componente con il  record successivo
    goToNext():void{
        let pos:number=this._getIdxFromIds(this.record['id']);
        //prendi la posizione corrente dell'id del record
        if((pos+1)<Globals.lastSearchIDS.length-2){
          this.id=Globals.lastSearchIDS[pos+1];
          this.getItem();
        }
          
    }
    /// Ricarica il componente con il  record precedente
    goToPrevious():void{
      let pos:number=this._getIdxFromIds(this.record['id']);
      if((pos-1)>-1){
        this.id=Globals.lastSearchIDS[pos-1];
        this.getItem();
      }
      
    }
    /// Verifica se è presente un record precedente
    isNext():boolean{
      let pos:number=this._getIdxFromIds(this.record['id']);
      if((pos+1)<Globals.lastSearchIDS.length-2)
        return true;
      else
        return false;
    }

    /// Verifica se è presente un record successivo
    isPrevious():boolean{
      let pos:number=this._getIdxFromIds(this.record['id']);
      if((pos-1)>-1)
        return true;
      else
        return false;
    }

    

    /*
    ///Apre un componente nella modalità modale
    openModal(comp:string,title:string,id_key:string,tableModal:string,field_key:string="",recordSelectedField:string=""){
      
      //prendi il componente
      let component:Modal=AppComponent.app.getComponentByName(comp);
      AppComponent.app.titleModal=title;
      AppComponent.app.tableModal=tableModal;
      AppComponent.app.selectedRecordFromModal={} as Function;
      AppComponent.app.selectedRecordFromModal=((r)=>{
        this.record[id_key]=r.id;
        
        let arrayFieldKey:string[]=[];
        if(field_key!="")
          
          arrayFieldKey=field_key.split(".");
          if(arrayFieldKey.length>1){
            if(recordSelectedField!="")
              this.record[arrayFieldKey[0]][arrayFieldKey[1]]=r[recordSelectedField];
            else
              this.record[arrayFieldKey[0]][arrayFieldKey[1]]=r;
          }else{
            if(recordSelectedField!="")
              this.record[arrayFieldKey[0]]=r[recordSelectedField];
            else
              this.record[arrayFieldKey[0]]=r;
          }
          if(this.afterSelectionRecord)
            this.afterSelectionRecord(r,AppComponent.app.tableModal);
      });

      AppComponent.app.openModal(component);
      


    }
    */
    verifyDate(d):boolean{
      if(Date.parse(d)>this.today.getTime())
        return true;
      else
      return false;
    }

    //esporta lista CSV
    /*export():void{



      Globals.modal.showModal(SelecttemplateComponent,[{"name":"table","value":this.recordService.table},{"name":"detail","value":true},{"name":"arguments","value":[]}],(instance)=>{

        
        
          let template=instance['selectedTemplate'] as Template;
          let templatesService:TemplatesService=new TemplatesService();

          if(template.id<1){
            return;
          }



          let m={};

          
          m['where']=this.record['id'];
          
          let a:Filter;
          for(let p of Object.getOwnPropertyNames(this)){
            if(typeof(this[p])=="string" || typeof(this[p])=="number" || typeof(this[p])=="boolean" || p.includes("filter_"))
              m[p]=this[p];
          }

          Globals.setLoading(true);
          templatesService.openTemplate(template.id,JSON.stringify(m)).subscribe((url)=>{
            Globals.setLoading(false);
            window.open(url,"_blank");
          });
       },"md");




    }*/

    getTemplates(){
      let templatesService:TemplatesService=new TemplatesService();
      templatesService.getTemplates(this.recordService.table,true,true).subscribe((templates)=>{
        this.templates=templates;
      });
    }


    export():void{
      let templatesService:TemplatesService=new TemplatesService();
      //trova i template disponibili
      let results=[];
      //verifica se i templates rispettano i  vincoli
      for(let t of this.templates){
        if(t.condition!=""){
          let c=Helper.replaceKeyword(t.condition,this.record);
          if(math.evaluate(c)){
            results.push(t);
          }
        }else{
          results.push(t);
        }
        
      }
      
      if(results.length>0){
        let m={};
        m['where']=this.record['id'];
        if(results.length>1){
          Globals.modal.showModal(SelecttemplateComponent,[{"name":"table","value":this.recordService.table},{"name":"templates","value":results}],(instance)=>{
            let template=instance['selectedTemplate'] as Template;
            Globals.setLoading(true);
            templatesService.openTemplate(template.id,JSON.stringify(m)).subscribe((url)=>{
              Globals.setLoading(false);
              window.open(url,"_blank");
            });
          },"md");
        }else{
          
          Globals.setLoading(true);
          templatesService.openTemplate(results[0].id,JSON.stringify(m)).subscribe((url)=>{
            Globals.setLoading(false);
            window.open(url,"_blank");
          });
        }
      }else{
        Globals.message.showToaster("Nessun template di stampa associato per il record selezionato",ToastMode.DANGER);
      }


     


    }
  
    onNavigate(link){
      Globals.navigation.onNavigate(link);
    }


    isModuleEnabled(modulename){
      
      return Globals.parameters.get(modulename)?true:false;

    }

    
    
}