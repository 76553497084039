<div class="input-group input-group-sm" *ngIf="!showOnlyTree">
    <input type="text" readonly class="form-control bg-white" style="cursor: pointer" (click)="open();" title="Seleziona conto contabile" name="financialaccount" [(ngModel)]="financialaccount">
    <div class="input-group-append" (click)="open();" style="cursor: pointer" data-toggle="tooltip" title="Seleziona conto contabile">
        <span class="input-group-text bg-white"><i class="fas fa-coins"></i></span>
    </div>
</div>

<ng-template #modalTree>
    <div class="modal-header bg-light">
        <h5 class="mb-0"><i class="fas fa-coins text-gray mr-2"></i>Seleziona il conto contabile</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="close()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <small>
        <tree-root [nodes]="financialaccounts" (activate)="selectNode($event)" [options]="options"></tree-root></small>
    </div>
   <div class="modal-footer">
        <button class="btn btn-primary" (click)="close()">Conferma</button>
    </div>
</ng-template>