import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
//import {Modal} from '../../include/modal'
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})

export class ModalComponent implements OnInit {

  @Input() public title = "Information";

  //@Input() public child:Modal;

  @Input() public class:string="";

  @Input() public showConfirmButton=true;

  @Input() public showHeader:boolean=true;

  @Input() public showFooter:boolean=true;
  

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
   
  
  
  }

  close(reason="cancel"){
    this.activeModal.dismiss(reason);
  }

  

}
