<div role="document ">
    <div class="modal-content {{class}}">
      <div class="modal-header py-1" *ngIf="showHeader">
        <h4 class="modal-title">{{title}}</h4>
        <a type="button" class="close" aria-label="Close" (click)="close()" >
          <h4  aria-hidden="true"><b>&times;</b></h4>
        </a>
      </div>
      <div class="modal-body">
        <ng-content #content></ng-content>
      </div>
      <div class="modal-footer" *ngIf="showFooter">
        <button type="button" class="btn btn-outline-dark" (click)="close('cancel')">Annulla</button>
        <button *ngIf="showConfirmButton" type="button" class="btn btn-outline-success" (click)="close('success')">Conferma</button>
      </div>
    </div>
  </div>