import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { Installation } from '../../../../db/installation';
import { InstallationsService } from '../../../../services/installations.service';
import { SerialsDetailsComponent } from '../serials-details/serials-details.component';
import { SerialsComponent } from '../serials.component';

@Component({
  selector: 'app-serialshop',
  templateUrl: './serialshop.component.html',
  styleUrls: ['./serialshop.component.css']
})
export class SerialshopComponent extends ModelList<Installation> implements OnInit  {

  @Input()
  id_shop:number=0;

  @Input()
  id_address:number=0;

  showNotAssigned=false;

  constructor(
    private fb1: FormBuilder,
    private installationsService: InstallationsService,
    
  ) {
    super(installationsService,fb1);
    this.detailViewModal=SerialsDetailsComponent;
   }

  ngOnInit(): void {
   

  }

  ngOnChanges():void{
    if(this.id_shop>0 || this.id_address>0)
      this.updateList();
  }

  updateList(){

    this.installationsService.getInstallationByShop(this.id_shop,this.id_address).subscribe((items)=>{
      this.list=items;
    })
  }

  getItems(){
    this.updateList();
  }


  addInstallation(){
    Globals.modal.showModal(SerialsDetailsComponent,[{"name":"mode","value":"modal"},{"name":"id_shop","value":this.id_shop}],(instance)=>{
      if(instance!=null){
        this.updateList();
      }
    })
  }


  isShow(record){
    if(this.showNotAssigned){
      return true
    }else{
      if(this.id_shop==record.current_id_shop || this.id_address==record.current_id_address){
        return true;
      }
    }
    return false;
  }

}
