import { Injectable } from '@angular/core';
import {CashFlow,CashFlowTotal} from '../db/cashflow';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { FilterAdvanced,Filter,Ordering } from 'projects/core/src/include/structures';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})

export class CashflowService extends WSService<CashFlow> {

  constructor() {super("cashflow") }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<CashFlowTotal>{
    return Globals.ws.requestUrl("cashflow","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }

  getOverview(date,created_from):Observable<any>{
    return Globals.ws.requestUrl("cashflow","getOverview",[date,created_from]);

  }



}

