<section class="fixed-bottom bg-light p-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
        
            <button class="btn btn-secondary float-right m-1" (click)="onBack()"  data-toggle="tooltip" title="Annulla" ><i class="fas fa-times"></i><span class="d-none d-sm-inline-block ml-2">Annulla (ESC)</span></button>
            <button *ngIf="showSave && showSaveAndNew"  data-toggle="tooltip" title="Salva e Nuovo" class="btn btn-success float-right m-1 d-none d-sm-inline-block" [disabled]="disable" (click)="saveAndNew();"><i class="fas fa-plus-circle"></i><span class="d-none d-sm-inline-block ml-2">Salva & Nuovo</span></button>
            <button *ngIf="showSave && showSaveAndClose" data-toggle="tooltip" title="Salva e Chiudi" class="btn btn-success float-right m-1 d-none d-sm-inline-block" [disabled]="disable"  (click)="saveAndClose();"><i class="fas fa-arrow-alt-circle-left"></i><span class="d-none d-sm-inline-block ml-2">Salva & Chiudi</span></button>
            <button *ngIf="showSave" data-toggle="tooltip" title="Salva" class="btn btn-success float-right m-1" [disabled]="disable" (click)="save();" ><i class="fas fa-save"></i><span class="d-none d-sm-inline-block ml-2">Salva (F8)</span></button>
            <button *ngIf="showSave && showSaveAndPrint" data-toggle="tooltip" title="Salva e stampa" class="btn btn-success float-right m-1" [disabled]="disable" (click)="saveAndPrint();" ><i class="fas fa-print"></i><span class="d-none d-sm-inline-block ml-2">Salva e Stampa</span></button>
            
            <span class="btn disabled float-left m-1 d-none d-sm-inline-block">ID: <b>{{id}}</b></span>
        </div>
      </div>
    </div>
</section>