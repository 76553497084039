<app-modal #modal [showConfirmButton]="false" title="Seleziona il metodo di pagamento">
    <div class="row">
        <div class="col-6">
            <h3 class="text-primary">Da pagare</h3>
        </div>
        <div class="col-6 text-right">
            <h2><strong class="text-primary">{{total | currency:" &euro;"}}</strong></h2>
        </div>
        <div class="col-6">
            <h3 class="text-success">Pagato</h3>
        </div>
        <div class="col-6 text-right">
            <h2><strong class="text-success">{{total_paid | currency:" &euro;"}}</strong></h2>
           
        </div>
        <div class="col-12" *ngIf="listMethods.length > 0">
            <table class="table table-sm border-bottom">
                <thead class=" alert-success">
                    <th class="text-left"><small>Metodo</small></th>
                    <th class="text-right"><small>Importo versato</small></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let m of listMethods">
                        <td class="text-left"><b>{{m.methodpayment}}</b></td>
                        <td class="text-right text-success"><b>{{m.amount | currency:" &euro;"}}</b></td>
                        <td><button class="btn btn-danger btn-xs float-right" (click)="removePayment(m)"><i class="fa fa-trash"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-6">
            <h3 class="text-danger">Rimanenza</h3>
        </div>
        <div class="col-6 text-right">
            <h2><strong class="text-danger">{{total_remain | currency:" &euro;"}}</strong></h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="total_remain>0">
            <div class="card card-light">
                <div class="card-header bg-success text-center p-1">
                    <h5 class="mb-0">Versato</h5>
                </div>
                <div class="card-body alert-success">
                    <div class="row">
                        <div class="col-12">
                            <input type="number" autofocus #paidInput (select)="$event.target.select()" (focus)="$event.target.select()" class="form-control text-xl text-center" name="paid" [(ngModel)]="paid"/>
                        </div>
                        <app-keyboard [showFunctions]="false" (onDigit)="paid=$event" ></app-keyboard>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6">
                            <a class="btn text-lg btn-success btn-block" (click)="selectMethod('Contanti');">Contanti (F2)</a>
                        </div>
                        <div class="col-6">
                            <a class="btn text-lg btn-success btn-block" (click)="selectMethod('POS');">POS (F4)</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2" *ngIf="total_remain<=0">
            <button class="btn btn-primary btn-lg btn-block text-xl" (click)="confirm()" >Conferma (F8)</button>
        </div>
    </div>
</app-modal>


