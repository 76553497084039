<app-windowlist [model]="this">
    <div class="row selectPeriodCalendar">
        <div class="col-lg-12">
          <div class="p-2">
            <div class="row">
                <div class="col-4 btn-previous pr-0 text-right">
                    <div class="btn btn-sm btn-secondary" mwlCalendarPreviousView [view]="view"  (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="text-light fas fa-angle-left"></i></div>
                    <div class="btn btn-sm btn-outline-secondary float-left" mwlCalendarToday [(viewDate)]="viewDate">Oggi</div>
                </div>
                <div class="col-4 pt-1 date text-center bg-light">
                    <b>{{ viewDate | calendarDate:(view + 'ViewTitle'):'It'}}</b>
                </div>
                <div class="col-4 btn-next pl-0 text-left">
                    <div class="btn btn-sm btn-secondary" mwlCalendarNextView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="text-light fas fa-angle-right"></i></div>
                </div>
                <div class="col-12 mt-2 calendarView">
                    <mwl-calendar-month-view-header ></mwl-calendar-month-view-header>
                    <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [events]="events"
                    [locale]="locale"
                    [cellTemplate]="customCellTemplate"
                    (dayClicked)="dayClicked($event.day)"  >
                    </mwl-calendar-month-view>
                </div>
            </div>
          </div>
        </div>
    </div>
  
    <ng-template  #customCellTemplate let-day="day" let-locale="locale">
            <span class="bg-gray px-1 text-center text-lg">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            <ng-container *ngIf="day.events.length>0">
                <div class="p-1 border-bottom" *ngFor="let e of day.events" title="Visualizza dettagli">
                    <div class="row">
                        <div [class.col-lg-7]="e.meta.total!=0" [class.col-12]="e.meta.total==0">
                            <small>{{e.meta.type}} <div class="badge badge-danger" *ngIf="e.meta.count>1">{{e.meta.count}}</div></small>
                        </div>
                        <div class="col-lg-5 pl-lg-0 text-right" *ngIf="e.meta.total!=0">
                            <small [class.text-danger]="e.meta.total<0">{{e.meta.total | currency:"&euro;"}}</small>
                        </div>
                    </div>
                </div>
            </ng-container>
    </ng-template>
</app-windowlist>