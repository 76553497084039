import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode } from 'projects/core/src/include/structures';
import { Deadlines } from '../../../db/deadlines';
import { DeadlinesService } from '../../../services/deadlines.service';
import { DeadlineDetailsComponent } from '../../deadlines/deadline-details/deadline-details.component';

@Component({
  selector: 'app-installments',
  templateUrl: './installments.component.html',
  styleUrls: ['./installments.component.css']
})
export class InstallmentsComponent extends ModelList<Deadlines> implements OnInit {

  @Input()
  id_contract:number=0;

  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  isFilterSet:boolean=false;


  constructor(
    private fb1: FormBuilder,
    private deadlinesService: DeadlinesService,
  ) { 
    super(deadlinesService,fb1);
    this.detailViewModal=DeadlineDetailsComponent;
  }

  ngOnInit(): void {

    this.title = 'Piano rate';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=25;
  }


  setFilters():void{
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("d.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("d.table");
    this.filter_table.value="contracts";
    this.filter.push(this.filter_table);
    
    this.filter_id_table.mode=FilterMode.normal;
    this.filter_id_table.fields=[];
    this.filter_id_table.fields.push("d.id_table");
    this.filter_id_table.value=this.id_contract.toString();
    this.filter.push(this.filter_id_table);

    this.ordering.field="d.date";
    this.ordering.mode=OrderingMode.ascendent;
    this.isFilterSet=true;

  }

  ngOnChanges():void{
    if(this.id_contract>0  ) //avvia la ricerca quando il filtro è attivo
      this.updateList();
  }

  updateList():void{
      if((this.id_contract==0 ))
        return;
    
    if(!this.isFilterSet)
      this.setFilters();
    
    this.getItems();
  }


}
