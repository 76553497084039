<div class="modal-header">
    Criteri di ricera salvati
</div>

<div class="modal-body">
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let t of templates">
            <div class="row">
                <div class="col-10">
                    {{t.name}}
                </div>
                <div class="col-2 text-right">
                    <button class="btn btn-xs btn-secondary" (click)="load(t)"><i class="fa fa-search"></i></button>
                    <button class="btn btn-xs btn-danger" (click)="delete(t)"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </li>
    </ul>
    <button class="btn btn-sm btn-success" (click)="save();"><i class="fa fa-plus"></i>&nbsp;Salva criterio corrente di ricerca</button>
</div>

<div class="modal-footer">
    <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
</div>
