import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { Installation } from '../../../../db/installation';
import { Type } from '../../../../db/type';
import { ShopsService } from '../../../../services/shops.service';
import { InstallationsService } from '../../../../services/installations.service';
import { TypesService } from '../../../../services/types.service';
import { Product } from '../../../../db/product';
import { Globals } from 'projects/core/src/globals';
import { ProductsComponent } from '../../../products/products.component';
import { ShopsComponent } from '../../../shops/shops.component';
import { AddressComponent } from '../../../address/address.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoriesDetails } from '../../../../db/inventories';
import { Helper } from 'projects/core/src/helper';
import { InventoriesDetailsService } from '../../../../services/inventoriesdetails.service';
import { CustomField, ToastMode } from 'projects/core/src/include/structures';
import { DeadlineDetailsComponent } from '../../../deadlines/deadline-details/deadline-details.component';
import { InventoriesService } from 'projects/c1-backend/src/app/services/inventories.service';


@Component({
  selector: 'app-serials-details',
  templateUrl: './serials-details.component.html',
  styleUrls: ['./serials-details.component.sass']
})
export class SerialsDetailsComponent extends Model<Installation> implements OnInit {
  modulename="installations";

  
  @ViewChild("modalChangeLocation")
  modalChangeLocation:Type;
  modalChangeLocationRef;
  causalMove;

  @ViewChild("modalXrifInstallationShop")
  modalXrifInstallationShop:Type;
  modalXrifInstallationShopRef;
  selectedXrifInstallationShop;

  @Input()
  id_shop:number;

  type_installation:Type[];
  //productModels:Product[];

  field_required=['id_product','id_type','id_shop'];
  isNew=false;

  id_category_log=0;

  sn_exist=false;

  constructor(
    private route1: ActivatedRoute,
    private installationsService: InstallationsService,
    private typesService: TypesService,
    private shopsService: ShopsService,
    private modalService:NgbModal,
    private inventoriesDetailsService:InventoriesDetailsService,
    private inventoriesService:InventoriesService
    
    ) {
      super(route1,installationsService); 
      
    
  }

  

  ngOnInit(): void {
    
    this.title = 'Prodotto Dettaglio';
    super.ngOnInit.apply(this, arguments); 
    this.id_category_log=this.module.getParam("id_category_log",0);


    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{
        this.id = params.id
        if(params.id_shop)
          this.id_shop=params.id_shop;
      } );

    this.typesService.getTypes("installations").subscribe((items)=>{
      this.type_installation=items;

      
        this.getItem();
     

      
   });


   this.afterGetItem=(()=>{

    if(this.id==0 || this.id==undefined){
      this.isNew=true;
      this.record.id=0;
      if(this.id_shop>0){
        //cerca l'anagrafica corretta
        this.shopsService.getItem(this.id_shop).subscribe((item)=>{
          this.record.shop=item;
          this.record.id_shop=item.id;
        
        });
      }
      this.record.customfields=[];
      this.record.id_type=this.type_installation[0].id;
    }else{
      this.title=this.record.product.brand+" "+this.record.product.name+" "+this.record.sn;
    }

    //this.getBrands(true);
    
  });

  
  this.beforeSave=()=>{
    
    if(this.isNew){

      let id_inventory=this.record.shop.id_inventory?this.record.shop.id_inventory:Globals.parameters.get("inventories").getParam("default_inventory");
      //verifica se il prodotto è già presente in magazzino oppure no.
      this.inventoriesDetailsService.checkStock(this.record.sn,id_inventory).subscribe((result)=>{
        this.isNew=false;
        if(result.avaible>0){
          this.saveAndClose();

        }else{
              //prendi il nome del magazzino
          this.inventoriesService.getItem(id_inventory).subscribe((inventory)=>{
            if(confirm("Caricare il prodotto nel magazzino "+inventory.name+" ?")){
              this.record.id_shop=inventory['shop']['id'];
              this.save(false,()=>{
              

               


                let d:InventoriesDetails=new InventoriesDetails();
                d.id=0;
                d.sn=this.record.sn;
                d.id_product=this.record.id_product;
                d.quantity=1;
        
                let p:Product=new Product();
                Object.assign(p,this.record.product);
        
                d.netprice=p.getPrice(d.quantity,2);
                d.tax=p.tax;
                d.type=1; //carico
                d.unit=p.unit;
                d.description=p.brand+" "+p.name;
                d.gross=d.netprice*(1+d.tax/100);
                d.id_inventory=id_inventory;
                d.date=Helper.convertDateControl();
                let t=new Date();
                d.time=t.getHours().toString()+":"+t.getMinutes().toString();
                d.id_user=Globals.user.id;
                d.id_table=this.record.id;
                d.table="installations";
                d.id_address=this.record.shop.id_address;
                this.afterSave=null; //evita che venga richiamata quando si salva la voce di inventario
                this.inventoriesDetailsService.save(d,()=>{
                  if(this.record.shop.id_inventory!=id_inventory){
                    this.installationsService.moveInstallation(this.record.id,0,this.record.shop.id,"","Nuovo prodotto").subscribe((result_move)=>{
                      this.onBack("success");
                    });
                  }else{
                    this.onBack("success");
                  }
                });
              });
            }else{
              this.saveAndClose();
            }

        
          });

            
        }
      });

      return false;
      
    }

    return true;
  }
  

  }




  selectProduct(){
    Globals.modal.showModal(ProductsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        let r=instance['recordSelected'];
        this.record.brand=r.brand;
        this.record.model=r.name;
        this.record.id_product=r.id;   
        this.record.product=r; 
        setTimeout(()=>{
          this.setDeafultCustomFields(this.record.id_product);

        },500);
        

      } 
      
    });
  }

  selectShop(){
    let answer_move=this.module.getParam("answer_move",true);

    if(this.id==0 || !answer_move){
      this.causalMove="";
      this.setCausal();
    }else{
      this.modalChangeLocationRef=this.modalService.open(this.modalChangeLocation,{size:"md"});

    }
   
    
  }

  setCausal(){

    
    Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona la nuova ubicazione del prodotto"}],(instance)=>{
      let r=instance['recordSelected'];
      this.record.shop=r;
      const old_id=this.record.id_shop;
      this.record.id_shop=r.id;
      if(this.record.id>0){ //se è un nuovo record
        this.installationsService.moveInstallation(this.record.id,old_id,r.id,1,this.causalMove).subscribe(result=>{
          this.closeModalChangeLocation();
          this.save();
        });
      }

      

    });


    
  }

  closeModalChangeLocation(){
    this.modalChangeLocationRef.close("success");
  
  }

  selectReseller(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"id_type","value":3}],(instance)=>{
      let r=instance['recordSelected'];
      this.record.reseller=r;
      this.record.id_reseller=r.id;
      
    });
  }
  /*
  onSelectModel(){
    for(let i=0;i<this.productModels.length;i++)
      if(this.productModels[i].id==this.record.id_product){
        this.record.model=this.productModels[i].name;
        break;
      }
  }*/

  setDeafultCustomFields(id_product:number):void{
   
    /*
    let record:Product;
    //ricerca il modello dalla lista
    for(let i=0;i<this.productModels.length;i++)
      if(this.productModels[i].id==id_product){
        record=this.productModels[i];
        break;
      }
    */
   let record=this.record.product;


    //verifica se ci sono dei valori di default
    if(record.defaultcustomfields!=""){
      //parserizza i valori
      let default_cf:CustomField[]=JSON.parse(record.defaultcustomfields);
      //per valore verifica i campi personalizzati

        //if(showMessage)
          //if(confirm("Aggiornare i parametri del dispositivo con quelli di default?"))
              default_cf.forEach(d => {
                
                
                for(let i=0;i<this.record.customfields.length;i++){
                  if(this.record.customfields[i].id==d.id){
                    if(this.record.customfields[i].type=="select")
                      this.record.customfields[i].option=d.option;
                        

                    if(d.value!="" && d.value!=undefined)
                      if(this.record.customfields[i].value!=""){
                        
                          this.record.customfields[i].value=d.value;
                        
                      }else{
                        
                          this.record.customfields[i].value=d.value;
                      }
                  }
                }
                
              });

    }
  }

  addProduct(){
    
  }

  getProduct(event:Product){

    this.record.product=event;
    this.record.id_product=event.id;
    
    
   
  }

  openQrCode(){
    if(this.record.code!=""){
      window.open(this.record.code);
    }else{
      Globals.message.showToaster("Inserire un link nel campo QR-CODE",ToastMode.DANGER);
    }
  }

  removeCronology(c){
    if(confirm("Sicuro di voler eliminare la voce di cronologia?Questa operazione non è reversibile")){
      this.installationsService.deleteHistory(c.id).subscribe(()=>{
        for(let i=0;i<this.record.history.length;i++){
          if(this.record.history[i]==c){
              this.record.history.splice(i,1);
              return;
          }
        }
    });
  }
  }

  newModalXrifInstallationShop(){
    this.selectedXrifInstallationShop={};
    this.selectedXrifInstallationShop.id=0;
    this.selectedXrifInstallationShop.id_installation=this.record.id;
    this.selectedXrifInstallationShop.created=Helper.convertDateControl();
    this.selectedXrifInstallationShop.id_shop=0;
    this.selectedXrifInstallationShop.note="";
    this.selectedXrifInstallationShop.shopname="";
    this.openModalXrifInstallationShop(this.selectedXrifInstallationShop);
  }
  
  openModalXrifInstallationShop(r){
    this.selectedXrifInstallationShop=r;
    this.modalXrifInstallationShopRef=this.modalService.open(this.modalXrifInstallationShop);
  }
  
  closeModalXrifInstallationShop(){
    this.modalXrifInstallationShopRef.close("success");
  }
  SaveXrifInstallationShop(){
    let isNew=false;
    if(this.selectedXrifInstallationShop.id==0)
      isNew=true;
    this.installationsService.saveHistory(this.selectedXrifInstallationShop,(newId)=>{
      this.closeModalXrifInstallationShop();
      this.selectedXrifInstallationShop.id=newId;
      if(isNew){
        this.record.history.push(this.selectedXrifInstallationShop);
      }
    });
  }

  selectXrifShop(){
    Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona la nuova ubicazione del prodotto"}],(instance)=>{
      let r=instance['recordSelected'];
      this.selectedXrifInstallationShop.id_shop=r.id;
      this.selectedXrifInstallationShop.shopname=r.name;

    });
  }


  checkSN(){
    if(this.id>0){ 
      return;
    }
    //verifica se la matricola è già presente
    this.installationsService.getSN(this.record.sn).subscribe((result)=>{
      if(result && result.length>0){
        this.sn_exist=true;
      }else{
        this.sn_exist=false;
      }
    });
  }

  addDeadline(){
    Globals.modal.showModal(DeadlineDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"table","value":"installations"},
      {"name":"id_table","value":this.record.id},
      {"name":"reference","value":this.record},
      {"name":"id_type","value":this.module.getParam("id_type_deadline_next",0)},
      
      

    ],(instance)=>{
      if(instance){
        this.record.nextDeadline=instance['record']
      }
    })
  }
  openDeadline(){
    Globals.modal.showModal(DeadlineDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":this.record.nextDeadline.id},


    ],(instance)=>{
      if(instance){
        this.record.nextDeadline=instance['record']
      }
    })
  }

  resetStock(id_inventory){
    if(confirm("Sicuro di voler azzerare la giacenza in questo magazzino?"))
    this.inventoriesService.resetStock(this.record.id_product,id_inventory,this.record.sn).subscribe(
      ()=>{
        this.getItem();
        
      }
    );
    
  }


}
