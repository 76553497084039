<div class="row my-1">
    <div *ngIf="id_product==0" class="col-12 col-lg-3 pr-lg-0">
        <small>
            <app-treecategories [multiple]="true" [class]="'card p-1'" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [showCountProduct]="true" [editButton]="false" #tree [list]="categories"></app-treecategories>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;getItems();" style="cursor:pointer;">Tutti</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;getItems();" style="cursor:pointer;">Giacenza positiva</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==7" (click)="filter_stock.mode=7;getItems();" style="cursor:pointer;">Giacenza negativa</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;getItems();" style="cursor:pointer;">Giacenza nulla</li>
                <div class="icon"><i class="fas fa-cubes"></i></div> 
            </ul>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==0" (click)="filter_date_stock.mode=0;getItems();" style="cursor:pointer;">Tutti</li>
            
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==7" (click)="filter_date_stock.mode=7" style="cursor:pointer;">Movimentati prima del...</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==6" (click)="filter_date_stock.mode=6" style="cursor:pointer;">Movimentati dopo il...</li>
                
                <div class="icon"><i class="fas fa-truck-loading"></i></div>
                <li *ngIf="filter_date_stock.mode==6 || filter_date_stock.mode==7" class="list-group-item d-flex justify-content-between align-items-center">
                    <input type="date" class="form-control form-control-sm" name="filter_date_stock" (change)="getItems();" [(ngModel)]="filter_date_stock.value">
                </li>
            </ul>
        </small>
    </div>
    <div [class.col-lg-9]="id_product==0" [class.col-12]="id_product>0">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row" *ngIf="id_product==0">
                    <div class="col-lg-4">
                        <div class="input-group input-group-info input-group-sm p-1">
                            <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per marca, modello  prodotto " [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                            <div class="input-group-append ">
                                <button type="submit" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="input-group input-group-sm m-1" >
                            <select class="form-control form-control-sm" [(ngModel)]="filter_id_brand.value" (ngModelChange)="getItems()">
                                <option value="">Tutte le marche</option>
                                <option *ngFor=" let r of brands" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                            <div class="input-group-append ">
                                <button class="btn btn-sm btn-default" (click)="openBrandsManager()"><i class="fa fa-cog"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="input-group input-group-sm m-1" >
                            <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()">
                                <option value="">Tutte le tipologie</option>
                                <option *ngFor=" let r of types" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-2 py-1" >
                        <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1 mr-1"><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block"></span></button>
                        <!-- <button *ngIf="id_product==0"  (click)="load()"  class="btn btn-success btn-xs float-right m-1"><i class="fas fa-upload"></i><span class="d-none d-sm-inline-block"></span> Carica</button> -->
                        <!-- <button *ngIf="id_product==0"  (click)="unload()"  class="btn btn-danger btn-xs float-right m-1"><i class="fas fa-download"></i><span class="d-none d-sm-inline-block"></span> Scarica</button> -->
                        <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block"></span></button>-->
                    </div>
                </div>
                <div *ngIf="list==null" class="m-4">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                </div>
                <form [formGroup]="form" *ngIf="list!=null">
                    <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                        <colgroup>
                            <col />
                            <col />
                            <col *ngFor="let i of inventories" style="background-color:{{i.color}};" />
                            </colgroup>
                        <thead class="bg-light">
                            <th>
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('ps.stock')}}"  ></i>
                                </button>
                            </th>
                            <th >
                                Prodotto
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pm.name')">
                                    <i class="fas fa-sort {{classOrdering('pm.name')}}"  ></i>
                                </button>
                            </th>
                            
                            <ng-container *ngFor="let i of inventories">
                                <th class="text-center" *ngIf="i.id_parent==0">
                                    <span data-toggle="tooltip" title="{{i.name}}">{{i.code}}</span>
                                </th>
                            </ng-container>
                            <th>Agg.
                                <button type="button" class="p-0 btn btn-tool" (click)="switchOrdering('ps.date')">
                                    <i class="fas fa-sort {{classOrdering('ps.date')}}"  ></i>
                                </button>
                            </th>
                            <!--
                            <th>
                                Magazzino
                            </th>
                            <th >
                                Disponibilità
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('ps.stock')}}"  ></i>
                                </button>
                            </th >
                            -->
                            <th></th>
                        </thead>
                        <tbody>
                            <!-- elemento ripetuto -->
                            <tr  *ngFor="let r of list">
                                <td [class.text-success]="r.stock>0" [class.text-gray]="r.stock == 0" [class.text-danger]="r.stock < 0" class="text-center pt-3">
                                    <i *ngIf="!r.icon" class="fas fa-box-open fa-2x "></i>
                                    <i class="{{r.icon}} fa-2x"></i>&nbsp;
                                    <h5 >{{r.stock}} <small>{{r.unit}}</small></h5>
                                </td>
                                <td>
                                    <app-productitem [sn]="" [product]="r"></app-productitem>
                                </td>
                                <ng-container *ngFor="let s of r.stocks">
                                    <td class="border text-center" *ngIf="s.id_parent==0"> 
                                        <span data-toggle="tooltip" title="{{s.inventoryname}}" style="cursor: default; color: {{s.textcolor}};" [class.text-danger]="s.stock<0">{{s.stock}}</span>
                                        <ng-container *ngIf="s.stock<r.minstock"><br>
                                            <i data-toggle="tooltip" title="Quantità inferiore allo stock minimo ({{r.minstock}} {{r.unit}})" class="text-warning fas fa-exclamation-triangle"></i>
                                        </ng-container>
                                        <ng-container *ngIf="s.substocks">
                                            <ng-container *ngFor="let ss of s.substocks">
                                                <ng-container *ngIf="ss.stock!=0">
                                                    <br><small title="{{ss.inventoryname}}" data-toggle="tooltip" style="cursor: default; color: {{s.textcolor}};">({{ss.stock}})</small>
                                                </ng-container>
                                                
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <td>
                                    {{r.date | date:"dd/MM/yyyy"}}
                                </td>
                                <!--
                                <td>{{ r.inventoryname}}</td>
                                <td style="min-width: 120px;" ><h5 [class.text-success]="r.avaible>0" [class.text-danger]="r.avaible < 1">{{r.avaible}} <small>{{r.unit}}</small></h5> </td>
                                    -->
                                <td>
                                    <button type="button" class="btn btn-primary btn-xs float-right m-1" (click)="move(r)"><i class="fas fa-arrows-alt-h"></i></button>
                                    <button type="button" class="btn btn-secondary btn-xs float-right m-1" *ngIf="user.isAdmin()" (click)="resetStock(r)"><i class="fab fa-creative-commons-zero"></i></button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </form>

                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>