import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Helper } from 'projects/core/src/helper';
import { Filter } from 'projects/core/src/include/structures';
// import { registerLocaleData } from '@angular/common';
// import localeIt from '@angular/common/locales/it';
// registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-filterdate',
  templateUrl: './filterdate.component.html',
  styleUrls: ['./filterdate.component.css']
})
export class FilterdateComponent implements OnInit {
  
  @Output()
  onSelect:EventEmitter<number>=new EventEmitter();

  @Input()
  filter_date:Filter;

  @Input()
  date_type:number=1;

  @Input()
  class="";

  @Input()
  future=false;
  
  @Input()
  past=true;

  today:Date=new Date();
  date_from: Date=new Date();
  date_to: Date=new Date();
  years=[];
  year;
  month;
  days_month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


  constructor() { }

  ngOnInit(): void {
    for(let i=this.today.getFullYear();i>this.today.getFullYear()-10;i--){
      this.years.push(i);
    }
    this.year=this.today.getFullYear();
    this.month=this.today.getMonth();
  }

  ngOnChanges():void{
    
    this.setDate(this.date_type);
  }

  

  setDate(type){

    
    this.date_type=type;
    
    if(this.date_type==99)
      return;

      this.date_from.setDate(this.today.getDate());
      this.date_from.setMonth(this.today.getMonth());
      this.date_from.setFullYear(this.today.getFullYear());

      this.date_to.setDate(this.today.getDate());
      this.date_to.setMonth(this.today.getMonth());
      this.date_to.setFullYear(this.today.getFullYear());

    switch(this.date_type){
      case 1: //today
        this.date_from.setDate(this.today.getDate());
       
    
        this.date_to.setDate(this.today.getDate());
     
      
        break;

      case 2: //yesterday

        this.date_from.setDate(this.today.getDate()-1);
       
        this.date_to.setDate(this.today.getDate()-1);
        

        break;

      case 3: //7 days

        this.date_from.setDate(this.today.getDate()-7);
     
    
        this.date_to.setDate(this.today.getDate());
      

        break;

      case 4: //30 days

        this.date_from.setDate(this.today.getDate()-30);
        
    
        this.date_to.setDate(this.today.getDate());
       

        break;

      case 5: //90 days

        this.date_from.setDate(this.today.getDate()-90);
        
    
        this.date_to.setDate(this.today.getDate());
       

        break;
      
      case 6: //from origin

        this.date_from.setDate(1);
        this.date_from.setMonth(1);
        this.date_from.setFullYear(1900);
        
        
    
        this.date_to.setDate(this.today.getDate());
       

        break;

      case 7: //tomorrow

        this.date_from.setDate(this.today.getDate()+1);
       
        this.date_to.setDate(this.today.getDate()+1);
        

        break;

      case 8: //to 7 days

        this.date_from.setDate(this.today.getDate());
     
    
        this.date_to.setDate(this.today.getDate()+7);
      

        break;

      case 9: //to 30 days

        this.date_from.setDate(this.today.getDate());
        
    
        this.date_to.setDate(this.today.getDate()+30);
       

        break;

      case 10: // to 90 days

        this.date_from.setDate(this.today.getDate());
        
    
        this.date_to.setDate(this.today.getDate()+90);
       

        break;
      
      case 11: //from end

        this.date_to.setDate(31);
        this.date_to.setMonth(11);
        this.date_to.setFullYear(2999);
        
        
    
        this.date_from.setDate(this.today.getDate());
       

        break;

      case 97: // month

        this.date_from.setFullYear(this.today.getFullYear());
        this.date_to.setFullYear(this.today.getFullYear());

        let month_correction = 0;
        //if(this.month>this.today.getMonth()) month_correction = -1;
        this.date_from.setMonth(this.month + month_correction);
        this.date_to.setMonth(this.month + month_correction);

        this.date_from.setDate(1);
        this.date_to.setDate(this.days_month[this.month + month_correction]);

        break;

      case 98: // year

      this.date_from.setDate(1);
      this.date_from.setMonth(0);
      this.date_from.setFullYear(this.year);
     
      this.date_to.setMonth(11);
      this.date_to.setDate(31);
      this.date_to.setFullYear(this.year);
      break;

      case 100: //all

        this.date_from.setDate(1);
        this.date_from.setMonth(0);
        this.date_from.setFullYear(2000);

        this.date_to.setDate(31);
        this.date_to.setMonth(11);
        this.date_to.setFullYear(2099);
        
        break;



    }

    

    this.filter_date.value=Helper.convertDateControl(this.date_from,false);
    this.filter_date.value2=Helper.convertDateControl(this.date_to,false);


    
   this.update();

  }

  update(){
    this.onSelect.emit(this.date_type);
  }

  

}
