<app-windowlist [model]="this">
    <div class="card">
        <div class="row">
            <div class="col-lg-4">
                <app-selecttables [name]="'filter_type'" [value]="filter_type.value" [show_all]="true" (onchange)="filter_type.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right m-1" data-toggle="modal" data-target="#modalRule" (click)="addTemplate()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <form>
            <table class="table table-sm table-striped text-nowrap m-0" >
            <thead>
                <th>ID</th>
                <th>Nome</th>
                <th>Tabella</th>
                <th class="text-center">Formato</th>
                <th class="text-center">Pulsante</th>
                <th></th>
            </thead>
            <tbody>

            <!-- elemento ripetuto -->
            <tr *ngFor="let record of list">
                <td>{{record.id}}</td>
                <td class="pl-2">
                    <i *ngIf="record.status==1" data-toggle="tooltip" title="Abilitato" class="fa fa-check text-success"></i>
                    <i *ngIf="record.status==0" data-toggle="tooltip" title="Disabilitato" class="fas fa-times text-danger"></i>
                    {{record.name}}
                </td>
                <td>
                    <small><i>{{record.table}}</i></small>
                </td>
                <td class="text-center">
                    <b><span class="badge bg-gray">{{record.format}}</span></b>
                </td>
                <td class="text-center">
                    <i *ngIf="record.inrow"  class="fa fa-check text-success"></i>
                </td>
                <td class="pr-2">
                    <button  type="button" data-toggle="tooltip" title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(record.id)">
                        <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button type="button"  data-toggle="tooltip" title="Duplica" class="btn btn-info btn-xs float-right ml-1" (click)="duplicate(record)">
                        <i class="far fa-clone"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button type="button" data-toggle="tooltip" title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  data-toggle="modal" data-target="#modalRule" (click)="setRule(record)">
                        <i class="fas fa-pencil-alt"></i></button>
                </td>
            </tr>
            </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary py-1">
                <b>Impostazioni modello</b>
            </div>
            <div class="modal-body" *ngIf="selectedTemplate">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="selectedTemplate.name"/>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-sm">
                            <label>Stato</label>
                            <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedTemplate.status">
                                <option value="0">Non pubblicato</option>
                                <option value="1">Pubblicato</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group form-group-sm">
                            <label>Tabella</label>
                            <app-selecttables [name]="'table'" [value]="selectedTemplate.table" [show_all]="false" (onchange)="selectedTemplate.table=$event;"></app-selecttables>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2">
                        <div class="form-group form-group-sm">
                            <label>Formato</label>
                            <input class="form-control form-control-sm" type="text" name="format" [(ngModel)]="selectedTemplate.format"/>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Origine dei record</label>
                            <select class="form-control form-control-sm" name="detail" [(ngModel)]="selectedTemplate.detail">
                                <option value="0">Accetta i record selezionati nella ricerca</option>
                                <option value="1">Accetta un record specifico</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Nome file <small>(se vuoto verrà generato un nome random)</small></label>
                            <input class="form-control form-control-sm" type="text" name="filename" [(ngModel)]="selectedTemplate.filename"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="selectedTemplate.detail==1">
                    <div class="col-lg-2">
                        <div class="form-group form-group-sm">
                            <label>Pulsante rapido</label>
                            <select class="form-control form-control-sm" name="inrow" [(ngModel)]="selectedTemplate.inrow" data-toggle="tooltip" title="Scegli se inserire fra i pulsanti nella riga della tabella">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <div class="form-group form-group-sm" *ngIf="selectedTemplate.inrow">
                            <label>Specifica una condizione per la visualizzazione <small>(opzionale)</small></label>
                            <input type="text" class="form-control form-control-sm" name="condition" [(ngModel)]="selectedTemplate.condition">
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>Sorgente di origine dati</label>
                        <table class="table table-sm table-head-fixed text-nowrap m-0" >
                            <thead>
                                <th>Identificativo</th>
                                <th>Tabella</th>
                                <th>Funzione</th>
                                <th>Argomenti</th>
                                <th><a type="button" class="btn btn-xs btn-success float-right" data-toggle="tooltip" title="Aggiungi" (click)="addOrigin()"><i class="fa fa-plus"></i></a></th>
                            </thead>
                            <tbody>
                                <tr class="form-group" *ngFor="let o of selectedTemplate.originsArray">
                                    <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.id"/></td>
                                    <td><app-selecttables [name]="'table'" [value]="o.table" [show_all]="false" (onchange)="o.table=$event;"></app-selecttables></td>
                                    <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.task"/></td>
                                    <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.args"/></td>
                                    <td>
                                        <a type="button" class="btn btn-xs btn-secondary float-right ml-2" data-toggle="tooltip" title="Esempio di struttura" (click)="getExample(o)"><i class="fas fa-code"></i></a>
                                        <a type="button" class="btn btn-xs btn-danger float-right" data-toggle="tooltip" title="Elimina" (click)="removeOrigin(o)"><i class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9 pr-lg-0">
                        <ckeditor *ngIf="editorOn && selectedTemplate.format=='html'" [config]="{allowedContent:true}" tagName="textarea" [editor]="Editor" [(ngModel)]="selectedTemplate.body"></ckeditor>
                        <textarea *ngIf="!editorOn || selectedTemplate.format!='html'" rows="28" class="form-control" name="body"  [(ngModel)]="selectedTemplate.body" ></textarea>
                        <a type="button" class="btn btn-xs btn-info float-right m-1" (click)="getPreview();"><i class="fas fa-eye mr-1"></i>Anteprima</a>
                        <a href="https://mathjs.org/docs/expressions/syntax.html" target="_blank" type="button" class="btn btn-xs btn-info float-right m-1"><i class="fas fa-question mr-1"></i>Aiuto</a>
                    </div>
                    <small class="col-lg-3 pl-lg-1">
                        <div class="example_container">
                            <ngx-json-viewer *ngIf="example!=null" [json]="example" ></ngx-json-viewer>
                        </div>
                    </small>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-1"></i>Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
            </div>
        </div>
    </div>
</div>