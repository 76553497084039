import { Component, OnInit, Input } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { FormBuilder} from '@angular/forms';
import { TypesService } from '../../../../services//types.service';
import { ProductcategoriesService } from '../../../../services/productcategories.service';
import { Type } from '../../../../db/type';
import { Filter,FilterMode, OrderingMode } from 'projects/core/src/include/structures'

import { InstallationsService } from '../../../../services/installations.service';
import { Installation } from '../../../../db/installation';
import { ProductCategories } from '../../../../db/productcategories';
import { NotificationService } from '../../../../services/notification.service';
import { Globals } from 'projects/core/src/globals';
import { UploadFileComponent } from 'projects/core/src/common/upload-file/upload-file.component';
import { ToastMode } from 'projects/core/src/include/structures';
import { SerialsDetailsComponent } from './../serials-details/serials-details.component';
import { ShopsComponent } from '../../../shops/shops.component';
import { DocumentsDetailsComponent } from '../../../documents/documents-details/documents-details.component';
import { FormsValueService } from '../../../../services/formsvalue.service';
import { ActivitiesService } from 'projects/c1-backend/src/app/services/activities.service';
import { Activities } from 'projects/c1-backend/src/app/db/activities';

@Component({
  selector: 'app-serials-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class SerialActivitiesComponent extends ModelList<Activities> implements OnInit {

  @Input()
  id_product:number=0;

  @Input()
  id_address:number=0;

  @Input()
  id_shop:number=0;

  modulename="installations";
  
  
  type_product:Type[];
  type_shops:Type[];
  type_activities:Type[];
  categories_product:ProductCategories[];
  status_product:Type[];
  filter_search:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_type:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_phase:Filter=new Filter();
  filter_id_type_installation:Filter=new Filter();



  filter_date:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_id_shop:Filter=new Filter();
  filter_category:Filter=new Filter();
  filter_shop_id_type:Filter=new Filter();
  filter_id_reseller:Filter=new Filter();
  filter_id_product:Filter<number>=new Filter<number>();
  filter_qrcode:Filter=new Filter();
  isFilterSet=false;
  id_category_log=0;

  constructor(
    private fb1: FormBuilder,
    private installationsService: InstallationsService,
    private activitiesService: ActivitiesService,
    private typesService: TypesService,
    private productcategoriesService: ProductcategoriesService,
    private notificationService:NotificationService,
    private formsService:FormsValueService
    
  ) {
    super(activitiesService,fb1);
    this.detailViewModal=SerialsDetailsComponent;
   }


  ngOnInit(): void {

    //this.tableColumns.push(new TableColumn("Password richieste","customfields['Password richieste']"));

    this.title='Prodotti installati';
    super.ngOnInit.apply(this, arguments); 

    this.id_category_log=this.module.getParam("id_category_log",0);


      this.typesService.getTypes("installations").subscribe((items)=>{
        this.type_product=items;
      });

      this.typesService.getTypes("shops").subscribe((items)=>{
        this.type_shops=items;
      });

      this.typesService.getTypes("activities").subscribe((items)=>{
        this.type_activities=items;
      });

      this.productcategoriesService.getCategories().subscribe((items)=>{
        this.categories_product=items;
      });

    

    

      if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
        this.updateList(this.id_address);
  }


  setFilters():void{
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    //this.filter_search.fields.push("i.brand");
    this.filter_search.fields.push("i.model");
    this.filter_search.fields.push("i.sn");
    this.filter_search.fields.push("i.matricola");
    this.filter_search.fields.push("addr.name");
    this.filter_search.fields.push("s.name");
    
    this.filter_search.fields.push("s.city");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    /*
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("a.id");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);

    this.filter_shop_id_type.mode=FilterMode.normal;
    this.filter_shop_id_type.fields=[];
    this.filter_shop_id_type.fields.push("s.id_type");
    this.filter_shop_id_type.value="";
    this.filter.push(this.filter_shop_id_type);
    
    this.filter_id_shop.mode=FilterMode.normal;
    this.filter_id_shop.fields=[];
    this.filter_id_shop.fields.push("p.id_shop");
    this.filter_id_shop.value="";
    this.filter.push(this.filter_id_shop);

    this.filter_id_product.mode=FilterMode.normal;
    this.filter_id_product.fields=[];
    this.filter_id_product.fields.push("p.id_product");
    this.filter_id_product.value=0;
    this.filter.push(this.filter_id_product);
    
    */
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("a.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);
    
    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("a.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_type_installation.mode=FilterMode.normal;
    this.filter_id_type_installation.fields=[];
    this.filter_id_type_installation.fields.push("i.id_type");
    this.filter_id_type_installation.value="";
    this.filter.push(this.filter_id_type_installation);
    

    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("a.table");
    this.filter_table.value="installations";
    this.filter.push(this.filter_table);

    this.filter_phase.mode=FilterMode.normal;
    this.filter_phase.fields=[];
    this.filter_phase.fields.push("a.phase");
    this.filter_phase.value="3";
    this.filter.push(this.filter_phase);
    

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("a.date");
    this.filter_date.value="";
    this.filter.push(this.filter_date);


    /*
    this.filter_category.mode=FilterMode.normal;
    this.filter_category.fields=[];
    this.filter_category.fields.push("pr.id_category");
    this.filter_category.value="";
    this.filter.push(this.filter_category);

    this.filter_id_reseller.mode=FilterMode.major;
    this.filter_id_reseller.fields=[];
    this.filter_id_reseller.fields.push("p.id_reseller");
    this.filter_id_reseller.value="";
    this.filter.push(this.filter_id_reseller);

    this.filter_qrcode.mode=FilterMode.normal;
    this.filter_qrcode.fields=[];
    this.filter_qrcode.fields.push("p.code");
    this.filter_qrcode.value="";
    this.filter_qrcode.nullvalue="null";
    this.filter_qrcode.enabled=false;
    this.filter.push(this.filter_qrcode);

    this.advancedFilter=Globals.navigation.getStateFilters(this.constructor.name,this.filter);
    */
    this.ordering.field="a.date";
    this.ordering.mode=OrderingMode.discendent;
    this.groupby="a.id_table"

    this.other_params.push("installations");
    this.other_params.push("i");
    this.other_params.push("true");
    

    this.isFilterSet=true;
  }


  showUploadFile(){
    Globals.modal.showModal(UploadFileComponent,[{"name":"subdirectory","value":"qrcode"},{"name":"extensions","value":"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"},{"name":"description","value":"Carica un file ZIP contenente le immagini QR-CODE dei prodotti associati. Ogni file contenuto nel pacchetto ZIP deve essere nel formato '<matricola> <anagrafica>.jpg'"}],(instance)=>{
      
      if(instance['filename']!=""){
        let filename=instance['filename'];
        Globals.setLoading(true);
        Globals.message.showToaster("Avvio importazione in corso. Il processo potrebbe richiedere molto tempo...",ToastMode.WARNING);
        this.installationsService.linkQRCodeZip(filename).subscribe((result)=>{
          Globals.setLoading(false);
          Globals.message.showToaster("Importati "+result['count'].toString()+" codici. Dispositivi aggiornati:"+result['devices'].join(", "),ToastMode.SUCCESS);
        });
      }
     
      
    });
  }


  ngOnChanges():void{
    if(this.id_address>0 || this.id_shop>0 || this.id_product>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }
  
  updateList(id_address:number=0):void{

    if(!this.isFilterSet)
      this.setFilters();
      
    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }
    if(this.id_shop>0){
      this.filter_id_shop.value=this.id_shop.toString();
    }

    if(this.id_product>0){
      this.filter_id_product.value=this.id_product;
    }
    
    
    this.getItems();
  }
 


  notify(record:Installation){

    this.notificationService.showPreviewNotification(record.shop.addressItem.contacts,record,"installations",()=>{

    });
    
    
  }

  newform(record:Installation){
    this.formsService.showNewForm(record,"installations",()=>{
      ;
    })
  }

  getStock(record){
    if(!record.product)
      return 0;
    for(let s of record.product.serials){
      if(s.sn==record.sn){
        if(s.id_inventory==record.shop.id_inventory){
          return s.avaible;
        }
      }
    }

    return 0;
  }
  

  moveInstallation(record){
    Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona la nuova ubicazione del prodotto"}],(instance)=>{
      let r=instance['recordSelected'];
      record.shop=r;
      const old_id=record.id_shop;
      record.id_shop=r.id;
      
      this.installationsService.moveInstallation(record.id,old_id,r.id,status,"").subscribe(result=>{
        this.save(record);
      });

      

    });
  }


  createDDT(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
    let ids_installations=this.form.value.id;
    
    this.installationsService.getItemsByIDS(ids_installations).subscribe((items)=>{
      let details=[];

      for(let i of items){
        let d={};
        d['id']=0;
        d['id_product']=i.id_product;
        d['product']=i.product;
        d['description']=i.product.brand+" "+i.product.name;
        d['quantity']=1;
        d['unit']=i.product.unit;
        d['sn']=i.sn;

        if(i.last_log && i.product.id_category==this.id_category_log)
          d['note']="Ultimo chilometraggio registrato: "+i.last_log.km+ "Km";
        details.push(d);
        
      }

      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"type_document","value":5},
        {"name":"details","value":details}
        
      ],()=>{
  
        });

    });


   

  }

}
