<app-windowlist [model]="this">

    <div class="row">
        <div class="col-lg-2 pr-md-0" *ngIf="filterbox">
            <small>
                <app-filterdate [date_type]="mode!='embedded'?1:100" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <ul class="card small-box list-group treeviewlist p-1 mt-2" *ngIf="user.isAdmin() && isModuleEnabled('shops')">
                    <li class="list-group-item" [class.active]="filter_created_from.value==''" (click)="filter_created_from.value='';getItems()" style="cursor:pointer;">Tutti gli esercizi</li>
                    <li class="list-group-item text-truncate" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()" style="cursor:pointer;">{{s.name}}</li>
                    <div  *ngIf="shops.length>2" class="icon"><i class="fa fa-store-alt "></i></div>
                </ul>

                <ul class="card small-box list-group treeviewlist p-1 mt-2 mb-4" *ngIf="user.isAdmin() && isModuleEnabled('financialaccounts')">
                    <li class="list-group-item" [class.active]="filter_financialaccount.value==''" (click)="filter_financialaccount.value='';getItems()" style="cursor:pointer;">Tutti i conti contabili</li>
                    <li class="list-group-item" *ngFor="let f of financialaccounts" [class.active]="filter_financialaccount.value==f.id" (click)="filter_financialaccount.value=f.id;getItems()" style="cursor:pointer;">
                        <span *ngIf="f.id_parent>0">--&nbsp;</span>{{f.name}}</li>
                    <div *ngIf="financialaccounts.length>2" class="icon"><i class="fas fa-coins "></i></div>
                </ul>
            </small>
        </div>

        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card card-outline">

                <ng-container *ngIf="filterbox">
                    <div class="row" >
                        <div class="col-lg-2 pr-lg-1">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="Cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    <button class="btn btn-default"  data-toggle="tooltip" title="Regole" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h text-info"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 px-lg-1">
                            <div class="input-group input-group-sm m-1" >
                                <select class="custom-select" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()">
                                    <option value="">Ingressi + Uscite</option>
                                    <option value="1">Solo Ingressi</option>
                                    <option value="2">Solo Uscite</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 px-lg-0">
                            <div class="input-group input-group-sm m-1" >
                                <select class="custom-select" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()">
                                    <option value="">Tutti gli operatori</option>
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 px-lg-1">
                            <div class="input-group input-group-sm m-1" >
                                
                                <select class="form-control form-control-sm"  [(ngModel)]="filter_method_payment.value" (ngModelChange)="getItems()">
                                    <option value="">Tutti i metodi di pagamento</option>
                                    <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-2 px-lg-0">
                            <div class="input-group input-group-sm m-1" >
                                <select class="form-control form-control-sm"  [(ngModel)]="filter_source.value" (ngModelChange)="getItems()">
                                    <option value="">Tutte le casse/banche</option>
                                    <option *ngFor="let s of sources" (value)="s.description">{{s.description}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 pl-lg-0 py-1">
                            <div class="dropdown dropleft show">
                                <a class="btn btn-default btn-xs float-right ml-1 mt-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <button type="button"   class="btn btn-xs" data-toggle="tooltip" (click)="createDocument()">Crea documento fiscale</button>
                                    <br/>
                                </div>
                            </div>
                            <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right  ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button data-toggle="tooltip" title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button data-toggle="tooltip" title="Aggiungi2" (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div  class="callout callout-info collapse my-2" id="advancedSearchPanel">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-11">
                                        <app-advancedsearch [id_field]="'c.id'" [table]="'cashflow'" [filters]="advancedFilter"></app-advancedsearch>
                                    </div>
                                    <div class="col-xs-12 col-sm-1">
                                        <button data-toggle="tooltip" title="Regole" class="btn btn-secondary btn-sm my-1" (click)="getItems()"><i class="fa fa-search mr-2"></i>Cerca</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="list==null" class="m-4">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                </div>

            

                <form [formGroup]="form" *ngIf="list!=null">
                    <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th>
                                <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                    <input type="checkbox"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                            </th>
                            <th>
                                Data
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.date')">
                                    <i class="fas fa-sort {{classOrdering('c.date')}}"  ></i>
                                </button>
                            </th>
                            <th>
                                Causale
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.description')">
                                    <i class="fas fa-sort {{classOrdering('c.description')}}"  ></i>
                                </button>
                            </th>
                            <th *ngIf="mode!='embedded'">
                                Anagrafica
                                
                            </th>
                            <th>
                                Importo
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.amount')">
                                    <i class="fas fa-sort {{classOrdering('c.amount')}}"  ></i>
                                </button>
                            </th>
                            <th>
                                Versato
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.paid')">
                                    <i class="fas fa-sort {{classOrdering('c.paid')}}"  ></i>
                                </button>
                            </th>
                            <th>
                                Differenza
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.paid')">
                                    <i class="fas fa-sort {{classOrdering('c.paid')}}" ></i>
                                </button>
                            </th>
                            <th>Altro</th>
                            <th>Utente</th>
                        
                            <th></th>
                            
                        </thead>
                        <thead>
                            <tr class="bg-white">
                                <td></td>
                                <td class="pl-4"><b>TOTALE</b></td>
                                <td *ngIf="mode!='embedded'"></td>
                                <td></td>
                                <td [class.text-red]="total_amount < 0">
                                    <b>{{total_amount | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_amount_future!=total_amount">
                                        <br/>
                                        <i>{{total_amount_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td [class.text-red]="total_paid < 0">
                                    <b>{{total_paid | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_paid_future!=total_paid">
                                        <br/>
                                        <i>{{total_paid_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td [class.text-red]="total_difference < 0">
                                    <b>{{total_difference | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_difference_future!=total_difference">
                                        <br/>
                                        <i>{{total_difference_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td></td>
                                <td class="d-none d-xl-table-cell"></td>
                                <td></td>
                            </tr>

                        </thead>
                        <tbody>
                        
                            <!-- elemento ripetuto -->
                            <tr *ngFor="let record of list" [class.bg-futureline]="verifyDate(record.date)" [class.bg-redline]="record.id_type==2" [class.bg-greenline]="record.id_type==1">
                                <td data-toggle="tooltip" title="{{record.date | date:'EEEE'}}">
                                    <div class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" data-toggle="tooltip" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                    </div>
                                    
                                </td>
                                <td>
                                    <a [class.trashed]="record.status == 2" (click)="openDetail(record)">
                                        
                                        {{record.date | date:'dd/M/yyyy'}}
                                    </a>
                                </td>
                                <td>
                                    
                                    {{ record.description }}<br/>
                                    
                                </td>
                                <td *ngIf="mode!='embedded'">{{ record.addressItem.name }}</td>
                                <td>
                                    <span *ngIf="record.id_type==1" >{{ record.amount*(1+record.tax/100) | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                    <span *ngIf="record.id_type==2" class="text-red">{{ record.amount*(1+record.tax/100)*-1 | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                    <br/><small><b *ngIf="record.method_payment">{{ record.method_payment }}</b></small>
                                </td>
                                <td>
                                    <span *ngIf="record.id_type==1" >{{ record.paid | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                    <span *ngIf="record.id_type==2" class="text-red">{{ record.paid*-1 | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                
                                </td>
                                <td>
                                    <span [class.text-red]="record.balance < 0">{{ record.balance | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span></td>
                                <td>
                                    <div class="" *ngIf="record.reference">
                                        <small>
                                            <app-reference [table]="record.table" [reference]="record.reference"></app-reference>
                                        </small>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell" style="font-size:80%">
                                    <div class="text-truncate" style="max-width:6rem">{{record.username}}</div>
                                    <div class="text-truncate" style="max-width:6rem"><small>{{record.shopname}}</small></div>
                                    <div class="text-truncate" style="max-width:6rem"><b>{{record.source}}</b></div>
                                </td>
                                <td>
                                    <a *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                    <i class="fas fa-pencil-alt"></i></a>
                                    <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                        <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                    </button>

                                    <button type="button" *ngIf="deleteInRow" class="btn btn-xs btn-danger float-right ml-1" (click)="trash([record.id],true);"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                            <!-- fine elemento ripetuto -->
                            
                        </tbody>
                        <tfoot >
                            <tr class="bg-white">
                                <td></td>
                                <td class="pl-4"><b>TOTALE</b></td>
                                <td *ngIf="mode!='embedded'"></td>
                                <td></td>
                                <td [class.text-red]="total_amount < 0">
                                    <b>{{total_amount | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_amount_future!=total_amount">
                                        <br/>
                                        <i>{{total_amount_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td [class.text-red]="total_paid < 0">
                                    <b>{{total_paid | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_paid_future!=total_paid">
                                        <br/>
                                        <i>{{total_paid_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td [class.text-red]="total_difference < 0">
                                    <b>{{total_difference | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_difference_future!=total_difference">
                                        <br/>
                                        <i>{{total_difference_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td></td>
                                <td> <small *ngIf="total_difference_future!=total_difference">
                                    * valori ancora da contabilizzare
                                </small></td>
                                <td></td>
                            </tr>

                        </tfoot>
                    </table>
                    
                </form>
            
                <div class="card-footer clearfix py-1" *ngIf="mode!='embedded'">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
                 
            </div>
        </div>
    </div>
</app-windowlist>
