<a *ngIf="list.length>0" class="nav-link" data-toggle="dropdown" href="#">
    <i class="far fa-bell"></i>
    <span class="badge">{{list.length}}</span>
</a>
<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
    
    <ng-container *ngFor="let r of list">
        
        <a  class="dropdown-item" style="white-space: normal;" (click)="openModalOverview(r)">
            <!-- <i class="fas fa-exclamation-circle mr-2 text-info"></i> -->
            <!-- <i class="fas fa-exclamation-circle mr-2 text-danger"></i> -->
            <i class="fas fa-exclamation-circle mr-2 text-warning"></i>
            <!-- <i class="fas fa-exclamation-circle mr-2 text-success"></i> -->
            <small>{{r.message}}</small>
          </a>
          <div class="dropdown-divider"></div>
    </ng-container>

    <!-- <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> -->
</div>

  <ng-template #modalOverview>
    <div class="modal-header">
        {{alarmSelected.message}}
    </div>
      <div class="modal-body">
        <ul class="list-group">
           <li class="list-group-item" *ngFor="let i of alarmSelected.items">
                <app-reference [reference]="i" [table]="alarmSelected.table"></app-reference>
                <hr/>
           </li> 
        </ul>
      </div>
      <div class="modal-footer">
          <button class="btn btn-secondary" (click)="closeModalOverview()">Chiudi</button>
      </div>
  </ng-template>