<app-windowlist [model]="this">
    
    <div class="row">
        <div class="col-6">

            <div class="row">
                <div class="col-8">
                    <div class="input-group input-group-sm m-1" >
                        <input type="text" name="table_search" class="form-control float-right" placeholder="Cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-default text-danger"  data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-4" *ngIf="mode!='modal'">
                    <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-3">
                        <input  type="checkbox"
                        (change)="onChangeAll($event.target.checked)" />
                    </div>
                    <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button data-toggle="tooltip" title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button data-toggle="tooltip" title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                </div>
            </div>

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let record of list">
                    <div class="row">
                        <div class="col-9">
                            <a [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record);" ><h6>{{record.name}}&nbsp;<i *ngIf="record.id_inventory>0" class="fa fa-boxes"></i></h6></a>
                            <small>
                                {{record.activity}} <br/>
                                {{record.address}}, {{record.city}}
                            </small>
                        </div>
                        <div class="col-3">

                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                        />&nbsp;
                            <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                            
                            <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                <i class="far fa-paper-plane"></i><span class="d-none d-sm-inline-block"></span></button>
                            <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div> 
        <div class="col-6" >
            
            <div id="map" style="width: 100%;min-height: 500px;height:100%" *ngIf="id_address==0"></div>    
            <div class="searchbarmap" >
                
                <input *ngIf="openSearchBar" type="text" class=" form-control form-control-sm" [(ngModel)]="search" placeholder="Inserisci l'indirizzo da ricerca..." (keyup.enter)="geocoding()">
                
                <button class="btn btn-default btn-sm" (click)="openSearchBar=!openSearchBar"><i class="fa fa-search"></i></button>
                
            </div>
                
        </div>
            
    </div>
    
</app-windowlist>
