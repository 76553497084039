<ul class="small-box list-group treeviewlist {{class}}">
    <li *ngIf="showAll" class="list-group-item d-flex justify-content-between align-items-center" [class.active]="id==0" (click)="OnSelect(0)" style="cursor:pointer;">Tutte le categorie
        <button class="btn btn-xs"  data-toggle="tooltip" title="Personalizza categorie" [class.btn-secondary]="editButton" (click)="editButton=!editButton"><i class="fa fa-cog"></i></button>
    </li>
    <ng-container *ngFor="let i of list" >
        <li class="list-group-item px-0" [class.active]="checkSelected(i.id)" (click)="OnSelect(i.id)" style="cursor:pointer;">
            <div style="height: 1rem" [class.text-gray]="!editButton && i.count==0" [class.text-danger]="!editButton && i.count<0">
                <span *ngIf="i.parent>0">&ensp;--&nbsp;</span><i class="{{i.icon}} mr-1"></i><span><span *ngIf="editButton">{{i.id}}-</span>{{i.name}}</span>
                <button *ngIf="editButton" class="btn btn-xs text-danger float-right ml-1" (click)="delete(i)" data-toggle="tooltip" title="Elimina"><i class="fa fa-trash"></i></button>
                <button *ngIf="editButton" class="btn btn-xs float-right ml-1" (click)="edit(i)" data-toggle="tooltip" title="Modifica"><i class="fa fa-pencil-alt"></i></button>
            </div>
            <div style="height: 15px;margin-top:-15px;width:100%">
                <div *ngIf="!editButton && showCountProduct && i.count>0" class="bg-success float-right text-right"
                    style="min-width:15px; max-width:15vw; width: calc( {{i.count}}px / 1 ); border-radius: 0.5rem 0 0 0.5rem;">
                    <small class="pr-1">{{i.count}}</small>
                </div>
            </div>
            <app-treecategories *ngIf="i.hasChildren" [list]="i.children"></app-treecategories>
        </li>
    </ng-container> 
    <li *ngIf="editButton" class="list-group-item list-group-item-success" (click)="add()" style="cursor:pointer;"><i class="fa fa-plus"></i>&nbsp;Aggiungi</li>
    <div class="icon"><i class="fas fa-clipboard-list"></i></div>
</ul>

<ng-template #detailModal let-modal>
    <div class="modal-body">
        <h5>Modifica categoria</h5>
        <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="recordSelected.name" />
        </div>
        <div class="form-group">
            <label>Icona</label>
            <div class="input-group input-group-sm">
                
                <input type="text" class="form-control form-control-sm" [(ngModel)]="recordSelected.icon" />
                <div class="input-group-append">
                    <span class="input-group-text bg-white"><i class="{{recordSelected.icon}}"></i></span>
                </div>
                <div class="input-group-append ">
                    <a class="input-group-text" data-toggle="tooltip" title="cerca" target="_blank" href="https://fontawesome.com"><i class="fas fa-search"></i></a>
                </div>
                
            </div>
        </div>
        <div class="form-group">
            <label>Categoria collegata</label>
            <select class="form-control form-control-sm" [(ngModel)]="recordSelected.parent">
                <option value="0">Nessuna</option>
                <option *ngFor="let t of categories" [ngValue]="t.id"><span *ngFor="let i of [].constructor(t.level-1)"> - </span>{{t.name}}</option>
                    
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="save();modal.close()">Ok</button>
        <button class="btn btn-secondary" (click)="modal.close()">Annulla</button>
    </div>
</ng-template>