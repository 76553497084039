<div class="modal-body">
    <ng-container *ngIf="state_cashregister==0">
        <h5>Ricerca registratore di cassa in corso...</h5>
    </ng-container>
   


    <ng-container *ngIf="state_cashregister==1">
        <h5>Stampa in corso su registrato {{rt}}...</h5>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-secondary" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="state_cashregister==2">
        <div class="alert alert-danger">
            Nessun registratore di cassa collegato al sistema
        </div>

        <h5>Confermare lo scontrino comunque?</h5>
        <div class="row">
            <div class="col-12">
                <input type="text" name="reference" class="form-control form-control-sm" placeholder="Indicare il numero di scontrino" [(ngModel)]="document.reference" />
            </div>
            <div class="col-12">
                <button class="btn btn-secondary" (click)="confirmManual()">Ok</button>
                <button class="btn btn-secondary" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="state_cashregister==3">
        <h5>Errore durante la stampa </h5>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-secondary" (click)="state_cashregister=1;handleDocument()">Riprova</button>
                <button class="btn btn-secondary" (click)="state_cashregister=2">Manuale</button>
          

                <button class="btn btn-secondary" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>

</div>
