import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { CustomFunction, ToastMode } from '../../include/structures';
import { CustomfunctionsService } from '../../lib/customfunctions.service';

@Component({
  selector: 'app-windowlistbuttons',
  templateUrl: './windowlistbuttons.component.html',
  styleUrls: ['./windowlistbuttons.component.css']
})
export class WindowlistbuttonsComponent implements OnInit {

  @Input()
  model:any;

  @Input()
  table:string;

  @Input()
  funs:[]=[];

  @Input()
  openDetailExtra:any={};


  customfunctions:CustomFunction[]=[];


  constructor(
    private customfunctionsService:CustomfunctionsService
  ) { }

  ngOnInit(): void {
    this.customfunctionsService.getFunctions(this.table).subscribe((items)=>{
      this.customfunctions=items;
    });
  }


  executeCustomFunction(cf:CustomFunction){
    Globals.message.showToaster("Operazione iniziata...",ToastMode.WARNING);
    this.customfunctionsService.executeFunction(cf.id).subscribe((result)=>{
      Globals.message.showToaster("Operazione completata!",ToastMode.SUCCESS);
      console.log(result);
    });
  }

}
