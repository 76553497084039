<ng-container *ngIf="recordSelected==undefined || !recordSelected.id">
                  <!-- <div *ngIf="false" class="input-group">
                    <input list="values" id="searchfield" type="text" autocomplete="name" class="{{class}}"  [(ngModel)]="searchText" />
                    <div class="input-group-append">
                      <a type="button" data-toggle="tooltip" title="Aggiungi" class="btn btn-sm btn-success" (click)="add()" *ngIf="showAddButton"><i class="fa fa-plus"></i></a>
                      <button type="button" data-toggle="tooltip" title="Cerca" *ngIf="showSearchButton" class="btn btn-sm btn-primary mt-2" (click)="explore()"><i class="fa fa-search"></i></button>
                    </div>
                  </div> -->
  <button type="button" *ngIf="showSearchButton" class="btn btn-sm btn-primary btn-block" (click)="explore()"><i class="fa fa-list mr-2"></i>Seleziona</button>
</ng-container>
<ng-container *ngIf="recordSelected!=undefined && recordSelected.id">
  <div class="input-group" style="justify-content: space-between;">
    <h5 [class.trashed]="recordSelected['status']==2" class="text-truncate p-1 m-0" style="max-width:85%;">{{recordSelected[fieldRender]}}</h5>
    <div class="input-group-append">
      <a type="button" data-toggle="tooltip" title="Seleziona" class="btn btn-sm text-secondary" (click)="edit()"><i class="fas fa-list"></i></a>
      <a type="button" data-toggle="tooltip" title="Visualizza / Modifica" *ngIf="showViewButton" class="btn btn-sm text-secondary" (click)="view()"><i class="fas fa-eye"></i></a>
      <a type="button" data-toggle="tooltip" title="Dissocia" *ngIf="showUnlinkButton" class="btn btn-sm text-secondary" (click)="unlink()"><i class="fas fa-unlink"></i></a>
 
    </div>
  </div>
</ng-container>

<datalist id="values">
    <ng-container *ngFor="let r of list">
      <option value="{{r[fieldRender]}}"></option>
    </ng-container>
    <option value="Aggiungi"></option>
</datalist>