import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { VerifyFiscalCodeService } from '../../services/verify-fiscal-code.service';

@Component({
  selector: 'app-tax-code',
  templateUrl: './tax-code.component.html',
  styleUrls: ['./tax-code.component.css']
})
export class TaxCodeComponent implements OnInit {
  @Input()
  tax_code:string="";

  @Output()
  onTextChange:EventEmitter<string>=new EventEmitter();

  @Input()
  name:string="tax_code";

  tax_code_error:string="";

  constructor(
    private verifyFiscalCodeService:VerifyFiscalCodeService,

  ) { }

  ngOnInit(): void {
  }

  verifyTaxCode():boolean{
    let v:boolean=false;
    if(this.tax_code=='XXXXXXXXXXX') return v=true; // salta per intestatario estero
    this.tax_code_error="";
    if(this.tax_code!=undefined){
      v=this.verifyFiscalCodeService.validate(this.tax_code);
      if(!v)
        this.tax_code_error=this.verifyFiscalCodeService.error_message;
    }
    return v;
  }

  onKeyChange(){
    this.onTextChange.emit(this.tax_code);
  }

}
