import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';

@Injectable({
  providedIn: 'root'
})
export class CustomfunctionsService extends WSService<any> {

  constructor() { super("customfunctions") };

  getFunctions(table):Observable<any>{
    return Globals.ws.requestUrl("customfunctions","getItems",["c.table='"+table+"'"]);
  }

  executeFunction(id):Observable<any>{
    return Globals.ws.requestUrl("customfunctions","executeFunction",[id]);
  }

  

}