import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesComponent } from './activities.component';
import { ActivitiesDetailsComponent } from './activities-details/activities-details.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { ProductsModule } from '../products/products.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { MissingValuesModule } from '../../modules/missing-values/missing-values.module';
import { ActivitiesListComponent } from './list/list.component';
import { ActivitiesReschedulePaymentComponent } from './reschedule-payment/reschedule-payment.component';
import { TypeModule } from '../../modules/type/type.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ChartsModule } from 'ng2-charts';
import { CitiesModule } from '../../modules/cities/cities.module';
import { CashFlowModule } from '../cash-flow/cash-flow.module';




@NgModule({
  declarations: [ActivitiesComponent,ActivitiesDetailsComponent, ActivitiesListComponent, ActivitiesReschedulePaymentComponent, AnalyticsComponent],
  exports:[ActivitiesComponent,ActivitiesDetailsComponent,ActivitiesListComponent,ActivitiesReschedulePaymentComponent],
  imports: [
    CommonModule,
    FormsModule,
    WindowModule,
    WindowlistModule,
    PaginationModule,
    ReferenceModule,
    CustomfieldsModule,
    ActivitiesRoutingModule,
    FilterdateModule,
    ToolbarmodelModule,
    CashFlowModule,
    //ProductsModule,
    MissingValuesModule,
    TypeModule,
    ChartsModule,
    CitiesModule
    
    
    
    
  ]
})
export class ActivitiesModule { 
  //static savingActivityEvent:EventEmitter<void>=new EventEmitter();
}
