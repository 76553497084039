
<div class="dropdown dropleft show">
    <a class="btn btn-xs float-right btn-default mt-1 ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-ellipsis-v"></i>
    </a>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <button class="dropdown-item" *ngFor="let f of funs" (click)="model[f['value']]()">{{f['name']}}</button>
        <button class="dropdown-item" *ngFor="let cf of customfunctions" (click)="executeCustomFunction(cf)">{{cf['name']}}</button>
    </div>
</div>
<button data-toggle="tooltip" title="Archivia"  (click)="model.archive()" [disabled]="model.form.value.id.length == 0" class="btn btn-primary btn-xs float-right mt-1 ml-1"><i class="fas fa-archive"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
<button data-toggle="tooltip" title="Cestina" *ngIf="model.filter_status.value == '1'" (click)="model.trash()" [disabled]="model.form.value.id.length == 0" class="btn btn-danger btn-xs float-right mt-1 ml-1"><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
<button data-toggle="tooltip" title="Elimina" *ngIf="model.filter_status.value == '2'" (click)="model.delete()" [disabled]="model.form.value.id.length == 0" class="btn btn-danger btn-xs float-right mt-1 ml-1"><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
<button data-toggle="tooltip" title="Ripristina" *ngIf="model.filter_status.value == '2'" (click)="model.undo()" [disabled]="model.form.value.id.length == 0" class="btn btn-secondary btn-xs float-right mt-1 ml-1"><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
<button data-toggle="tooltip" title="Esporta" (click)="model.export()" class="btn btn-secondary btn-xs float-right mt-1 ml-1"><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
<button data-toggle="tooltip" title="Aggiungi" (click)="model.openDetail(null,openDetailExtra)" class="btn btn-success btn-xs float-right mt-1 ml-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> 
