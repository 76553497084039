<div class="modal-header">
    Verifica di tutte le fatture emesse
</div>
<div class="modal-body">

    <div class="alert alert-info" *ngIf="loading">Scaricamento informazioni in corso... La procedura potrà impiegare diversi minuti</div>

    <table class="table" *ngIf="!loading">
        <thead>
            <th>ID documento</th>
            <th>Numero</th>
            <th>Stato nel DB</th>
            <th>Stato sullo SDI</th>
            <th>Modifica</th>
            
            
        </thead>
        <tr *ngFor="let record of list">
            <td>{{record.id}}</td>
            <td>{{record.number}}</td>
            <td>{{record.old_status}}</td>
            <td>{{record.new_status}}</td>
            <td>{{record.change}}</td>
        </tr>
    </table>
</div>
<div class="modal-footer">

</div>