import {  Type } from "@angular/core";
import {NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';


@Injectable()

export class ModalWindow{

    currentModal:string="";
    selectedRecordFromModal:Function;
    titleModal:string="";
    tableModal:string="";
    //modalRef:NgbModalRef;

    constructor(
        private modalService: NgbModal,
    ){}

    //TempComponentModal;

    showModal(component:any,variables:any[],onComplete,size="xl",windowClass=""){
        
       
        let m=this.modalService.open(component,{size:size,backdrop:'static',windowClass:windowClass});

        for(let i=0;i<variables.length;i++){
            m.componentInstance[variables[i]['name']]=variables[i]['value'];
        }
        if(m.componentInstance)
         m.componentInstance['modalWindow']=m;
        
        m.result.then((r)=>{
        //quando si chiude la modal
        if(r=="success"){
            onComplete(m.componentInstance);
        }
            
        else{
            onComplete(null);
        }
            
        
        }).catch((r)=>{
            if(r=="success"){
                onComplete(m.componentInstance);
            }
                
            else{
                onComplete(null);
            }
        });
        //this.modalRef=m;
        return m;
    }
}