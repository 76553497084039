import { Component, Input, OnInit } from '@angular/core';
import {arrayMoveImmutable} from 'array-move';
import { i, json } from 'mathjs';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css']
})
export class FieldsComponent implements OnInit {

  @Input()
  sections=[];


  tabsection=0;
  id_section=0;
  param_selected="";

  default_file_params=[
    {"name":"subdirectory","value":"","label":"Nome della sottocartella (da creare)","type":"text"},
    {"name":"default_filename","value":"","label":"Nome del file (predefinito)","type":"text"}
    
  ];

  default_photo_params=[
    {"name":"multiple","value":"false","label":"Permetti di aggiungere più foto","type":"boolean"}
    
  ];

  constructor() { }

  ngOnInit(): void {

    
  }

  ngOnChanges(){
    for(let s of this.sections){
      for(let f of s.fields)
        this.suggestParams(f);
    }
    
  }
  addField(){
    
    let f={};
    f['id_parent']=this.sections[this.tabsection].id;

    if(this.sections[this.tabsection].fields==null || this.sections[this.tabsection].fields == undefined){
      this.sections[this.tabsection].fields=[];
    }
    this.sections[this.tabsection].fields.push(f);
    


  }

  moveField(field,direction="up"){

    for(let i=0;i<this.sections[this.tabsection].fields.length;i++){
     
      if(field==this.sections[this.tabsection].fields[i]){
        if(direction=="up"){
          if(i>0)
            this.sections[this.tabsection].fields = arrayMoveImmutable(this.sections[this.tabsection].fields, i, i-1);
            
        }
        else
          if(i<this.sections[this.tabsection].fields.length-1)
            this.sections[this.tabsection].fields = arrayMoveImmutable(this.sections[this.tabsection].fields, i, i+1);
        
        return;
      }
      
    }

  }


  removeField(field){
    for(let i=0;i<this.sections[this.tabsection].fields.length;i++){
     
      if(field==this.sections[this.tabsection].fields[i]){
        this.sections[this.tabsection].fields.splice(i,1);
        return;
      }
    }
  }

  duplicateField(field){
    let new_field={};
    Object.assign(new_field,field);
    new_field['id']=0;
    this.sections[this.tabsection].fields.push(new_field);
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }
  
  
  addSection(){
    if(!this.sections){
      this.sections=[];
    }

    if(!this.sections[this.tabsection].fields){
      this.sections[this.tabsection].fields=[];
    }
    
    let name_section=prompt("Inserisci il nome della sezione","");

    let s={};
    s['id']=0;
    s['type']="section";
    s['name']=name_section;
    s['label']=name_section;
    s['fields']=[];
   
    
    
    this.sections.push(s);
    

  }


  removeSection(s){

    if(confirm("Sicuro di voler eliminare la sezione?")){
      const index: number = this.sections.indexOf(s);
      this.sections.splice(index,1);
    }    

  }

  moveToSection(r){
    //aggiungi il campo alla nuova sezione
    for(let section of this.sections){
      if(section.id==r.id_parent){
        section.fields.push(r);
      }
    }

    //togli il campo dalla vecchia sezione
    for(let section of this.sections){
      for(let i=0;i<section.fields.length;i++){
        if(section.fields[i]==r && section.id!=r.id_parent){
          section.fields.splice(i,1);
          return;
        }
      }
    }
  }


  suggestParams(field){
    switch(field.type){
      case "select":
        if(field.params=="" || field.params==null || field.params==undefined)
          field.params=[];
        break;
      case "file":
        if(field.params=="" || field.params==null || field.params==undefined)
          field.params=this.default_file_params;
        else
          field.params=JSON.parse(field.params);
        break;
      case "photo":
        if(field.params=="" || field.params==null || field.params==undefined)
          field.params= this.default_photo_params;
        else
          field.params=JSON.parse(field.params);
        break;
      default:
        if(field.params=="" || field.params==null || field.params==undefined)
          field.params= [];
        break;
    }
  }

  deleteSection(section){
    if(confirm("Eliminare la sezione e tutti i campi collegati?")){
      for(let i=0;i<this.sections.length;i++){
        if(this.sections[i]==section){
          this.sections.splice(i,1);
          return;
        }
      }
    }
  }


  renameSection(section){
    let name=prompt("Indicare il nome della sezione",section.name);
    if(name!="")
      section.name=name;
      section.label=name;
  }


  addValueToParams(field){
    if(!field.params){
      field.params=[];
    }else{
      field.params=field.params.split(";");
    }
    let value=prompt("Indicare il valore dell'elemento","");
    if(value){
      field.params.push(value);
      field.params=field.params.join(";");
    }
  }

  removeValueFromParams(field){
    if(!field.params){
      field.params=[];
    }else{
      field.params=field.params.split(";");
    }

    for(let i=0;i<field.params.length;i++){
      if(field.params[i]==this.param_selected){
        field.params.splice(i,1);
        this.param_selected="";
        field.params=field.params.join(";");
        return;
      }
    }
  }
}
