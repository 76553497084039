import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { FinancialAccount } from '../db/financialaccount';

@Injectable({
  providedIn: 'root'
})
export class FinancialAccountsService extends WSService<FinancialAccount> {

  constructor() { super("financialaccounts")}

  getAccounts():Observable<any[]>{
    return Globals.ws.requestUrl("financialaccounts","getAccounts",[]);

  }

  getAnalytics(date_from,date_to):Observable<any[]>{
    return Globals.ws.requestUrl("financialaccounts","getAnalytics",[date_from,date_to]);
  }
}
