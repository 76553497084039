
<ul class="small-box list-group {{class}}">

    <li class="list-group-item px-0" [class.active]="date_type==99">

        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
                <span class="input-group-text"><small>da</small></span>
            </div>
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value" name="date_from" (focusout)="update()" (key.enter)="update()" >
        </div>
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
                <span class="input-group-text"><small>&ensp;a</small></span>
            </div>
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value2"  name="date_to" (focusout)="update()" (key.enter)="update()">
        </div>
    </li>
    
    <li class="d-none d-lg-block list-group-item" [class.active]="date_type==100" (click)="setDate(100)" style="cursor:pointer;">Tutto</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="past" [class.active]="date_type==6" (click)="setDate(6)" style="cursor:pointer;">Dall'inizio</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="past" [class.active]="date_type==5" (click)="setDate(5)" style="cursor:pointer;">Ultimi 90 gg</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="past" [class.active]="date_type==4" (click)="setDate(4)" style="cursor:pointer;">Ultimi 30 gg</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="past" [class.active]="date_type==3" (click)="setDate(3)" style="cursor:pointer;">Ultimi 7 gg</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="past" [class.active]="date_type==2" (click)="setDate(2)" style="cursor:pointer;">Ieri</li>
    <li class="d-none d-lg-block list-group-item" [class.active]="date_type==1" (click)="setDate(1)" style="cursor:pointer;">Oggi</li>  
    <li class="d-none d-lg-block list-group-item" *ngIf="future" [class.active]="date_type==7" (click)="setDate(7)" style="cursor:pointer;">Domani</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="future" [class.active]="date_type==8" (click)="setDate(8)" style="cursor:pointer;">Fino a 7 gg</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="future" [class.active]="date_type==9" (click)="setDate(9)" style="cursor:pointer;">Fino a 30 gg</li>
    <li class="d-none d-lg-block list-group-item" *ngIf="future" [class.active]="date_type==10" (click)="setDate(10)" style="cursor:pointer;">Fino a 90 gg</li>
    
    <li class="d-none d-lg-block list-group-item" *ngIf="future" [class.active]="date_type==11" (click)="setDate(11)" style="cursor:pointer;">Tutti i successivi</li>
    <li class="d-none d-lg-block list-group-item" [class.active]="date_type==98" (click)="date_type==98"><span (click)="setDate(98)" style="cursor:pointer;">Anno<i *ngIf="date_type!=98" class="fas fa-chevron-down ml-1"></i></span>
        <div *ngIf="date_type==98">
            <select class="form-control form-control-sm" [(ngModel)]="year" name="year" (change)="setDate(98);">
                <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
            </select>
        </div>
    </li>

    <li class="d-none d-lg-block list-group-item" [class.active]="date_type==97" (click)="date_type==97"><span (click)="setDate(97)" style="cursor:pointer;">Mese<i *ngIf="date_type!=97" class="fas fa-chevron-down ml-1"></i></span>
        <div *ngIf="date_type==97">
            <select class="form-control form-control-sm" [(ngModel)]="month" name="month" (change)="setDate(97);">
                <option [ngValue]="0">gennaio</option>
                <option [ngValue]="1">febbraio</option>
                <option [ngValue]="2">marzo</option>
                <option [ngValue]="3">aprile</option>
                <option [ngValue]="4">maggio</option>
                <option [ngValue]="5">giugno</option>
                <option [ngValue]="6">luglio</option>
                <option [ngValue]="7">agosto</option>
                <option [ngValue]="8">settembre</option>
                <option [ngValue]="9">ottobre</option>
                <option [ngValue]="10">novembre</option>
                <option [ngValue]="11">dicembre</option>
            </select>
        </div>
    </li>

<!--
    <li class="list-group-item" [class.active]="date_type==99" (click)="setDate(99)" style="cursor:pointer;">Personalizza
        <div *ngIf="date_type==99">
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value" name="date_from"  >
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value2" >
            <button class="btn btn-sm btn-secondary mt-1 float-right" (click)="update()">Applica</button>
        </div>
    </li>
-->

    <div class="d-none d-lg-block icon"><i class="far fa-calendar-alt"></i></div>
</ul>
