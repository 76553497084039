<app-windowlist [model]="this"> 
                <div class="row">
                    <div *ngIf="isModuleEnabled('shops')" class="col-lg-2 pr-md-0" >
                        <small>
                            
                            <ul class="card small-box list-group treeviewlist p-1 " *ngIf="user.isAdmin() ">
                                <li class="list-group-item text-truncate" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()">{{s.name}}</li>
                                <li class="list-group-item"  [class.active]="filter_created_from.value=='0'" (click)="filter_created_from.value='0';getItems()">Altro</li>
                                <div class="icon"><i class="fa fa-store-alt "></i></div>
                            </ul>
                        </small>
                    </div>
                    <div class="col-lg-10" >
                        <div class="card card-outline p-2">
                            <div class="form-group row mt-2">
                                <div class="col-3 text-right"><label>Giorno</label></div>
                                <div class="col-6"><input type="date" class="form-control form-control-sm" [(ngModel)]="date" (change)="getItems()"> </div>
                            </div>
                            <div class="row" *ngIf="list">
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-header bg-success">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h5>Ingresso</h5>
                                                </div>
                                                <div class="col-6">
                                                    <h5 class="text-right">+{{list['total_in'] | currency:"&euro;"}}</h5>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="card-body bg-light" *ngIf="list['in']">
                                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                                <thead class="bg-light">
                                                    <th>Metodo di pagamento</th>
                                                    <th class="text-right">Importo</th>
                                                </thead>
                                                <tbody >
                                                    <tr *ngFor="let r of list['in']['methodpayment']">
                                                        <td>{{r.method_payment}}</td>
                                                        <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="bg-info text-white">
                                                    <tr>
                                                        <td>TOTALE</td>
                                                        <td class="text-right"><b>{{list['in']['total_methodpayment'] | currency:"&euro;"}}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                
                                            <hr/>
                
                                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                                <thead class="bg-light">
                                                    <th>Documento</th>
                                                    <th class="text-right">Importo</th>
                                                </thead>
                                                <tbody >
                                                    <tr *ngFor="let r of list['in']['documents']">
                                                        <td>
                                                            
                                                            {{getTypeDocument(r.typedocument)}}
                
                
                
                                                        </td>
                                                        <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="bg-info text-white">
                                                    <tr>
                                                        <td>TOTALE</td>
                                                        <td class="text-right"><b>{{list['in']['total_documents'] | currency:"&euro;"}}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-header bg-danger">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h5 >Uscita</h5>
                                                </div>
                                                <div class="col-6">
                                                    <h5 class="text-right">-{{list['total_out'] | currency:"&euro;"}}</h5>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="card-body bg-light" *ngIf="list['out']">
                                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                                <thead class="bg-light">
                                                    <th>Metodo di pagamento</th>
                                                    <th class="text-right">Importo</th>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let r of list['out']['methodpayment']">
                                                        <td>{{r.method_payment}}</td>
                                                        <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="bg-info text-white">
                                                    <tr>
                                                        <td>TOTALE</td>
                                                        <td class="text-right"><b>{{list['out']['total_methodpayment'] | currency:"&euro;"}}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                
                                            <hr/>
                
                                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                                <thead class="bg-light">
                                                    <th>Documento</th>
                                                    <th class="text-right">Importo</th>
                                                </thead>
                                                <tbody *ngIf="list['out'].length>0">
                                                    <tr *ngFor="let r of list['out']['documents']">
                                                        <td>
                                                            
                                                            {{getTypeDocument(r.typedocument)}}
                
                
                
                                                        </td>
                                                        <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="bg-info text-white">
                                                    <tr>
                                                        <td>TOTALE</td>
                                                        <td class="text-right"><b>{{list['out']['total_documents'] | currency:"&euro;"}}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row mt-2">
                                <div class="col-3 d-none d-lg-block"></div>
                                <div class="col-12 col-lg-6">
                                    <div class="info-box bg-light">
                                        <span class="info-box-icon bg-gray elevation-1" [class.bg-success]="list['total']>0" [class.bg-danger]="list['total']<0">
                                            <i class="fas fa-euro-sign"></i></span>
                                        <h4 class="info-box-content mb-0">
                                          <span>Saldo: <b  [class.text-danger]="list['total']<0">{{list['total'] | currency:"&euro;"}}</b></span>
                                        </h4>
                                      </div>
                                    <!-- <div class="alert alert-light text-center">
                                        <h4 >Saldo: <span [class.text-success]="list['total']>0" [class.text-danger]="list['total']<0" >{{list['total'] | currency:"&euro;"}}</span></h4>
                                    </div> -->
                                </div>
                                <div class="col-3 d-none d-lg-block"></div>
                            </div>
                        </div>
                    </div>
                </div>
</app-windowlist>