import {Observable, of} from 'rxjs';
import { Globals } from '../globals';
import {Filter,FilterMode,FilterAdvanced,Ordering,OrderingMode} from './structures'


export interface requestList<T>{
    value:T[];
    paging_start:number;
    paging_count:number;
    count:number;
    ids:number[];
}


export class WSService<T>{

    public lastSQLWhere:string;
    public lastSQLOrder:string;
    public lastSQLAuxWhere:string
    public default_paging_count=10;


    constructor(public table:string,
       
        
    ){};

    getSQLWhere(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]=[]){
        let where=[];
        
        if(filters.length>0){
            for (let filter of filters) {
                if(!filter.enabled)
                    continue;


                let where_field=[];
                let nullvalue="";
                if(filter.nullvalue!=undefined)
                    nullvalue=filter.nullvalue

                if(filter.value!=nullvalue  && filter.value!=undefined && filter.value!=null){
                    if(filter.mode==FilterMode.custom){
                        where_field.push(filter.value);
                    }

                    //Ricerca per campo ID

                    let searchId=-1;
                    try{
                        searchId=filter.value.indexOf("id:");
                    }catch{
                        searchId=-1;
                    }
                    if(searchId>-1){
                        let s=filter.value.split(":");
                        where_field.push("idkey="+s[1]);
                    }


                    //Ricerca per campo ID di tabella
                    //verifica se la query di ricerca ha delle keyword id_
                    try{
                        searchId=filter.value.indexOf("id_");
                        if(searchId==-1)
                            searchId=filter.value.indexOf("id?");
                        
                    }catch{
                        searchId=-1;
                    }
                    if(searchId>-1){
                        filter.value=filter.value.split('?').join('_');
                        filter.value=filter.value.split('ç').join(':');
                        filter.value=filter.value.split('§').join('|');
                        
                        
                        
                        //verifica se ci sono campi multipli
                        let m=filter.value.split("|");
                        for(let mi of m){
                            let s=mi.split(":");
                            //controlla se la tabella di riferimento è corretta
                            let t=s[0].split("_");
                            if(t[1]==this.table){
                                where_field.push("idkey="+s[1]);
                            }

                            
                        }


                       
                    }


                    else{
                        for(let field of filter.fields){

                            if(filter.mode==FilterMode.normal){
                                where_field.push(field+"='"+filter.value+"'");
                            }

                            if(filter.mode==FilterMode.not_ugual){
                                where_field.push(field+"!='"+filter.value+"'");
                            }

                            if(filter.mode==FilterMode.fulltext){
                                //divide la frase con gli spazi
                                let words=filter.value.trim().split(" ");
                                let words_filtered=[];
                                for(let i=0;i<words.length;i++){
                                    if(words[i]!="")
                                        words_filtered.push("+"+words[i].replace("+"," ").replace("-"," ")+"*");
                                }
                                where_field.push("MATCH("+filter.field_fulltext+") AGAINST ('"+words_filtered.join(" ")+"' IN  BOOLEAN MODE)");
                            }

                            if(filter.mode==FilterMode.like){
                                where_field.push(field+" LIKE '%"+filter.value+"%'");
                            }

                            if(filter.mode==FilterMode.date){
                                if(filter.value2!=undefined)
                                    where_field.push(field+" BETWEEN '"+filter.value+" 00:00:00' AND '"+filter.value2+" 23:59:59'");
                                else
                                    where_field.push(field+" >= '"+filter.value+"'");
                                
                            }

                            if(filter.mode==FilterMode.between){
                                if(filter.value2!=undefined)
                                    where_field.push(field+" BETWEEN "+filter.value+" AND "+filter.value2);
                            }

                            if(filter.mode==FilterMode.minmax){
                                where_field.push(field+" >= "+filter.value+" AND "+field+" <= "+filter.value2);
                            }

                            if(filter.mode==FilterMode.major){
                                if(filter.valueIsField)
                                    where_field.push(field+" > "+filter.value);
                                else
                                    where_field.push(field+" > '"+filter.value+"'");
                            }

                            if(filter.mode==FilterMode.minor){
                                if(filter.valueIsField)
                                    where_field.push(field+" < "+filter.value);
                                else
                                    where_field.push(field+" < '"+filter.value+"'");
                            }

                            

                            if(filter.mode==FilterMode.majorequal){
                                if(filter.valueIsField)
                                    where_field.push(field+" >= "+filter.value);
                                else
                                    where_field.push(field+" >= '"+filter.value+"'");
                            }

                            if(filter.mode==FilterMode.minorequal){
                                if(filter.valueIsField) 
                                    where_field.push(field+" <="+filter.value);
                                else
                                    where_field.push(field+" <='"+filter.value+"'");
                            }
                            

                            if(filter.mode==FilterMode.contain){
                                if(Array.isArray(filter.value)){
                                    let where_in:string[]=[]
                                    for(let k=0;k<filter.value.length;k++){
                                        where_in.push("'"+filter.value[k]+"'");
                                    }

                                    where_field.push(field+" IN ("+where_in.join(',')+")");
                                }
                                    
                                else
                                    where_field.push(field+" IN ("+filter.value+")");
                            
                            }

                            if(filter.mode==FilterMode.not_contain){
                                if(Array.isArray(filter.value)){
                                    let where_in:string[]=[]
                                    for(let k=0;k<filter.value.length;k++){
                                        where_in.push("'"+filter.value[k]+"'");
                                    }

                                    where_field.push(field+" NOT IN ("+where_in.join(',')+")");
                                }
                                    
                                else
                                    where_field.push(field+" NOT IN ("+filter.value+")");
                            
                            }

                            

                        }
                    }

                    if(filter.field_fulltext!=undefined && filter.field_fulltext!=""){
                        let words=filter.value.trim().split(" ");
                        let words_filtered=[];
                        for(let i=0;i<words.length;i++){
                            if(words[i]!="")
                                words_filtered.push("+"+words[i].replace("+"," ").replace("-"," ")+"*");
                        }
                        where_field.push("MATCH("+filter.field_fulltext+") AGAINST ('"+words_filtered.join(" ")+"' IN  BOOLEAN MODE)");
                    }

                    if(where_field.length>0)
                        where.push("("+where_field.join(' OR ')+")");
                }
                
            }
        }

        if(advancedFilter.length>0){
            let where_af:string=""
            for(let i=0;i<advancedFilter.length;i++){

                let value_af:string;
                switch(advancedFilter[i].condition){
                    case "ugual":
                        value_af="`value`='"+advancedFilter[i].value+"'";
                        break;
                    case "minor":
                        value_af="`value` < '"+advancedFilter[i].value+"'";
                        break;
                    case "minorugual":
                        value_af="`value` <= '"+advancedFilter[i].value+"'";
                        break;
                    case "major":
                        value_af="`value` > '"+advancedFilter[i].value+"'";
                        break;
                    case "majorugual":
                        value_af="`value` >= '"+advancedFilter[i].value+"'";
                        break;


                        
                    case "like":
                        value_af="`value` LIKE '%"+advancedFilter[i].value+"%'";
                        break;
                    case "unlike":
                        value_af="`value` NOT LIKE '%"+advancedFilter[i].value+"%'";
                        break;

                    case "null":
                        value_af="`value` = ''";
                        break;

                    case "notnull":
                        value_af="NOT `value` = ''";
                        break;
                
                            
                }


                where_af=where_af+" "+(i>0?advancedFilter[i-1].nextCondition:"")+" SELECT id_table FROM customfieldvalues WHERE (`id_customfield`='"+advancedFilter[i].customfield.id+"' AND "+value_af+" AND `table`='"+advancedFilter[i].table+"')"
            }

            where.push(advancedFilter[0].id_field +" IN  ("+where_af+")")
        }

        //imposta i parametri di ordinazione
        let order:string[]=[];
        if(ordering!=null)
            for(var i=0;i<ordering.length;i++)
                if(ordering[i].field!="")
                    order.push(ordering[i].field+" "+ordering[i].mode);


       

     return  [where.join(" AND "),order.join(", ")];
    };


    getItems(filters:Filter[],ordering:Ordering[],paging_start:number=0,paging_count:number=this.default_paging_count,aux_parameter:string="",advancedFilter:FilterAdvanced[]=[],groupby:string="",other_params=[]):Observable<requestList<T>>{
       
      
       [this.lastSQLWhere,this.lastSQLOrder]=this.getSQLWhere(filters,ordering,advancedFilter);
        
        this.lastSQLAuxWhere=aux_parameter;

       let args:string[]=[];
       args.push(this.lastSQLWhere);
       args.push(this.lastSQLOrder);
       if(groupby)
           args.push(groupby);

       for(let p of other_params)
            args.push(p);    

        if(aux_parameter!="")
            args.push(aux_parameter);
        
     return  Globals.ws.requestUrl<requestList<T>>(this.table,"getItems",args,false,paging_start,paging_count);
    };

    getItem(id:number,fullParams=true):Observable<T>{
        return  Globals.ws.requestUrl<T>(this.table,"getItem",[id.toString(),fullParams.toString()],true);
    };




  getOfflineItems():T[]{
    let json=localStorage.getItem(this.table+"Items");
    if(json!=""){
      return JSON.parse(json);
    }

    return null;
  }

  getOfflineItem(id:number):T{
    let items:T[]=this.getOfflineItems();
    for(let item of items){
      if(item['id']==id)
        return item;
    }

    return null;

  }


    save(item:T,return_id:Function,checkdeadlinesandactions=true,showAfterMessage=true){
        Globals.loading=true;
        return Globals.ws.send(this.table,"save",item,[],(id:number)=>{
            Globals.loading=false;
            return_id(id);
          },showAfterMessage?"Salvataggio effettuato correttamente":"");
    };

    saveMultiple(items:T[],oncomplete,showAfterMessage=true){
        return Globals.ws.send(this.table,"saveMultiple",items,[],(id:number)=>{
            
            oncomplete();
          },showAfterMessage?"Salvataggio effettuato correttamente":"");
    }

    updateAny(item:Object,ids:string[],oncomplete=null){

        return Globals.ws.send(this.table,"updateAny",item,[ids.join(",")],(id:number)=>{
            if(oncomplete!=null)
                oncomplete(true);
        });
    };

    restore(item:Object,ids:string[],oncomplete=null){

        return Globals.ws.send(this.table,"restore",item,[ids.join(",")],(id:number)=>{
            if(oncomplete!=null)
                oncomplete(true);
        });
    };

    trash(item:Object,ids:string[],oncomplete=null){

        return Globals.ws.send(this.table,"trash",item,[ids.join(",")],(id:number)=>{
            if(oncomplete!=null)
                oncomplete(true);
        });
    };


    delete(ids:string[]):Observable<T>{
        return  Globals.ws.requestUrl<T>(this.table,"delete",[ids.join(",")]);
    };

    deleteSQL(where:string):Observable<T>{
        return  Globals.ws.requestUrl<T>(this.table,"deleteSQL",[where]);
    };


    search(text:string):Observable<T>{
        return  Globals.ws.requestUrl<T>(this.table,"search",[text]);
    }

}