<app-window [title]="record.title+' | '+record.subtitle">
    <form role="form" id="detailForm">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tabT2" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-edit"></i><span class="d-none d-sm-inline-block ml-2">Descrizione</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabT3" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-images"></i><span class="d-none d-sm-inline-block ml-2">Immagini</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabT4" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Calendario</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabT6" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Prodotti inclusi</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabT5" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fab fa-diaspora"></i><span class="d-none d-sm-inline-block ml-2">Caratteristiche</span></a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabT7" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fab fa-diaspora"></i><span class="d-none d-sm-inline-block ml-2">Costi</span></a>
            </li>
        </ul>

        <div class="p-2">
            <div class="tab-content" id="myTabContent">
                <!-- GENERAL-->
                <div class="tab-pane fade show active" id="tabT2" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="form-group row">
                        <div class="col-md-2"><label>Titolo</label></div>
                        <div class="col-md-6">
                            <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="record.title" />
                        </div>
                        <div class="col-md-2 text-md-right"><label>Codice</label></div>
                        <div class="col-md-2">
                            <input type="text" readonly="readonly" name="code" class="form-control form-control-sm" [(ngModel)]="record.code" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2"><label>Sotto titolo</label></div>
                        <div class="col-md-10">
                            <input type="text" name="subtitle" class="form-control form-control-sm" [(ngModel)]="record.subtitle" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2"><label>Tipologia</label></div>
                        <div class="col-md-10">
                            <app-type [mode]="2" [id_type]="record.id_type" [table]="'tour'" [record]="record" ></app-type>
                          
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2"><label>Introduzione</label></div>
                        <div class="col-md-10">
                            <ckeditor *ngIf="editorOnIntro" [config]="{allowedContent:true}" name="intro" tagName="intro" [editor]="EditorIntro" [(ngModel)]="record.intro"></ckeditor>
                            
                            <textarea *ngIf="!editorOnIntro" rows="20"  name="intro" class="form-control form-control-sm" [(ngModel)]="record.intro"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2"><label>Descrizione</label></div>
                        <div class="col-md-10">
                            <ckeditor *ngIf="editorOnDescription" [config]="{allowedContent:true}" name="description" tagName="description" [editor]="EditorDescription" [(ngModel)]="record.description"></ckeditor>
                            
                            <textarea *ngIf="!editorOnDescription" rows="20"  name="description" class="form-control form-control-sm" [(ngModel)]="record.description"></textarea>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <div class="col-md-2"><label>Partenza</label></div>
                        <div class="col-md-6">
                            <app-selectfieldcustom [showSearchButton]="true"  (OnExplore)="selectShop()" [recordSelected]="record.shop" [service]="shopsService"  [fieldRender]="'name'" [class]="'form-control form-control-sm'" (OnRecordSelected)="OnSelectShop($event)"></app-selectfieldcustom>
                            <div class="alert alert-info" *ngIf="record.shop">
                                <i class="fa fa-store-alt mr-2"></i><small>{{record.shop.address}}, {{record.shop.city}}</small>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <div class="col-md-2"><label>Visualizza</label></div>
                        <div class="col-md-4">
                           <select class="form-control form-control-sm" name="type" [(ngModel)]="record.type">
                               <option value="1">Dappertutto</option>
                               <option value="2">Solo sul backend</option>
                               <!-- <option value="3">Solo sul frontend</option> -->
                               
                           </select>
                        </div>
                    </div>
                </div>
                <!-- IMAGES -->
                <div class="tab-pane fade" id="tabT3" role="tabpanel" aria-labelledby="tab3-tab">
                    <div class="row">
                        <div class="col-md-3 text-center mt-3" *ngFor="let i of record.images" >
                            <a href="{{i.imagePath}}" target="_blank" data-toggle="tooltip" title="Visualizza">
                                <img src="{{i.imagePath}}" class="img-thumbnail" style="max-height:200px;" >
                            </a>
                            <br>
                            <button style="margin-top:-50px" data-toggle="tooltip" title="Elimina" (click)="removeImage(i)" class="btn btn-sm text-danger"><i class="fa fa-trash mr-1"></i></button>
                        </div>
                        <div class="col-md-3 text-center mt-1">
                            <button class="btn btn_add_photo btn-default mt-5" (click)="fileToUpload.click()"><i class="fa fa-3x fa-plus"></i><br/>Aggiungi immagine</button>
                        </div>
                        <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
                    </div>
                    <hr>
                    <div class="m-3"><h5 class="text-info d-inline"><i class="icon fas fa-info mr-2"></i></h5><i>La prima immagine sarà utilizzata come immagine di copertina. </i></div>
                </div>
                <!-- CALENDARIO -->
                <div class="tab-pane fade" id="tabT4" role="tabpanel" aria-labelledby="tab4-tab">
                    <div class="row" *ngIf="record.id">
                        <div class="col-12">
                            <table class="table table-sm">
                                <thead>
                                    <th>Periodo</th>
                                    <th class="d-none d-sm-table-cell">Orari</th>
                                    <th class="d-none d-sm-table-cell">Min posti</th>
                                    <th class="d-none d-sm-table-cell">Max posti</th>
                                    <!--<th class="d-none d-sm-table-cell">Prezzo adulti</th>
                                    <th class="d-none d-sm-table-cell">Prezzo bambini</th>-->
                                    <th class="d-none d-sm-table-cell">Partenza</th>
                                    <th class="d-none d-sm-table-cell">Magazzino</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let d of record.dates">
                                        <td>{{d.date_start | date:"dd/MM/yyyy"}} - {{d.date_end | date:"dd/MM/yyyy"}}<br/>
                                            ogni: <b>{{replaceDaysOfWeek(d.days_of_week)}}</b>
                                        </td>
                                        <td class="d-none d-sm-table-cell">{{d.time_start}} - {{d.time_end}}</td>
                                        <td class="d-none d-sm-table-cell">{{d.min_quantity}}</td>
                                        <td class="d-none d-sm-table-cell">{{d.quantity}}</td>
                                        <!--<td class="d-none d-sm-table-cell">{{d.price_adult | currency:"&euro;"}}</td>
                                        <td class="d-none d-sm-table-cell">{{d.price_children | currency:"&euro;"}}</td>-->
                                        <td class="d-none d-sm-table-cell">{{d.location_start}}</td>
                                        <td class="d-none d-sm-table-cell">{{d.shop_start_name}}</td>
                                        <td>
                                            <button class="btn btn-sm btn-secondary float-right ml-1" data-toggle="tooltip" title="Modifica" (click)="editDate(d);"><i class="fa fa-pencil-alt"></i></button>
                                            <button class="btn btn-sm btn-danger float-right ml-1" data-toggle="tooltip" title="Elimina" (click)="removeDate(d);"><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-sm btn-success m-1" (click)="addDates()"><i class="fa fa-plus"></i><span class="d-none d-sm-inline-block ml-1">Aggiungi periodo</span></button>
                            <button type="button" class="btn btn-sm btn-danger m-1 float-right" (click)="clearAllDates()"><i class="fa fa-trash"></i><span class="d-none d-sm-inline-block ml-1">Elimina tutti i periodi</span></button>
                        </div>
                    </div>
                    <hr>
                    <div class="m-4"  *ngIf="record.id==0">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Salvare il record per poter inserire le date nel calendario.</i>
                    </div>
                </div>
                <!-- COMPOSIZIONE -->
                <div class="tab-pane fade" id="tabT6" role="tabpanel" aria-labelledby="tab6-tab">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-sm">
                                <thead>
                                    <th>Prodotto</th>
                                    <th>Quantità</th>
                                    <!-- <th>Costo</th> -->
                                    <!-- <th>Prezzo</th> -->
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of record.details">
                                        <td><app-productitem [product]="r.product" [showNotes]="false"></app-productitem></td>
                                        <td style="max-width:2rem"><br><input class="form-control form-control-sm" type="number" step="1" min="1" name="quantity_{{r.id_product}}" [(ngModel)]="r.quantity"></td>
                                        <!-- <td><br><input class="form-control form-control-sm" type="number" step="0.01" min="0" name="cost_{{r.id_product}}" [(ngModel)]="r.cost"></td> -->
                                        <!-- <td><br><input class="form-control form-control-sm" type="number" step="0.01" min="0" name="price_{{r.id_product}}" [(ngModel)]="r.price"></td> -->
                                        <td><br><button data-toggle="tooltip" title="Elimina" (click)="removeDetail(r)" class="btn btn-sm btn-danger float-right"><i class="fa fa-trash"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>

                            <button class="btn btn-sm btn-success" (click)="addDetail()"><i class="fa fa-plus"></i><span class="d-none d-sm-inline-block ml-1">Aggiungi prodotto</span></button>
                        </div>
                    </div>
                </div>
                <!-- CARATTERISTICHE -->
                <div class="tab-pane fade" id="tabT5" role="tabpanel" aria-labelledby="tab5-tab">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-sm">
                                <thead>
                                    <th>Caratteristica</th>
                                    <th>Icona</th>
                                    <th></th>
                                    <th>Valore</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let f of this.record.features">
                                        <td><input type="text" class="form-control form-control-sm" name="feature_{{f.id}}" [(ngModel)]="f['feature']"/> </td>
                                        <td><input type="text" class="form-control form-control-sm" name="icon_{{f.id}}" [(ngModel)]="f['icon']"/></td>
                                        <td><img class="bg-secondary" src="https://new.hitinero.com/assets/icons/{{f['icon']}}.svg" height="30" /></td>
                                        <td><input type="text" class="form-control form-control-sm" name="value_{{f.id}}" [(ngModel)]="f['value']"/> </td>
                                        <td>
                                            <button class="btn btn-sm btn-danger float-right" (click)="deleteFeature(f)"><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn btn-sm btn-success" (click)="addFeature()"><i class="fa fa-plus"></i><span class="d-none d-sm-inline-block ml-1">Aggiungi caratteristica</span></button>
                        </div>

                    </div>
                </div>
                <!-- COSTI -->
                <div class="tab-pane fade" id="tabT7" role="tabpanel" aria-labelledby="tab5-tab">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-sm">
                                <thead>
                                    <th>Descrizione</th>
                                    <th>Quantità</th>
                                    <th>UM</th>
                                    <th>Costo unitario</th>
                                    <th>Totale</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let c of record.costs;let i=index">
                                        <td><input type="text" class="form-control form-control-sm" name="{{i}}_description" [(ngModel)]="c.description"></td>
                                        <td><input type="number" class="form-control form-control-sm" name="{{i}}_quantity" [(ngModel)]="c.quantity"></td>
                                        <td><input type="text" class="form-control form-control-sm" name="{{i}}_unit" [(ngModel)]="c.unit"></td>
                                        <td><input type="text" class="form-control form-control-sm" name="{{i}}_cost" [(ngModel)]="c.cost"></td>
                                        <td><input type="text" readonly="readonly" class="form-control form-control-sm" name="{{i}}_total" value="{{c.cost*c.quantity}}" ></td>
                                        <td>
                                            <button class="btn btn-danger btn-sm" (click)="removeCost(c);"><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="4">TOTALE</td>
                                    <td>{{total_cost | currency:" &euro;"}}</td>
                                </tfoot>
                            </table>
                            <button class="btn btn-sm btn-success" (click)="addCost()"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    </form>
</app-window>

<app-toolbarmodel [model]="this"></app-toolbarmodel>


<ng-template #modalAddCalendar>
    <div class="modal-header py-1 bg-light">
        <b>Crea periodo</b>
        <button type="button" class="btn btn-tool" (click)="closeModalAddCalendar()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
            <div class="row mb-2">
                <div class="col-lg-2"><label>Dal</label></div>
                <div class="col-lg-3">
                    <input type="date" [readonly]="is_calendar_edit" class="form-control form-control-sm" name="calendar_from" [(ngModel)]="calendar_from">
                </div>
                <div class="col-lg-4 text-md-right"><label>Al</label></div>
                <div class="col-lg-3">
                    <input type="date" [readonly]="is_calendar_edit" class="form-control form-control-sm" name="calendar_to" [(ngModel)]="calendar_to">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2"><label>Orario inizio</label></div>
                <div class="col-lg-3">
                    <input type="text" class="form-control form-control-sm" name="calendar_time_start" [(ngModel)]="time_start">
                </div>
                <div class="col-lg-4 text-lg-right"><label>Orario Fine</label></div>
                <div class="col-lg-3">
                    <input type="text" class="form-control form-control-sm" name="calendar_time_end" [(ngModel)]="time_end">
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-lg-2"><label>Giorni</label></div>
                <div class="col-lg-10">
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(1)" (click)="addOrRemoveDayWeek(1)" ><label>Lun</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(2)" (click)="addOrRemoveDayWeek(2)" ><label>Mar</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(3)" (click)="addOrRemoveDayWeek(3)"><label>Mer</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(4)" (click)="addOrRemoveDayWeek(4)"><label>Gio</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(5)" (click)="addOrRemoveDayWeek(5)"><label>Ven</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(6)" (click)="addOrRemoveDayWeek(6)"><label>Sab</label>
                    <input class="mx-2" type="checkbox" [checked]="checkDayWeek(0)" (click)="addOrRemoveDayWeek(0)"><label>Dom</label>
                    <button class="btn btn-xs btn-outline-secondary float-right ml-1" (click)="addAllDayWeek()">Seleziona tutto</button>
                    <button class="btn btn-xs btn-outline-secondary float-right ml-1" (click)="removeAllDayWeek()">Deseleziona tutto</button>
                </div>
            </div>

            <div class="row mb-2">

                <div class="col-12 mb-2">
                    <label>Prezzario</label>
                    <table class="table table-sm mb-0">
                        <thead>
                            <th>Da</th>
                            <th>A</th>
                            <th>Prezzo Adulto</th>
                            <th>Prezzo Bambino *</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let price of prices;let i=index;">
                                <td>
                                    <div class="input-group input-group-sm">
                                        <input type="number" class="form-control form-control-sm" name="{{i}}_from" [(ngModel)]="price.from" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">persona</span>
                                        </div>
                                    </div>
                                    
                                </td>
                                <td>
                                    <div class="input-group input-group-sm">
                                        <input type="number" class="form-control form-control-sm" name="{{i}}_to" [(ngModel)]="price.to" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">persona</span>
                                        </div>
                                    </div>
                                </td>
                                <td><input type="number" class="form-control form-control-sm" name="{{i}}_price_adult" [(ngModel)]="price.price_adult" /></td>
                                <td><input type="number" class="form-control form-control-sm" name="{{i}}_price_children" [(ngModel)]="price.price_children" /></td>
                                <td>
                                    <button class="btn btn-xs btn-danger" (click)="removePrice(price)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-success btn-xs m-1" (click)="addPrice()"><i class="fa fa-plus"></i>&nbsp;Aggiungi prezzo</button>
                    <small class="float-right pt-2">*<i> impostare 0, se il tour è solo per adulti.</i></small>
                </div>
                <!--
                <div class="col-md-2"><label>Prezzo adulti <small>(i.e.)</small></label></div>
                <div class="col-md-2"><input type="number" class="form-control form-control-sm" name="price_adult" [(ngModel)]="price_adult"></div>
                
                <div class="col-md-4 text-md-right"><label>Prezzo bambini <small>(i.e.)</small></label></div>
                <div class="col-md-2"><input type="number" class="form-control form-control-sm" name="price_children" [(ngModel)]="price_children"></div>
                <div class="col-md-2"></div>
                -->
            </div>

            <div class="row">
                <div class="col-lg-2"><label>Min posti</label></div>
                <div class="col-lg-2"><input type="number" class="form-control form-control-sm" name="min_quantity" [(ngModel)]="min_quantity"></div>
                <div class="col-lg-4 text-lg-right"><label>Max posti</label></div>
                <div class="col-lg-2"><input type="number" class="form-control form-control-sm" name="quantity" [(ngModel)]="quantity"></div>
            </div>
            <div class="row mt-2"> 
                <div class="col-lg-2"><label>Partenza da</label></div>
                <div class="col-lg-4">
                    <input type="text" class="form-control form-control-sm" name="location_start" [(ngModel)]="location_start">
                </div>
                <div class="col-lg-2 text-lg-right"><label>Magazzino di rif.</label></div>
                <div class="col-lg-4">
                    <select  class="form-control form-control-sm" name="id_shop_start" [(ngModel)]="id_shop_start">
                        <option value="{{s.id}}" *ngFor="let s of shops">{{s.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-success m-1" (click)="addDate()"><i class="fa fa-plus"></i><span class="d-none d-sm-inline-block ml-1"><span *ngIf="!is_calendar_edit">Crea date</span><span *ngIf="is_calendar_edit">Aggiorna date</span></span></button>
            <button type="button" class="btn btn-sm btn-secondary float-right m-1" (click)="closeModalAddCalendar()"><span class="d-none d-sm-inline-block ml-1">Annulla</span></button>
        </div>

</ng-template>