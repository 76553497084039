import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Installation } from 'projects/c1-backend/src/app/db/installation';
import { InstallationsService } from 'projects/c1-backend/src/app/services/installations.service';
import { ModelList } from 'projects/core/src/include/modellist';

@Component({
  selector: 'app-serials-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent extends ModelList<Installation> implements OnInit {

  constructor(
    private fb1: FormBuilder,
    private installationsService: InstallationsService
  ) { 
    super(installationsService,fb1);
  }

  ngOnInit(): void {
    this.installationsService.getAnalytics().subscribe((items)=>{
      this.list=items;
    })
  }

}
