<app-windowlist [model]="this">
    <div class="row">
        <div *ngIf="filterbox" class="col-lg-2 pr-lg-0">
            <small>
                <app-filterdate [future]="true" [date_type]="1" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <ul class="card small-box list-group treeviewlist p-1 mt-2 d-none d-lg-block">
                    <li class="list-group-item" [class.active]="filter_id_shop_start.value==''" (click)="filter_id_shop_start.value='';updateList()"style="cursor:pointer;">Tutti gli esercizi</li>
                    <li class="list-group-item text-truncate" *ngFor="let s of shops" [class.active]="filter_id_shop_start.value==s.id" (click)="filter_id_shop_start.value=s.id;getItems()"style="cursor:pointer;">{{s.name}}</li>
                    <div *ngIf="shops.length>2" class="icon"><i class="fa fa-store-alt "></i></div>
                </ul>
                <div class="card card-outline mb-0 p-1 d-block d-lg-none">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend input-group-prepend-sm">
                            <span class="input-group-text"><i class="fas fa-store-alt"></i></span>
                        </div>
                        <select class="form-control form-control-sm" name="filter_id_shop_start" [(ngModel)]="filter_id_shop_start.value" (ngModelChange)="getItems()">
                            <option value="">Tutti gli esercizi</option>
                            <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                        </select>
                    </div>
                </div>

                <ul class="card small-box list-group treeviewlist p-1 mt-2 d-none d-lg-block">
                    <li class="list-group-item" [class.active]="filter_type_address.value==''" (click)="filter_type_address.value='';updateList()"style="cursor:pointer;">Tutti</li>
                    <li class="list-group-item" [class.active]="filter_type_address.value=='0'" (click)="filter_type_address.value='0';updateList()"style="cursor:pointer;">Solo B2C</li>
                    <li class="list-group-item" [class.active]="filter_type_address.value=='1'" (click)="filter_type_address.value='1';updateList()"style="cursor:pointer;">Solo B2B</li>
                </ul>
                
                <ul class="card small-box list-group treeviewlist mt-2 mb-4 d-none d-lg-block" *ngIf="methodpayments.length>0" >
                    <li class="list-group-item" [class.active]="filter_method_payment.value==''" (click)="filter_method_payment.value='';getItems()" style="cursor:pointer;line-height: 1;">Tutte le modalità di pagamento</li>
                    <li class="list-group-item" [class.active]="filter_method_payment.value && filter_method_payment.value.indexOf(m.name)>-1" *ngFor="let m of methodpayments"  (click)="setFilterTypeValue('filter_method_payment',m.name);" style="cursor:pointer;line-height: 1;">{{m.name}}</li>
                    <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                </ul>

            </small>
        </div>
        <div [class.col-lg-12]="!filterbox" [class.col-lg-10]="filterbox" >
            <div class="card card-outline bg-light mb-4">
                <div class="p-0">
                    <ng-container *ngIf="filterbox">
                        <div class="row p-1">
                            <div class="col-lg-3">
                                <div class="input-group input-group-sm">
                                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per codice prenotazione o nome cliente..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                    <div class="input-group-append ">
                                        <button type="button" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="input-group input-group-sm">
                                    <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()">
                                        <option value="">Tutti gli operatori</option>
                                        <ng-container *ngFor="let u of users">
                                            <ng-container *ngIf="all_users">
                                                <option [class.text-gray]="!u.enabled" [ngValue]="u.id">{{u.name}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="!all_users">
                                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.name}}</option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                    <div class="input-group-append pl-1">
                                        <input class="" type="checkbox"  data-toggle="tooltip" title="Visualizza anche operatori disabilitati" (change)="all_users==true?all_users=false:all_users=true" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="input-group input-group-sm">
                                    <select class="form-control form-control-sm" name="filter_type" [(ngModel)]="filter_type.value" (change)="filter_id_type_tour.value='';getItems();">
                                        <option value="">Tutti i tipi</option>
                                        <option value="1">Noleggio</option>
                                        <option value="2">Tour</option>
                                        
                                    </select>
                                    <div class="input-append" *ngIf="filter_type.value==2">
                                        <select class="form-control form-control-sm" name="filter_id_type_tour" [(ngModel)]="filter_id_type_tour.value" (change)="getItems()">
                                            <option value="">Tutte le tipologie di tour</option>
                                            <option *ngFor="let t of type_tours" value="{{t.id}}" >{{t.name}}</option>
                                            
                                        </select>
                                    </div>
                                </div>
                                
                            </div>

                          
                            <div class="col-6 col-lg-2">
                                <div class="input-group input-group-sm" >
                                    <select class="custom-select" [(ngModel)]="filter_state.value" (ngModelChange)="updateList()">
                                        <option value="">Tutti gli stati</option>
                                        <option value="1">In attesa del pagamento</option>
                                        <option value="2">Confermato</option>
                                        <option value="6">Confermato (fornitore esterno)</option>
                                        <option value="3">Annullato</option>
                                        <option value="4">Check-in</option>
                                        <option value="5">Check-out</option>
                                    </select>
                                </div>
                            </div>

                            

                            <div class="col-6 col-lg-3 py-1">
                                <div class="dropdown dropleft show">
                                    <a class="btn btn-default btn-xs float-right" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu bg-light" aria-labelledby="dropdownMenuLink">
                                        <a type="button"   class="btn btn-xs dropdown-item" (click)="createDocument()">Crea documento</a>
                                    </div>
                                </div>
                                <!-- <button data-toggle="tooltip" title="Listino prezzi" (click)="openRentalPrices()" class="btn btn-default btn-xs float-right m-1"><i class="fas fa-cog"></i></button> -->
                                <button data-toggle="tooltip" title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1"><i class="far fa-trash-alt"></i></button>
                                <button data-toggle="tooltip" title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1"><i class="fas fa-trash"></i></button>
                                <button data-toggle="tooltip" title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1"><i class="fas fa-undo"></i></button>
                                <button data-toggle="tooltip" title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right ml-1"><i class="fas fa-file-export"></i></button>
                                <button data-toggle="tooltip" title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                                <button data-toggle="tooltip" title="Prenotazione guidata" (click)="openWizard()" class="btn btn-success text-yellow btn-xs float-right ml-1"><i class="fas fa-hat-wizard"></i></button>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="list==null" class="m-4">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>

                    <form [formGroup]="form" *ngIf="list!=null">
                        <table class="table table-sm">
                            <thead>
                                <th>
                                    <div #checkboxselectall class="float-left m-1 icheck-primary d-inline mr-3">
                                        <input  type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Cliente</th>
                                <th>Partenza</th>
                                <th>Costo</th>
                                <th class="text-center">Stato</th>
                                <th>Utente</th>
                                <th width="100px"></th>
                            </thead>
                            <thead>
                                <tr class="bg-white">
                                    <td></td>
                                    <td colspan="2"><b>TOTALE</b></td>
                                    <td [class.text-red]="total < 0">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td colspan="3"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of list">
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" data-toggle="tooltip" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1" [checked]="checkedAll"
                                        />&nbsp;
                                    </td>
                                    <td>
                                        <span (click)="openDetail(record)" *ngIf="record.addressItem" style="cursor: pointer">
                                            <h6 class="m-0 text-uppercase"><div *ngIf="record.type_address==1" class="badge bg-fuchsia"><small>B2B</small></div> {{record.addressItem.name}}</h6>
                                            <span class="d-none d-sm-inline">
                                                <ul class="list-group"  *ngIf="record.type_address==0">
                                                    <li class="list-group-item bg-light p-0" *ngFor="let c of record.addressItem.contacts">
                                                        <a href="c.type" ><small><i>{{c.value}}</i></small></a>
                                                    </li>
                                                </ul>
                                                <small *ngIf="record.type_address==1">
                                                    <b>{{record.clientname}}</b><br>
                                                    <i *ngIf="record.clientmail">{{record.clientmail}}<br/></i>
                                                    <i *ngIf="record.clientphone">{{record.clientphone}}<br/></i>
                                                    <i *ngIf="record.clientnote">{{record.clientnote}}<br/></i>
                                                </small>
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <small>
                                            <div *ngIf="record.id_tour==0">Noleggio</div>
                                            <div *ngIf="record.id_tour>0">Tour {{record.tour.type_tour}}</div>
                                        </small>
                                        <small>
                                            <b>{{record.shop_start.name}}</b><span *ngIf="record.id_shop_start!=record.id_shop_end"><i class="fas fa-long-arrow-alt-right"></i><b>{{record.shop_end.name}}</b></span><br/>
                                            <span data-toggle="tooltip" title="{{record.date_start | date:'EEEE'}}">{{record.date_start | date:'dd/MM/yyyy'}}</span>
                                            <span data-toggle="tooltip" title="{{record.date_end | date:'EEEE'}}" *ngIf="record.date_end!=record.date_start"> - {{record.date_end | date:'dd/MM/yyyy'}}</span><br>
                                            <span>{{record.time_start.substr(0,5)}} - {{record.time_end.substr(0,5)}}</span><br/>
                                            
                                            <i class="fa fa-male"></i>{{record.adults}}<span *ngIf="record.children!=0"><small><i class="fa fa-child ml-2"></i></small>{{record.children}}</span>
                                            <span class="ml-2 badge bg-gray">{{record.code}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <h5 [class.text-danger]="record.total-record.paid>0.1 && record.methodpayment!='omaggio'" class="text-success m-0"><b>{{record.total | currency:'&euro;'}}</b></h5>
                                        <small>
                                            <div *ngIf="record.methodpayment" [class.bg-danger]="record.total-record.paid>0 && record.methodpayment!='omaggio'" class="badge bg-success">{{record.methodpayment}}<br>
                                                <small *ngIf="record.tracking" class="bg-white font-weight-normal px-1">{{record.tracking}}</small>
                                            </div>
                                        </small><br>
                                        <ng-container *ngIf="record.methodpayment!='omaggio'">

                                        
                                            <small *ngIf="record.total-record.paid<=0" data-toggle="tooltip" title="{{record.paid | currency:'&euro;'}}" class="text-success"><b>pagato</b></small> 
                                            <small *ngIf="(record.total-record.paid>0) && (record.state!=1 && record.state!=3 && record.state!=6)" data-toggle="tooltip" title="pagato: {{record.paid | currency:'&euro;'}}" class="text-danger">da pagare:&nbsp;<b>{{record.total-record.paid | currency:'&euro;'}}</b></small><br>
                                            <small *ngIf="record.documents.length==0 && record.state!=6" data-toggle="tooltip" title="Nessun documento fiscale è associato alla prenotazione" ><i>Nessun documento associato!</i></small>
                                        </ng-container>
                                    </td>
                                    <td  class="text-center">
                                        <span class="badge badge-warning"   *ngIf="record.state==1">In attesa<br>del pagamento</span>
                                        <span class="badge badge-success"   *ngIf="record.state==2">Confermato</span>
                                        <span class="badge badge-danger"    *ngIf="record.state==3">Annullato</span>
                                        <span class="badge badge-info"      *ngIf="record.state==4">Check-in</span>
                                        <span class="badge badge-primary"   *ngIf="record.state==5">Check-out</span>
                                        <span class="badge bg-teal"         *ngIf="record.state==6">Confermato<br><small>(fornitore esterno)</small></span>
                                        <br/>
                                        
                                        <!-- <span class="badge text-danger" *ngIf="record.state>3 && record.checkinCount==0 && record.state!=6"><i class="fas fa-exclamation-triangle mr-1"></i>Check-in errato</span><br> -->
                                        <!-- <span class="badge text-danger" *ngIf="record.state==5 && record.checkoutCount==0 && record.state!=6"><i class="fas fa-exclamation-triangle mr-1"></i>Check-out errato</span> -->
                                        <span class="text-warning" *ngIf="record.state==4  && record.state!=6 && record.checkinCount==0" data-toggle="tooltip" title="Non risultano prodotti scaricati dal magazzino!"><i class="fas fa-exclamation-triangle mr-1"></i></span>
                                        <span class="text-warning" *ngIf="record.state==5  && record.state!=6 && record.checkinCount>0 && record.checkoutCount==0" data-toggle="tooltip" title="Non risultano prodotti ricaricati nel magazzino!"><i class="fas fa-exclamation-triangle mr-1"></i></span>
                                        <span class="text-warning" *ngIf="record.state==5  && record.state!=6 && record.checkinCount==0 && record.checkoutCount==0" data-toggle="tooltip" title="Non risulta nessuna movimentazione di magazzino!"><i class="fas fa-exclamation-triangle mr-1"></i></span>
                                    </td>
                                    <td  style="max-width:10rem">
                                        <small>
                                            {{record.created | date:"d/M/Y hh:mm a"}}<br/>
                                            {{record.username}}<br>
                                            <small><b>{{record.source}}</b></small>
                                            <div style="max-height:56px;overflow-y:auto;" class="alert alert-info mb-0 p-1" *ngIf="record.note"><i class="fas fa-info mr-2"></i>
                                                {{record.note}}
                                            </div>
                                        </small>
                                    </td>
                                    <td style="min-width:7rem">
                                       
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>

                                        <!-- <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" (click)="printInvoice(record.id);" title="Stampa fattura cortesia" class="btn btn-info btn-xs float-right ml-1">
                                            <i class="fas fa-receipt"></i><span class="d-none d-sm-inline-block"></span>
                                        </button> -->
                                        <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)"  data-toggle="tooltip" title="Stampa" class="btn btn-warning btn-xs float-right ml-1">
                                            <i class="fas fa-print"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" (click)="notify(record);" title="Notifica" class="btn btn-primary btn-xs float-right ml-1">
                                            <i class="far fa-paper-plane"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
                                        <br>
                                        <button type="button" class="btn btn-xs btn-block btn-outline-info float-right ml-1 mt-1" *ngIf="mode!='modal' && record.state!=1" (click)="printInvoice(record.id);" >Stampa fattura</button>
                                        <button type="button" class="btn btn-xs btn-block btn-outline-success float-right ml-1 mt-1" *ngIf="record.state==1" (click)="addDocument(record)">Registra pagamento</button>
                                        <button type="button" class="btn btn-xs btn-block btn-outline-success float-right ml-1 mt-1" *ngIf="record.state==2 || record.state==6" (click)="checkin(record)">Effettua check-in</button>
                                        <button type="button" class="btn btn-xs btn-block btn-outline-success float-right ml-1 mt-1" *ngIf="record.state==4" (click)="checkout(record)">Effettua check-out</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="bg-white">
                                    <td></td>
                                    <td colspan="2"><b>TOTALE</b></td>
                                    <td [class.text-red]="total < 0"><b>{{total | currency:'EUR':true }}</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>