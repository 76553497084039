<div class="modal-header bg-light">
    <h5 class="mb-0">Check-in</h5>
    <div class="card-tools">
        <button type="button" class="btn btn-tool float-right" (click)="closeWindow();" >
            <i class="fas fa-times"></i>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <h5>{{steptitle}}</h5>
    <hr/>
    <!-- PARTECIPANTI E DOCUMENTI DI RICONOSCIMENTO -->
    <div class="form-group row mt-2" *ngIf="step==0">
        <div class="col-12 mt-3">
            <h6>Partecipanti</h6>
            <ng-container *ngFor="let passenger of passengers">
                
              <div class="row mb-1">
                <div class="col-lg-7">
                    <div class="input-group input-group-sm">
                        <input type="text" [class.is-invalid]="!passenger.value" required class="form-control" name="passenger_{{passenger.id}}" placeholder="{{passenger.label}}" [(ngModel)]="passenger.value" />
                        <span class="input-group-append ">
                            <button *ngIf="passenger.images.length<2" class="btn btn-flat btn-info" (click)="takephoto(passenger)"><i class="fas fa-camera"></i><span class="d-none d-sm-inline-block ml-2">Scatta foto I.D.</span></button>
                            <button *ngIf="passenger.images.length<2" class="btn btn-flat btn-secondary" (click)="passengerSelected=passenger;fileToUpload.click()"><i class="far fa-image"></i><span class="d-none d-sm-inline-block ml-2">Sfoglia</span></button>
                        </span>
                    </div>
                </div>
                <div class="col-lg-5">
                <ng-container *ngFor="let image of passenger.images">
                    
                    <a href="{{getImagePath(image)}}" target="_blank"> <img *ngIf="image" src="{{getImagePath(image)}}" height="64" /></a>
                    <button class="btn btn-sm btn-danger m-1" (click)="removeImage(passenger,image)"><i class="fas fa-trash "></i></button>
                </ng-container>
                </div>
              </div> 
            </ng-container>
            <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
          
        </div>
    </div>
    
    <!-- PRODOTTI -->
    <div class="form-group mt-1" *ngIf="step==1">
            <h6>Ecco ciò che dovrai consegnare al cliente:</h6>
            <div class="row mt-4">
                <ng-container *ngFor="let b of booking.details" >
                    <!-- elemento ripetuto -->
                    <div class="col-lg-4 py-2 text-center">
                        <img src="{{b.product.coverImage().imagePath}}" style="height:64px" /><br/>
                        <small><strong>{{b.quantity}}</strong> x {{b.product.name}}</small>
                        <hr/>
                        <ul *ngIf="b.selected && b.selected.length>0" class="p-0 m-0" style="list-style:none;">
                             <!-- elemento ripetuto -->
                            <li class="p-1 border-bottom" *ngFor="let s of b.selected">
                                <span class="text-info">{{s.product.name}}</span>
                                <span *ngIf="s.sn" class="badge badge-info mx-1">
                                    {{s.sn}}
                                </span>
                                <span class="m-0 p-0" *ngFor="let r of s.request">
                                    <span *ngIf="r.value">{{r.label}}: <b>{{r.value}}</b></span>
                                </span>
                                <br>
                                <button class="btn btn-xs btn-danger m-1" (click)="deleteProduct(b,s)"><i class="fas fa-unlink mr-2"></i> Disassocia</button>
                                <button *ngIf="s.request" class="btn btn-xs btn-secondary m-1" (click)="setRequestParams(s.request)">Parametri</button>
                            </li>
                        </ul>
                        <ng-container *ngIf="b.selected && b.selected.length>0">
                            <ul class="p-0 m-0" style="list-style:none;"></ul>
                        </ng-container>
                        <ng-container *ngIf="(b.selected==undefined || (b.selected.length==0 || b.selected.length<b.quantity))">
                            <button  class="btn btn-sm btn-primary m-2" (click)="linkProduct(b)"><i class="fas fa-link mr-2"></i>Associa</button>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="col-sm-4 text-center">
                    <button class="btn btn-outline-secondary" (click)="addProduct()">
                        <i class="fas fa-2x fa-plus"></i>
                        <br/>Aggiungi prodotto
                    </button>
                </div>
            </div>
    </div>

    <!-- FIRMA CONTRATTO -->
    <div class="form-group row mt-1" *ngIf="step==2">
        <div class="col-12 text-center">
            <img *ngIf="signature" src="{{getSignatureImage()}}" style="height: 150px;" />
            <br/>
            <button class="btn btn-warning" (click)="openSignature()"><i class="fas fa-pencil-alt mr-2"></i>Modifica</button>
        </div>
    </div>

    <!-- CONFERMA -->
    <div class="form-group row mt-5" *ngIf="step>2">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <div class="alert alert-warning text-center">
                <h3>Sicuro di voler confermare il check-in <i class="fas fa-question ml-2"></i></h3>
                <p>Proseguendo verranno scaricati i prodotti dal magazzino.</p>
                <!-- <div class="mt-2 text-center">
                    <button class="btn btn-success" (click)="confirm()"><i class="fas fa-check mr-2"></i>Si</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

<div class="card-footer">
    <button *ngIf="showPrevious" class="btn btn-secondary float-left" (click)="goPrevious()"><i class="fa fa-chevron-left mr-2"></i>Indietro <span class="d-none d-sm-inline-block">(F2)</span></button>
    <button  *ngIf="showNext" class="btn btn-success float-right" (click)="goNext()">{{NextTitleBtn}} <span class="d-none d-sm-inline-block">(F4)</span><i class="fa fa-chevron-right ml-2"></i></button>
</div>


<ng-template let-modal #modalProduct>
    <div class="modal-header bg-light">
        <b>Seleziona i prodotti da consegnare al cliente</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body bg-light p-1">
        
        <div class="input-group">
            <!-- <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
              </div> -->
            <input #search autofocus type="text" class="form-control form-control-sm" name="searchProduct" [(ngModel)]="searchProduct"  (keyup)="searchFromProducts($event);searchIDCategory=0">
            <div class="input-group-append">
                <button class="btn btn-sm btn-secondary" data-toggle="tooltip" title="Acquisisci QR-Code" (click)="openQrCodeReader()"> <i class="fas fa-qrcode"></i></button>
            </div>
        </div>
        <div class="btn-group my-1" role="group" aria-label="Basic example">
            <span *ngIf="searchProduct!=''" type="button" class="btn btn-xs btn-secondary float-left">TUTTI</span>
            <ng-container *ngFor="let c of inventoryItemsGroup">
                
                <!--<button (click)="modalProductTab=c.name" [class.btn-secondary]="modalProductTab==c.name" type="button" class="btn">{{c.name}}</button>-->
                <a (click)="searchIDCategory=c.id_category;searchProduct=''" [class.bg-gray]="searchIDCategory==c.id_category"
                type="button" class="btn border btn-xs float-left">{{c.name}}</a>
               
            </ng-container>
            <!--
            <button *ngIf="!isAddProduct" (click)="modalProductTab=1" [class.btn-info]="modalProductTab==1" type="button" class="btn">Suggeriti</button>
            <button (click)="modalProductTab=2" [class.btn-info]="modalProductTab==2" type="button" class="btn">Biciclette</button>
            <button (click)="modalProductTab=3" [class.btn-info]="modalProductTab==3" type="button" class="btn">Accessori</button>
            -->
        </div>
    </div>
  
    <div class="modal-body p-0" style="min-height:200px;">
        <!-- SUGGERITI -->
        <ng-container *ngFor="let c of inventoryItemsGroup">
            <!-- <div *ngIf="modalProductTab==c.name"> -->
                <ul class="list-group">
                    <ng-container *ngFor="let i of c.items">
                        <li class="list-group-item border-bottom px-2" 
                            *ngIf="(searchProduct=='' || i.product.name.toUpperCase().indexOf(searchProduct.toUpperCase())>-1 || i.sn.toUpperCase()==searchProduct.toUpperCase()) &&
                                        ((i.product.id_category==searchIDCategory && searchProduct=='') || searchProduct!='')" >
                            <div class="row">
                                <div class="col-8">
                                    <a (click)="selectInventory(i)" style="cursor:pointer;">
                                        <span class="badge badge-info mt-1 mr-2">{{i.sn}}</span>{{i.product.name}}
                                    </a>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-xs btn-primary float-right" (click)="selectInventory(i)"><span class="d-none d-sm-inline-block mr-2">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                </div>
                            </div>
                            
                        </li>
                    </ng-container>
                </ul>
            <!-- </div> -->
        </ng-container>
            
        <!--
        <div *ngIf="modalProductTab==1 && !isAddProduct">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let i of inventoryItemsSuggest">
                    <div class="row">
                        <div class="col-8">
                            {{i.description}} <span class="badge badge-info">{{i.sn}}</span>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-sm btn-primary" (click)="selectInventory(i)">Seleziona</button>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
        -->
        <!-- BICICLETTE -->
        <!--
        <div *ngIf="modalProductTab==2">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let i of inventoryItemsBike">
                    <div class="row">
                        <div class="col-8">
                            {{i.description}} <span class="badge badge-info">{{i.sn}}</span>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-sm btn-primary" (click)="selectInventory(i)">Seleziona</button>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
        -->
        <!-- EXTRA -->
        <!--
        <div *ngIf="modalProductTab==3">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let i of inventoryItemsExtra">
                    <div class="row">
                        <div class="col-8">
                            {{i.description}} <span class="badge badge-info">{{i.sn}}</span>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-sm btn-primary" (click)="selectInventory(i)">Seleziona</button>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
        -->
    </div>
</ng-template>


<ng-template let-modal #modalSignature>
    <div class="modal-header bg-warning">
        <b>Firma Contratto</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body" >
        <signature-pad #sigPad (click)="signed=true" [options]="signaturePadOptions"></signature-pad>
    </div>
    <div class="modal-footer p-2">
        <a class="btn btn-danger float-left" (click)="sigPad.clear();signed=false"><i class="fas fa-eraser mr-2"></i>Cancella</a>
        <a class="btn btn-success float-right" [class.section_disabled]="!signed" (click)="confirmSignature(sigPad)"><i class="fas fa-check mr-2"></i>Procedi</a>
    </div>
</ng-template>

<ng-template let-modal #modalRequestParams>
    <div class="modal-header bg-light">
        <b>Dati richiesti</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ng-container *ngFor="let p of requestSelected">
            <div class="form-group">
                <label>{{p.label}}</label>
                <input type="text" name="{{p.name}}" [(ngModel)]="p.value">
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmParams()">OK</button>
    </div>
</ng-template>


<ng-container *ngIf="payModal">
    <app-modal [textCancelBtn]="'Paga'" [textCancelBtn]="'Annulla'" [isConfirmDialog]="false" (OnConfirm)="pay()" (OnCancel)="payModal=false" >
        <div class="row">
            <div class="col-12 pt-1 ">
                <label>Seleziona il metodo di pagamento</label>
                <select class="form-control" name="methodpayment" [(ngModel)]="booking.methodpayment">
                    <option value="stripe">Stripe (pagamento online)</option>
                    <option value="POS">POS (bancomat/carte)</option>
                    <option value="Contanti">Contanti</option>
                    <option value="Bonifico">Bonifico</option>
                </select>
            </div>
            <ng-container *ngIf="booking.methodpayment=='stripe'">
                <div class="col-12 py-1">
                    <h4>Inserisci i dati della tua carta per procedere al pagamento</h4>
                    <app-stripe [class]="'form-control'" [showPayButton]="false" [amount]="booking.amount*(1-booking.discount/100)*(1+booking.tax/100)" (onsuccess)="confirmPayment($event)" (onerror)="errorCard($event)" (oninvalid)="invalidPayment($event)" (paymentMethodId)="createPaymentMethod($event)"></app-stripe>
                </div>
            </ng-container>
        </div>
    </app-modal>
</ng-container>


<ng-container *ngIf="confirmPaymentModal">
    <app-modal [textConfirmBtn]="'Si'" [textCancelBtn]="'No'" (OnConfirm)="beforeBookNow()" (OnCancel)="confirmPaymentModal=false" >
        <h4>Confermi il pagamento ?</h4>
        <table class="table">
            <tr>
                <td>Costo prenotazione</td>
                <td>{{booking.amount | currency:" &euro;"}}</td>
            </tr>
            <tr>
                <td>Sconto</td>
                <td>
                    <div class="input-group">
                        <input type="number" name="discount" [(ngModel)]="booking.discount" (change)="calculateTotal()">
               
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Imponibile</td>
                <td>{{booking.amount-booking.discountValue | currency:" &euro;"}}</td>
            </tr>

            <tr>
                <td>Imposta</td>
                <td>
                    {{booking.tax | currency:" &euro;"}}
                </td>
            </tr>
            <tr>
                <td>TOTALE A PAGARE</td>
                <td>
                    <div class="input-group">
                        <input type="number" name="total" value="{{total_gross}}" (change)="onChangeAmount($event)">
                    
                        <div class="input-group-append">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <p>Inserisci un riferimento del pagamento (opzionale)</p>
        <input type="text" class="form-control" name="tracking" [(ngModel)]="booking.tracking" />
    </app-modal>
</ng-container>