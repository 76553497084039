<div class="row my-1">
    <div  class="col-12 col-lg-3 pr-lg-0">
        <small>
            <app-treecategories [class]="'card p-1'" [multiple]="true" (id_selected)="id_category=$event;getAnalytics()" [showAll]="true" [id]="id_category" [showCountProduct]="false" [editButton]="false" #tree [list]="categories"></app-treecategories>
        
            <ul class="card small-box list-group treeviewlist p-1 mt-2">
                <li class="list-group-item" [class.active]="id_type==0" (click)="id_type=0;getAnalytics()">Tutte le tipologie</li>
                <li *ngFor="let t of types" class="list-group-item" [class.active]="id_type==t.id"  (click)="id_type=t.id;getAnalytics()">{{t.name}}</li>
                
            </ul>

            <ul class="card small-box list-group treeviewlist p-1 mt-2">
                <li class="list-group-item" [class.active]="filter_difference==0" (click)="filter_difference=0;getAnalytics()">Tutti</li>
                <li class="list-group-item" [class.active]="filter_difference==1" (click)="filter_difference=1;getAnalytics()">Positivi</li>
                <li class="list-group-item" [class.active]="filter_difference==-1" (click)="filter_difference=-1;getAnalytics()">Negativi</li>
                
                
            </ul>
        </small>
    </div>
    <div class="col-lg-9">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row">
                    <div class="col-lg-9">

                    </div>
                    <div class="col-lg-3">
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block"></span></button>
                 
                    </div>
                </div>
            </div>
            <div class="row">
            </div>
            <div *ngIf="list==null" class="m-4">
                <i class="fa-2x fas fa-info text-info mr-3"></i>
                <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
            </div>
            <form [formGroup]="form" *ngIf="list!=null">
                <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead>
                        
                        <th>Prodotto</th>
                        <th>Categoria</th>
                        <th>Carico</th>
                        <th>Scarico</th>
                        <th>Carico (manuale)</th>
                        <th>Scarico (manuale)</th>
                        <th>Giacenza (teorica)</th>
                        <th>Giacenza (reale)</th>
                        <th>Differenze</th>
                        <th>Valore</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of list" [class.bg-success]="calculateTeoricalStock(r)-calculateRealStock(r)==0">
                            <td>
                                <small>
                                    <span class="badge badge-secondary">{{r.codeinternal}}</span>
                                    <span class="badge badge-info">({{r.id_product}})</span>
                                </small><br/>
                            
                                {{r.brand}} {{r.name}}
                                &nbsp;<i class="fa fa-pencil-alt" (click)="openProduct(r.id_product)"></i>
                                <ng-container >
                                    &nbsp;<i class="fa fa-boxes" (click)="openHistory(r.id_product)"></i>
                                </ng-container>


                            </td>
                            <td>{{r.category}}</td>
                            <td>{{r.total_load_document}}</td>
                            <td>{{r.total_unload_document}}</td>
                            <td>{{r.total_load}}</td>
                            <td>{{r.total_unload}}</td>
                            <td>{{r.theorical_stock}}</td>
                            <td>{{r.real_stock}}</td>
                            <td>{{r.difference}}</td>
                            <td>{{r.difference_grossvalue | currency:"&euro; "}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="9">TOTALE</td>
                            <td>{{total_difference_grossvalue | currency:"&euro; "}}</td>
                        </tr>
                    </tfoot>
                </table>
            </form>
            
        </div>
    </div>
</div>