import { Component, OnInit, Input,ViewChild,TemplateRef, OnChanges } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Type } from '../../../db/type';
import { Filter,FilterMode, User } from 'projects/core/src/include/structures'

import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { Inventories,InventoriesDetails, InventoriesTotal,InventoryMode,SNAvaible } from '../../../db/inventories';

import {ProductsService} from '../../../services/products.service';
import {NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../db/product';
import { ListinventoriesComponent } from './../listinventories/listinventories.component';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetailsComponent } from '../inventories-details/inventories-details.component';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { ProductCategories } from '../../../db/productcategories';
import { InventoriesModule } from '../inventories.module';
import { Helper } from 'projects/core/src/helper';
import { ProductsDetailsComponent } from '../../products/products-details/products-details.component';
import { TypesService } from '../../../services/types.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent extends ModelList<Product> implements OnInit,OnChanges {
  
  get user(): User {
      return Globals.user;
  }

  @Input()
  id_product:number=0;

  @Input()
  sn:string="";

  @Input()
  product_id_type=""; //tipologia prodotto di default (0 non specificato)
  
  @Input()
  stock_mode:number=0; //quantità di giacenza di default (0 = tutti, 6 = positiva, 7 = negativa , 1 = nulla )


  type_products=[]; //elenco tipologie prodotti


  inventories:Inventories[];
  status_inventories:Type[];
  filter_date_stock:Filter=new Filter();
  filter_stock:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_id_inventory:Filter<number>=new Filter();
  filter_status:Filter=new Filter();
  filter_id_product:Filter=new Filter();
  filter_sn:Filter=new Filter();
  filter_date:Filter=new Filter();
  filter_stockable:Filter=new Filter();

  filter_id_category:Filter=new Filter();
  filter_product_id_type:Filter=new Filter();

  total_cost:number=0;

  
  categories:ProductCategories[]=[];
  

  isFilterSet:boolean=false;


  date_from: Date=new Date();
  date_to: Date=new Date();

  from:string="";
  to:string="";

  constructor( private fb1: FormBuilder,
    private InventoriesDetailsService: InventoriesDetailsService,
    private productsService: ProductsService,
    private productcategoriesService:ProductcategoriesService,
    private typesService: TypesService,
    private route: ActivatedRoute,

    
  ) {
    super(productsService,fb1);
    this.detailViewModal=InventoriesDetailsComponent;
   }


   ngOnInit(): void {
    this.title = 'Magazzino';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=24;

    

    this.InventoriesDetailsService.getInventories().subscribe((items)=>{
      this.inventories=items;
    })
   
   

   InventoriesModule.savingInventoriesFastEvent.subscribe(()=>{
    this.getItems();
   });
    

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });

    this.typesService.getTypes("products").subscribe((items)=>{
      this.type_products=items;
      
    });

    this.InventoriesDetailsService.getInventories().subscribe((items)=>{
      this.inventories=items;
    });



    this.route.params.subscribe((params) => {
      
      this.product_id_type = params.product_id_type.toString();
      this.stock_mode=parseInt(params.stock_mode);


     
    }
  );

  if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
    this.updateList();
  }

  getItems(){
    this.from="1900-01-01";
    this.to=this.filter_date.value2;
    this.total_cost=0;
    Globals.setLoading(true);
    this.productsService.getItemsStockPeriod(this.filter,this.orderingList,this.from,this.to,this.paging_start,this.paging_count,this.filter_id_inventory.value).subscribe((items)=>{
      this.calculatePaging(items);
      this.list=items['value']['items'];
      Globals.setLoading(false);
      this.total_cost=items['value']['total_cost'];
    });
  }

  setFilters():void{
    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to);
      
    
    this.filter_id_inventory.mode=FilterMode.contain;
    this.filter_id_inventory.fields=[];
    this.filter_id_inventory.fields.push("ps.id_inventory");
    this.filter_id_inventory.value=0;
    //this.filter.push(this.filter_id_inventory);
    
    this.filter_id_category.mode=FilterMode.normal;
    this.filter_id_category.fields=[];
    this.filter_id_category.fields.push("pc.id");
    this.filter_id_category.value="";
    this.filter.push(this.filter_id_category);

    this.filter_product_id_type.mode=FilterMode.normal;
    this.filter_product_id_type.fields=[];
    this.filter_product_id_type.fields.push("pm.id_type");
    this.filter_product_id_type.value=this.product_id_type;
    this.filter.push(this.filter_product_id_type);


    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("pm.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_stockable.mode=FilterMode.normal;
    this.filter_stockable.fields=[];
    this.filter_stockable.fields.push("pm.stockable");
    this.filter_stockable.value="1";
    this.filter.push(this.filter_stockable);

    

    if(this.id_product){
      this.filter_id_product.mode=FilterMode.normal;
      this.filter_id_product.fields=[];
      this.filter_id_product.fields.push("pm.id");
      
      this.filter_id_product.value=this.id_product.toString();
      this.filter.push(this.filter_id_product);
    }

    if(this.sn!=""){
      this.filter_sn.mode=FilterMode.normal;
      this.filter_sn.fields=[];
      this.filter_sn.fields.push("pm.sn");
      this.filter_sn.value=this.sn;
      this.filter.push(this.filter_sn);
    }

    this.filter_stock.mode=this.stock_mode;
    this.filter_stock.fields=[];
    this.filter_stock.fields.push("ps.stock");
    this.filter_stock.value="0";
    this.filter.push(this.filter_stock);

    this.filter_date_stock.mode=FilterMode.disabled;
    this.filter_date_stock.fields=[];
    this.filter_date_stock.fields.push("ps.date");
    this.filter_date_stock.value="";
    this.filter.push(this.filter_date_stock);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("pm.name");
    this.filter_search.fields.push("pb.name"); //brand
    this.filter_search.fields.push("pm.codeinternal");
    this.filter_search.fields.push("pm.codeexternal");
    this.filter_search.fields.push("pm.barcode");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.ordering.field="pc.name";

    if(this.mode!="modal" && this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);
  
    

    
    this.isFilterSet=true;
    
  }

ngOnChanges():void{
  if(this.id_product>0 || this.sn!="") //avvia la ricerca quando il filtro è attivo
    this.updateList();
}




updateList():void{
  if(!this.isFilterSet)
      this.setFilters();

  
  if(this.id_product>0){
    this.filter_id_product.value=this.id_product.toString();
  }

  this.getItems();
  
}




 



  openInventoryManager(){
    Globals.modal.showModal(ListinventoriesComponent,[],()=>{
      this.InventoriesDetailsService.getInventories().subscribe((items)=>{
        this.inventories=items;
      })
    });
  }

  openProduct(p){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":p.id},
      
    ],()=>{

    });
  }
  

  move(record){
    this.InventoriesDetailsService.move(record.id,()=>{
      this.getItems();
    })
  }
}
