<div class="modal-header bg-light py-1">
    <b>Documenti presenti sullo SDI</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div *ngIf="list==null" class="m-4">
        <i class="fa-2x fas fa-info text-info mr-3"></i>
        <i>Nessun elemento visualizzato </i>
    </div>
    <form [formGroup]="form" *ngIf="list!=null">
        <table class="table table-sm">
            <thead>
                <th>
                    <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" />
                </th>
                <th>ID</th>
                <th>Data</th>
                <th>Riferimento</th>
                <th>Fornitore</th>
                <th class="text-right">Importo</th>
                <th class="text-center">Stato</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let r of list">
                    <td>
                        <input type="checkbox" #checkrecord id_record="{{r.identificativoSdI}}" (change)="onChange(r.identificativoSdI, $event.target.checked)" [checked]="checkedAll"/>
                    </td>
                    <td><small>{{r.identificativoSdI}}</small></td>
                    <td>{{r.dataDocumento}}</td>
                    <td>{{r.numero}}</td>
                    <td>{{r.fornitore}}</td>
                    <td class="text-right">{{r.totaleDocumento | currency:" &euro;"}}</td>
                    <td class="text-center">
                        <span *ngIf="r.state==0" class="badge badge-warning">da scaricare</span>
                        <span *ngIf="r.state==1" class="badge badge-success">scaricato</span>
                    </td>
                    <td><button type="button"  class="btn btn-xs" (click)="downloadDocument(r.identificativoSdI);" data-toggle="tooltip" title="Scarica il documento"><i class="fa fa-download"></i></button></td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="form.value.id.length==0" class="btn btn-success" (click)="downloadDocumentSelected()">Scarica i selezionati</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Chiudi</button>
</div>